import * as React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Button from '@mui/material/Button';
import { t } from 'shared/utils/LocaleUtils.js';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

const styles = {
  cartButtons: {
    margin: '1rem 0',
    height: 50, // MUI hardcodes a PX unit for height. See https://github.com/callemall/material-ui/issues/4473
    fontSize: 16,
  },

  buttonLabel: {
    fontSize: 16,
  },
  link: {
    textDecoration: 'none',
  },
};

function UpdateCartButton({ onClick, disabled, intl }) {
  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      disabled={disabled}
      style={styles.cartButtons}
      onClick={onClick}
    >
      {t('.update_cart', intl, __filenamespace)}
    </Button>
  );
}

function ViewInCartButton({ onClick, intl }) {
  return (
    <Link to={customerScopedRoute('/cart')} style={styles.link}>
      <Button
        fullWidth
        variant="contained"
        style={styles.cartButtons}
        onClick={onClick}
      >
        {t('.view_in_cart', intl, __filenamespace)}
      </Button>
    </Link>
  );
}

function AddToCartButton({ intl, addToCartLabel, onClick, disabled }) {
  return (
    <Button
      fullWidth
      variant="contained"
      color="secondary"
      disabled={disabled}
      style={styles.cartButtons}
      onClick={onClick}
    >
      {addToCartLabel || t('.add_to_cart', intl, __filenamespace)}
    </Button>
  );
}

function CartCommitButton({
  addToCartLabel,
  handleAddToCartClick,
  handleUpdateCartClick,
  handleViewInCartClick,
  hasOrderItemId,
  hasUpdate,
  intl,
  isNavigatingToCart,
  isUpdatingCart,
  itemIsValid,
}) {
  const disabled = !itemIsValid || isUpdatingCart || isNavigatingToCart;
  if (hasOrderItemId && hasUpdate) {
    return (
      <UpdateCartButton
        onClick={handleUpdateCartClick}
        disabled={disabled}
        intl={intl}
      />
    );
  }
  if (hasOrderItemId) {
    return <ViewInCartButton onClick={handleViewInCartClick} intl={intl} />;
  }
  return (
    <AddToCartButton
      addToCartLabel={addToCartLabel}
      disabled={disabled}
      onClick={handleAddToCartClick}
      intl={intl}
    />
  );
}

export default injectIntl(CartCommitButton);
