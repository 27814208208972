import { Record } from 'immutable';

// Please merge with src/event_mgmt/shared/records/Schedule.js
class Schedule extends Record({
  id: null,
  event_id: null,
  max_size: null,
  max_age: null,
  min_age: null,
  customer_user_ids: [],
  resource_usage_mode: '',
  staff_admin_ids: [],
  staff_member_ids: [],
  resource_ids: [],
  resource_preferring_staff: {},
  start_time_interval: null,
  duration: null,
  scheduling_deadline: null,
  cancellation_deadline: null,
  registration_deadline: null,
  gender_restriction: '',
  availability_schedule: {},
  location: {},
  schedule_resources: {},
  schedule_staff: {},
  future_registration_count: null,
  total_registration_count: null,
  status: null,
  label: '',
  team_schedule_type: '',
}) {
  constructor(obj = {}) {
    super({ ...obj });
  }
}

export default Schedule;
