import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paper from '@mui/material/Paper';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CheckoutStepper, {
  PAYMENT_SELECT,
  REVIEW,
  SUMMARY,
} from 'shared/components/checkout/CheckoutStepper.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import RegistrationFormActions from 'shared/actions/RegistrationFormActions.jsx';
import uhTheme from '_uh_theme.jsx';
import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { PaymentMethod } from 'containers/payments';
import { STANDARD_DRAWER_WIDTH } from 'shared/utils/DOMUtils';
import { sumInt } from 'shared/utils/ImmutableUtils';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  root: {
    width: STANDARD_DRAWER_WIDTH,
  },
  cartHeader: {
    backgroundColor: '#3a3c44',
    color: 'white',
    padding: '0 20px',
    fontSize: 16,
    lineHeight: '56px',
  },
  content: {
    padding: 20,
  },
  completeFormWarningStyle: {
    backgroundColor: uhColors.headerGrey,
    padding: '15px',
    textAlign: 'center',
    margin: '-20px -20px 5px -20px',
    borderBottom: '1px solid',
    borderBottomColor: uhColors.lightGrey,
  },
  divider: {
    marginLeft: '-20px',
    marginRight: '-20px',
  },
  warningMessage: {
    fontSize: '13px',
    marginBottom: '1rem',
    paddingTop: '15px',
    color: uhColors.errorColor,
    textAlign: 'center',
  },
};

const orderItemRemoved = orderItem => CartActions.remove(orderItem.id);

class CheckoutCard extends React.Component {
  header() {
    const { cart, step, totalDueNow } = this.props;

    if (step === SUMMARY) {
      return (
        <FlexBoxJustify
          style={merge(styles.cartHeader, {
            backgroundColor: uhTheme.palette.accent1Color,
          })}
        >
          <FormattedMessage id={messageId('.purchased', __filenamespace)} />
          <FormattedCurrency value={totalDueNow} fromCents />
        </FlexBoxJustify>
      );
    }

    return (
      <FlexBoxJustify style={styles.cartHeader}>
        {cart.hasPaymentPlans() ? (
          <FormattedMessage id={messageId('.total', __filenamespace)} />
        ) : (
          <FormattedMessage id={messageId('.due_today', __filenamespace)} />
        )}
        <FormattedCurrency value={totalDueNow} fromCents />
      </FlexBoxJustify>
    );
  }

  render() {
    const {
      athletes,
      automations,
      cart,
      cartStore,
      memberships,
      registrationForms,
      step,
      style,
      onItemEdit,
    } = this.props;

    const { features } = currentCustomer();
    const { insurance: insuranceEnabled } = features;

    // find the correct ID to list saved payment methods
    const purchaserId = currentUser().managing_customer_user
      ? currentUser().managing_customer_user.id
      : currentUser().customer_user_id;

    const purchasingUserId = currentUser().managing_customer_user
      ? currentUser().managing_customer_user.user_id
      : currentUser().user_id;

    const allAdjustments = cart.order_items.flatMap(i => i.applied_adjustments);

    const appliedAccountCredits = allAdjustments
      .filter(a => a.isAccountCredit())
      .reduce(sumInt('amount'), 0);

    const disabledPaymentMethods = [PaymentMethod.CASH, PaymentMethod.CHECK];

    const disableContinueToPayment =
      insuranceEnabled &&
      cartStore.disableContinueButton &&
      cartStore.insurances.length > 0;

    if (cart.insurance_amount > 0) {
      disabledPaymentMethods.push(PaymentMethod.PAY_LATER);
    }

    return (
      <Paper sx={{ ...styles.root, ...style }}>
        {this.header()}
        <div style={styles.content}>
          <CheckoutStepper
            appliedAccountCredits={appliedAccountCredits}
            onItemEdit={onItemEdit}
            automations={automations}
            cart={cart}
            couponIsLoading={cartStore.isLoadingCoupon}
            insurances={cartStore.insurances}
            disableContinueButton={disableContinueToPayment}
            dividerStyle={styles.divider}
            formWarningStyle={{ margin: '-20px -20px 10px -20px' }}
            memberships={memberships}
            onCouponApply={CartActions.couponApplied}
            onItemRemoved={orderItemRemoved}
            onPurchaseSuccess={() => {
              CartActions.updateCheckoutStep.defer(SUMMARY);
            }}
            onReturnToReview={() => CartActions.updateCheckoutStep(REVIEW)}
            onReviewContinue={() =>
              CartActions.updateCheckoutStep(PAYMENT_SELECT)
            }
            onInsuranceCheck={CartActions.setInsurance}
            onInsuranceValidate={CartActions.validateInsurancesSelection}
            profiles={athletes.allAthletes}
            purchaserEmail={currentUser().email}
            purchaserId={purchaserId}
            hasAccess
            registrationFormActions={RegistrationFormActions}
            registrationFormStore={registrationForms}
            step={step}
            disabledPaymentMethods={disabledPaymentMethods}
            purchasingUserId={purchasingUserId}
          />
        </div>
      </Paper>
    );
  }
}

export default injectIntl(CheckoutCard);
