import * as React from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import { Set } from 'immutable';
import { Spinner, Tabs } from '@upperhand/playmaker';

import BrowseEventsTab from 'client_portal/components/BrowseEventsTab.jsx';
import ARVisionTab from 'client_portal/components/ARVisionTab.jsx';
import ClientProfile from 'containers/clientProfile/ClientProfile.jsx';
import MembershipListing from 'memberships/components/MembershipListing.jsx';
import BrowseEvents from 'containers/browseEvents/BrowseEvents.jsx';
import BrowseTeams from 'containers/teams/client/teams_tab/BrowseTeams.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import PortalTabsActions from 'client_portal/actions/PortalTabsActions.js';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import AutomationTemplateDescriptionActions from 'shared/actions/AutomationTemplateDescriptionActions.jsx';
import BrowseEventListActions from 'client_portal/actions/BrowseEventListActions.jsx';
import CreditPassListing from 'credit_passes/components/CreditPassListing.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import EventActions from 'event_mgmt/shared/actions/EventActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import ClientProfileActions from 'containers/clientProfile/Actions';
import { isARtheme, isHybridJaxTheme } from 'shared/utils/ThemeUtils';

import {
  BROWSE_EVENTS_TAB,
  MEMBERSHIPS_TAB,
  PASSES_TAB,
  MY_PROFILE_TAB,
  TABS_EXPIRE_INTERVAL,
  TABS_EXPIRE_INTERVAL_UNIT,
  VISION_TAB,
  TEAMS_TAB,
} from 'client_portal/stores/PortalTabsStore.jsx';

import './styles.scss';

const styles = {
  content: {
    position: 'relative',
    textAlign: 'center',
  },
  divider: {
    position: 'absolute',
    width: '100%',
    top: 73,
    zIndex: -1,
    backgroundColor: uhColors.lighterGrey,
  },
  spinner: {
    paddingTop: 40,
  },
  tabContainer: {
    borderBottom: `1px ${uhColors.lighterGrey} solid`,
    backgroundColor: 'var(--color-bg-gray)',
    width: 500,
  },

  tabLabel: {
    fontSize: 22,
    textTransform: 'capitalize',
    height: 72,
  },
  tabsInkBar: {
    height: 3,
    marginTop: -3,
  },
};

class PortalTabs extends React.PureComponent {
  constructor(props) {
    super(props);

    this.loadedTabs = Set();
    this.membershipsLoaded = false;
    this.passesLoaded = false;
    this.tabsLoadedAt = moment();
    this.tabsOrder = [
      MY_PROFILE_TAB,
      BROWSE_EVENTS_TAB,
      TEAMS_TAB,
      MEMBERSHIPS_TAB,
      PASSES_TAB,
      VISION_TAB,
    ];
  }

  componentDidMount() {
    const activeTab = this.getDefaultTab();
    const canFetchProfile = isLoggedIn() && currentUser().customer_user_id;

    if (isHybridJaxTheme()) {
      this.tabsOrder = [
        MY_PROFILE_TAB,
        BROWSE_EVENTS_TAB,
        PASSES_TAB,
        MEMBERSHIPS_TAB,
        TEAMS_TAB,
        VISION_TAB,
      ];
    }

    this.loadMemberships();

    if (currentCustomer().credit_passes_enabled) {
      this.loadPasses();
    }
    if (activeTab === BROWSE_EVENTS_TAB) {
      this.onBrowseEventsTabActive();
    } else if (activeTab === MEMBERSHIPS_TAB) {
      this.onMembershipsTabActive();
    } else if (activeTab === PASSES_TAB) {
      this.onPassesTabActive();
    } else if (activeTab === MY_PROFILE_TAB && canFetchProfile) {
      ClientProfileActions.mounted({
        clientId: currentUser().customer_user_id,
        drawerOnly: smallScreen(),
      });
    }
  }

  onTabChange = (_event, tabValue) => {
    const canFetchProfile = isLoggedIn() && currentUser().customer_user_id;

    PortalTabsActions.tabSelected(tabValue);
    SecondaryDrawerActions.close();
    ClientProfileActions.closeDrawer();

    if (tabValue === BROWSE_EVENTS_TAB) {
      this.onBrowseEventsTabActive();
    } else if (tabValue === MEMBERSHIPS_TAB) {
      this.onMembershipsTabActive();
    } else if (tabValue === PASSES_TAB) {
      this.onPassesTabActive();
    } else if (tabValue === MY_PROFILE_TAB && canFetchProfile) {
      ClientProfileActions.mounted({
        clientId: currentUser().customer_user_id,
        drawerOnly: smallScreen(),
      });
    }
  };

  getDefaultTab() {
    const { tabStore } = this.props;
    const activeTab = tabStore.activeTab || tabStore.getTabFromPathname();
    const isProfileTab = activeTab === MY_PROFILE_TAB;

    return isProfileTab && !isLoggedIn() ? BROWSE_EVENTS_TAB : activeTab;
  }

  onBrowseEventsTabActive = () => {
    if (this.shouldLoadTab(BROWSE_EVENTS_TAB)) {
      if (!currentCustomer().beta_browse_events_enabled) {
        BrowseEventListActions.list({ fields: [], initialCall: true });
        AutomationTemplateDescriptionActions.list({
          listAll: true,
          requireToken: true,
        });
        EventTypeListingActions.list();
      }
      this.tabLoaded(BROWSE_EVENTS_TAB);
    }
  };

  onMembershipsTabActive = () => {
    if (this.shouldLoadTab(MEMBERSHIPS_TAB)) {
      if (isLoggedIn()) {
        AthleteActions.list({
          fields: ['active_membership_id', 'permitted_membership_ids'],
        });
      }
      this.loadMemberships();
      EventTypeListingActions.list();
      RetailCategoryListingActions.list();
      this.tabLoaded(MEMBERSHIPS_TAB);
    }
  };

  onPassesTabActive = () => {
    if (this.shouldLoadTab(PASSES_TAB)) {
      this.loadPasses();
      EventTypeListingActions.list();
      this.tabLoaded(PASSES_TAB);
    }
  };

  shouldLoadTab = tab => {
    /* Only re-GET data after certain time of interval */
    /* so that it does not do super frequent API calls */
    /* and a client can persist search data/result for a while even after switching tabs. */
    /* In thie mean time, the tabs do not stay with outdated data forever */
    /* (there's no browser reload button for the mobile apps) */

    const isExpired =
      moment().diff(this.tabsLoadedAt, TABS_EXPIRE_INTERVAL_UNIT) >
      TABS_EXPIRE_INTERVAL;

    if (isExpired) {
      this.loadedTabs = this.loadedTabs.clear();
      this.membershipsLoaded = false;
      this.passesLoaded = false;
    }

    return !this.loadedTabs.has(tab);
  };

  loadMemberships() {
    if (!this.membershipsLoaded) {
      MembershipListingActions.listRequested({});
      this.membershipsLoaded = true;
    }
  }

  loadPasses() {
    if (!this.passesLoaded) {
      EventTypeListingActions.list();
      EventActions.list({ fields: ['participant_count'] });
      CreditPassListingActions.listRequested({});
      this.passesLoaded = true;
    }
  }

  tabLoaded(tab) {
    this.loadedTabs = this.loadedTabs.add(tab);
    this.tabsLoadedAt = moment();
  }

  render() {
    const {
      automationStore,
      creditPassListingStore: {
        records: creditPasses,
        isLoading: passesLoading,
      },
      browseEventListStore,
      browseEventsFilterStore,
      eventTypeListingStore,
      intl,
      locationStore,
      membershipListingStore: {
        records: memberships,
        isLoading: membershipsLoading,
      },
      clientProfileStore: { isLoading: profileLoading },
    } = this.props;
    const otherLoading = passesLoading || membershipsLoading;
    const activeTab = this.getDefaultTab();
    const isProfileTab = activeTab === MY_PROFILE_TAB;
    const isLoading = isProfileTab ? profileLoading : otherLoading;

    const tabs = [
      isLoggedIn() && {
        label: t('.my_profile', intl, __filenamespace),
        content: <ClientProfile disableAutoFetch />,
        value: MY_PROFILE_TAB,
      },
      {
        label: t('.browse_events', intl, __filenamespace),
        content: currentCustomer().beta_browse_events_enabled ? (
          <BrowseEvents />
        ) : (
          <BrowseEventsTab
            automations={automationStore.descriptions}
            events={browseEventListStore.events}
            eventTypeListingStore={eventTypeListingStore}
            filterStore={browseEventsFilterStore}
            isLastEventPage={browseEventListStore.isLastPage}
            isLoading={browseEventListStore.isLoading}
            locationStore={locationStore}
          />
        ),
        value: BROWSE_EVENTS_TAB,
      },
      currentCustomer().features.teams && {
        label: t('.teams', intl, __filenamespace),
        content: <BrowseTeams />,
        value: TEAMS_TAB,
      },
      currentCustomer().memberships_enabled &&
        memberships.size && {
          label: t('.memberships', intl, __filenamespace),
          content: <MembershipListing readOnly />,
          value: MEMBERSHIPS_TAB,
        },
      currentCustomer().credit_passes_enabled &&
        creditPasses.size && {
          label: t('.credit_passes', intl, __filenamespace),
          content: <CreditPassListing readOnly />,
          value: PASSES_TAB,
        },
      isARtheme() && {
        label: t('.ar_vision', intl, __filenamespace),
        content: <ARVisionTab />,
        value: VISION_TAB,
      },
    ].sort(
      (a, b) =>
        this.tabsOrder.indexOf(a.value) - this.tabsOrder.indexOf(b.value)
    );

    return (
      <div className="iphone-x-content">
        {isLoading ? (
          <div style={styles.content}>
            <div style={styles.spinner}>
              <Spinner size={80} />
            </div>
          </div>
        ) : (
          <div className="portal-tabs">
            <Tabs
              classes={{
                tabsRoot: 'portal-tabs__tabs-root',
                tabSelected: 'portal-tabs__tab-selected',
                tabLabel: `${
                  smallScreen()
                    ? 'portal-tabs__tab-label-mobile'
                    : 'portal-tabs__tab-label'
                }`,
              }}
              variant={smallScreen() ? 'scrollable' : 'standard'}
              tabItems={tabs}
              onChange={this.onTabChange}
              value={activeTab}
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(PortalTabs);
