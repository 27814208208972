import { List, Record, Set, Map } from 'immutable';
import OrderItem from 'shared/records/OrderItem';

class Order extends Record({
  id: null,
  coupon_id: null,
  due_immediately: 0,
  failure_message: null,
  failure_suggestion: null,
  order_items: List(),
  order_number: null,
  sale_source: null,
  status: 'in_progress',
  subtotal: 0,
  tax: 0,
  fee: 0,
  tax_exempt: false,
  fee_exempt: false,
  tax_id: '',
  total: 0,
  total_due_at_checkout: 0,
  checkout_by_id: 0,
  checkout_by_name: null,
  prorate_date: null,
  completion_details: null,
  insurance_proposal_ids: Set(),
  insurance_amount: 0,
  insurance_metadata: Map(),
}) {
  constructor(obj = {}, options = {}) {
    const orderItems = List(obj.order_items).map(
      item => new OrderItem(item, options)
    );

    super({ ...obj, order_items: orderItems });
  }
}

export default Order;
