import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { injectIntl } from 'react-intl';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import {
  CardHolderField,
  CardNumberField,
  CvcField,
  ExpiryMonthYearField,
  ZipCodeField,
} from '../fields';
import { PaysafeService } from '../../services';

class PaysafeCardFormFieldsImpl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.cardNumberFieldId = `cardNumber-${uuidv4()}`;
    this.cvvFieldId = `cvv-${uuidv4()}`;
    this.expiryDateFieldId = `expiryDate-${uuidv4()}`;
  }

  componentDidMount() {
    const { intl } = this.props;

    window.paysafe.fields.setup(
      window.paysafe_token_api_key,
      {
        environment: window.paysafe_env,
        fields: {
          cardNumber: {
            selector: `#${this.cardNumberFieldId}`,
            placeholder: t('.card_number', intl, __filenamespace),
          },
          cvv: {
            selector: `#${this.cvvFieldId}`,
            placeholder: t('.zero', intl, __filenamespace),
            optional: false,
          },
          expiryDate: {
            selector: `#${this.expiryDateFieldId}`,
            placeholder: t(
              '.expiration_date_placeholder',
              intl,
              __filenamespace
            ),
          },
        },
        style: {
          input: {
            color: 'rgb(57, 60, 68)',
            'font-weight': 'lighter',
          },
        },
      },
      PaysafeService.setupCallback
    );
  }

  render() {
    const { card, style, onChange } = this.props;

    return (
      <div style={style}>
        <CardNumberField
          fieldId={this.cardNumberFieldId}
          errorText={card.errors.getErrors('number')}
          hideHint
        >
          <div id={this.cardNumberFieldId} />
        </CardNumberField>
        <CardHolderField
          errorText={card.errors.getErrors('name')}
          onChangeCardHolder={onChange}
          value={card.name}
        />
        <FlexBoxJustify style={{ flexWrap: 'wrap', margin: '0 -5px' }}>
          <div style={{ flex: '1 0 5em', margin: '0 5px' }}>
            <ExpiryMonthYearField hideHint fieldId={this.expiryDateFieldId}>
              <div id={this.expiryDateFieldId} />
            </ExpiryMonthYearField>
          </div>
          <div style={{ flex: '0 0 5em', margin: '0 5px' }}>
            <CvcField hideHint fieldId={this.cvvFieldId}>
              <div id={this.cvvFieldId} />
            </CvcField>
          </div>
          <div style={{ flex: '1 0 5em', margin: '0 5px' }}>
            <ZipCodeField
              errorText={card.errors.getErrors('address_zip')}
              onChangeZipCode={onChange}
              value={card.address_zip}
            />
          </div>
        </FlexBoxJustify>
      </div>
    );
  }
}

const PaysafeCardForm = injectIntl(PaysafeCardFormFieldsImpl);

export default PaysafeCardForm;
