import * as React from 'react';
import { injectIntl } from 'react-intl';
import { List } from 'immutable';
import { Dropdown } from '@upperhand/playmaker';
import PropTypes from 'prop-types';
import Client from 'shared/records/Client.jsx';
import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';
import ManageProfilesDialog from 'user_management/shared/components/_ManageProfilesDialog.jsx';
import UserAvatar from 'shared/components/_UserAvatar.jsx';
import { FlexBoxCenter } from 'shared/components/FlexBox.jsx';
import { boldText } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

class ProfileSelector extends React.Component {
  state = {
    profileDialogOpen: false,
  };

  afterProfileCreate = ({ id }) => {
    const { afterProfileCreate } = this.props;
    if (afterProfileCreate) {
      afterProfileCreate.defer(id);
    }
  };

  closeProfileDialog = () => {
    this.setState({ profileDialogOpen: false });
  };

  openProfileDialog = () => {
    const { managingUserId } = this.props;
    const managingNewUserId = managingUserId || currentUser().id;

    ManagedClientActions.updateStore(
      new Client({ managed_by_id: managingNewUserId }).toJS()
    );
    this.setState({ profileDialogOpen: true });
  };

  render() {
    const {
      intl,
      athletes,
      disabled,
      disabledText,
      header,
      headerStyle,
      preventProfileCreation,
      style,
      fullWidth,
      value,
      errorText,
      hintText,
      ProfileNameSuffix,
      onChange,
    } = this.props;
    const valueOverride = disabled ? -1 : value;
    const menuItems = disabled
      ? [{ label: disabledText, value: -1 }]
      : athletes
          .map(athlete => ({
            label: (
              <FlexBoxCenter style={{ height: 40 }}>
                <UserAvatar user={athlete} style={{ marginRight: '1rem' }} />
                {athlete.name()}
                <ProfileNameSuffix profile={athlete} />
              </FlexBoxCenter>
            ),
            value: athlete.id,
          }))
          .toJS();

    if (!preventProfileCreation) {
      menuItems.unshift({
        label: t('.new_item', intl, __filenamespace),
        value: 0,
      });
    }

    const { profileDialogOpen } = this.state;

    const handleSelectItem = e => {
      const { value: eventValue } = e.target;

      if (eventValue === 0) {
        this.openProfileDialog();
        return;
      }

      // eslint-disable-next-line no-undef
      onChange(_, _, eventValue);
    };

    return (
      <div style={style}>
        <div
          style={merge(
            boldText,
            { paddingBottom: 10, marginTop: 10 },
            headerStyle
          )}
        >
          {header}
        </div>
        <Dropdown
          className="profile-selector"
          errorText={errorText}
          fullWidth={fullWidth}
          placeholder={hintText || t('.hint_text', intl, __filenamespace)}
          value={valueOverride}
          items={menuItems}
          onChange={handleSelectItem}
        />
        <ManageProfilesDialog
          open={profileDialogOpen}
          closeDialog={this.closeProfileDialog}
          afterCreate={this.afterProfileCreate}
        />
      </div>
    );
  }
}

ProfileSelector.propTypes = {
  /**
   * Alt action to defer to after profile creation.
   *
   * @param {integer} customerUserId: The id of the selected profile.
   */
  afterProfileCreate: PropTypes.func.isRequired,

  /**
   * Immutable.List of athletes to be shown in the select dropdown. Defaults to Immutable.List().
   */
  athletes: PropTypes.instanceOf(List),

  /**
   * If true, the select field will be disabled. Defaults to false.
   */
  disabled: PropTypes.bool,

  /**
   * If true, the select field will take up the full width of its container. Defaults to true.
   */
  fullWidth: PropTypes.bool,

  header: PropTypes.string,

  /**
   * Callback function fired when a profile is selected.
   *
   * @param {event} event: Click event targeting the menu item that was selected.
   * @param {integer} key: The index of the selected menu item.
   * @param {integer} customerUserId: The id of the selected profile.
   */
  onChange: PropTypes.func.isRequired,

  /**
   * If true, the option to create a new profile will be hidden.
   */
  preventProfileCreation: PropTypes.bool,

  /**
   * The id of the currently selected profile.
   */
  value: PropTypes.number,

  headerStyle: PropTypes.object,
  errorText: PropTypes.string,
  hintText: PropTypes.string,
  ProfileNameSuffix: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

ProfileSelector.defaultProps = {
  athletes: List(),
  disabled: false,
  fullWidth: true,
  header: '',
  value: 0,
  preventProfileCreation: false,
  errorText: '',
  hintText: '',
  ProfileNameSuffix: () => null,
  headerStyle: {},
};

export default injectIntl(ProfileSelector);
