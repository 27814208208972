import * as React from 'react';
import MembershipListing from 'memberships/components/MembershipListing.jsx';

import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import MembershipListingActions from 'memberships/actions/MembershipListingActions.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import { isLoggedIn } from 'shared/utils/UserUtils.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions';

class StaffMembershipListing extends React.Component {
  componentDidMount() {
    if (isLoggedIn()) {
      AthleteActions.list({
        fields: ['active_membership_id', 'permitted_membership_ids'],
      });
    }
    MembershipListingActions.listRequested({});
    EventTypeListingActions.list();
    RetailCategoryListingActions.list();
    CreditPassListingActions.listRequested({});
  }

  render() {
    return (
      <MembershipListing
        className="iphone-x-content"
        // eslint-disable-next-line react/destructuring-assignment
        readOnly={this.props.readOnly}
      />
    );
  }
}

export default StaffMembershipListing;
