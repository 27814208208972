class BrowseEventsActions {
  constructor() {
    this.generateActions(
      'closeFiltersDrawer',
      'closeWaitListDrawer',
      'dateSelect',
      'eventTypeFilterUpdated',
      'sportTypeFilterUpdated',
      'listEventTypeError',
      'listEventTypeSuccess',
      'listEvents',
      'listEventsError',
      'listEventsSuccess',
      'listLocationError',
      'listLocationSuccess',
      'listStaffError',
      'listStaffSuccess',
      'locationFilterUpdated',
      'mounted',
      'openFiltersDrawer',
      'openWaitListDrawer',
      'startDateChange',
      'staffFilterUpdated',
      'toggleBrowseViewMode',
      'searchFilterUpdated',
      'listSportTypeSuccess',
      'listSportTypeError'
    );
  }
}

export default alt.createActions(BrowseEventsActions);
