import { List, Set } from 'immutable';
import ServiceFee from 'shared/records/ServiceFee';
import ServiceFeeArchivingActions from 'shared/actions/service_fee/ServiceFeeArchivingActions';
import ServiceFeeCreationActions from 'shared/actions/service_fee/ServiceFeeCreationActions';
import ServiceFeeEditingActions from 'shared/actions/service_fee/ServiceFeeEditingActions';
import ServiceFeeListingActions from 'shared/actions/service_fee/ServiceFeeListingActions';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import FeeRateListingActions from 'shared/actions/fee_rates/FeeRateListingActions';
import FeeRateEditingActions from 'shared/actions/fee_rates/FeeRateEditingActions';
import FeeRateCreationActions from 'shared/actions/fee_rates/FeeRateCreationActions';
import TeamTypeListingStore from 'shared/stores/TeamTypeListingStore.jsx';
import TeamTypeListingActions from 'shared/actions/TeamTypeListingActions.jsx';
import FeeRateListingStore from '../fee_rates/FeeRateListingStore';

class ServiceFeeListingStore extends UpperHandStore {
  constructor() {
    super();

    this.serviceFeesList = List();
    this.archivedCodes = List();
    this.isLoading = false;

    this.selectedCodeTypes = Set();
    this.selectedEventTypeIds = Set();
    this.selectedRetailCategoryIds = Set();
    this.selectedTaxRateIds = Set();
    this.selectedTeamTypeIds = Set();

    this.bindListeners({
      list: ServiceFeeListingActions.LIST,
      listSuccess: ServiceFeeListingActions.LIST_SUCCESS,
      listError: ServiceFeeListingActions.LIST_ERROR,

      feeCreated: ServiceFeeCreationActions.SAVE_SUCCESS,
      feeUpdated: ServiceFeeEditingActions.SAVE_SUCCESS,
      feeArchived: ServiceFeeArchivingActions.ARCHIVE_SUCCESS,

      setServiceFeeEventTypes: EventTypeListingActions.LIST_SUCCESS,
      setServiceFeeRetailCategories: RetailCategoryListingActions.LIST_SUCCESS,

      setServiceFeeRates: [
        FeeRateListingActions.LIST_SUCCESS,
        FeeRateEditingActions.SAVE_SUCCESS,
        FeeRateCreationActions.SAVE_SUCCESS,
      ],
      setServiceFeeTeamTypes: TeamTypeListingActions.LIST_SUCCESS,
    });
  }

  list(options) {
    const { page = 1 } = options || {};

    this.isLoading = true;

    uhApiClient.get({
      url: 'accounting_codes',
      data: { page, per_page: 100 },
      success: ServiceFeeListingActions.listSuccess,
      error: ServiceFeeListingActions.listError,
    });
  }

  listSuccess(data) {
    const {
      accounting_codes: ServiceFeeListData,
      page,
      per_page: perPage,
      total_count: totalCount,
    } = data;
    const partitionedList = List(ServiceFeeListData.map(c => new ServiceFee(c)))
      .groupBy(c => c?.charge_type === 'Fee')
      .get(true, List())
      .groupBy(c => c.archived);

    this.serviceFeesList = partitionedList.get(false, List());
    this.archivedCodes = partitionedList.get(true, List());
    const codeCount = this.serviceFeesList.size + this.archivedCodes.size;

    if (ServiceFeeListData.length === perPage && totalCount > codeCount) {
      this.list({ page: page + 1 });
    } else {
      this.isLoading = false;

      this.setServiceFeeEventTypes();
      this.setServiceFeeTeamTypes();
      this.setServiceFeeRetailCategories();
      this.setServiceFeeRates();
      this.setSelectedFeeTypes();
    }
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing accounting codes', args);
  }

  feeCreated(data) {
    this.serviceFeesList = this.serviceFeesList
      .push(new ServiceFee(data))
      .sort(this.sortByTypeAndName);

    this.setServiceFeeEventTypes();
    this.setServiceFeeTeamTypes();
    this.setServiceFeeRetailCategories();
    this.setServiceFeeRates();
    this.setSelectedFeeTypes();
  }

  feeUpdated(data) {
    const index = this.serviceFeesList.findIndex(c => c.id === data.id);

    this.serviceFeesList = this.serviceFeesList
      .set(index, new ServiceFee(data))
      .sort(this.sortByTypeAndName);

    this.setServiceFeeEventTypes();
    this.setServiceFeeRetailCategories();
    this.setServiceFeeRates();
    this.setSelectedFeeTypes();
  }

  feeArchived(data) {
    const [index, code] = this.serviceFeesList.findEntry(
      c => c.id === data.id,
      null,
      [-1, null]
    );

    if (index >= 0) {
      this.serviceFeesList = this.serviceFeesList.delete(index);
      this.archivedCodes = this.archivedCodes
        .push(code.merge(new ServiceFee(data)))
        .sort(this.sortByTypeAndName);

      this.setServiceFeeEventTypes();
      this.setServiceFeeRetailCategories();
      this.setServiceFeeRates();
      this.setSelectedFeeTypes();
    }
  }

  setSelectedFeeTypes() {
    this.selectedCodeTypes = this.serviceFeesList.reduce(
      (codeTypes, code) => codeTypes.add(code.code_type),
      Set()
    );
  }

  setServiceFeeEventTypes() {
    this.waitFor(EventTypeListingStore);
    const { isLoading, eventTypes } = EventTypeListingStore.getState();
    if (!isLoading) {
      this.serviceFeesList = this.serviceFeesList.map(c =>
        c.set(
          'event_types',
          eventTypes.filter(t => c.event_type_ids.has(t.id))
        )
      );

      this.archivedCodes = this.archivedCodes.map(c =>
        c.set(
          'event_types',
          eventTypes.filter(t => c.event_type_ids.has(t.id))
        )
      );

      this.selectedEventTypeIds = this.serviceFeesList.reduce(
        (selectedIds, code) => code.event_type_ids.union(selectedIds),
        Set()
      );
    }
  }

  setServiceFeeTeamTypes() {
    this.waitFor(TeamTypeListingStore);
    const { isLoading, teamTypes } = TeamTypeListingStore.getState();
    if (!isLoading) {
      this.serviceFeesList = this.serviceFeesList.map(c =>
        c.set(
          'team_types',
          teamTypes.filter(t => c.team_type_ids.has(t.id))
        )
      );

      this.archivedCodes = this.archivedCodes.map(c =>
        c.set(
          'team_types',
          teamTypes.filter(t => c.team_type_ids.has(t.id))
        )
      );

      this.selectedTeamTypeIds = this.serviceFeesList.reduce(
        (selectedIds, code) => code.team_type_ids.union(selectedIds),
        Set()
      );
    }
  }

  setServiceFeeRetailCategories() {
    this.waitFor(RetailCategoryListingStore);

    const { retailCategories } = RetailCategoryListingStore.getState();

    this.serviceFeesList = this.serviceFeesList.map(c =>
      c.set(
        'retail_categories',
        retailCategories.filter(t => c.retail_category_ids.has(t.id))
      )
    );

    this.archivedCodes = this.archivedCodes.map(c =>
      c.set(
        'retail_categories',
        retailCategories.filter(t => c.retail_category_ids.has(t.id))
      )
    );

    this.selectedRetailCategoryIds = this.serviceFeesList.reduce(
      (selectedIds, code) => code.retail_category_ids.union(selectedIds),
      Set()
    );
  }

  setServiceFeeRates() {
    this.waitFor(FeeRateListingStore);
    const { feeRates } = FeeRateListingStore.getState();

    this.serviceFeesList = this.serviceFeesList.map(c =>
      c.set(
        'fee_rates',
        feeRates.filter(t => c.service_fee_ids.includes(t.id))
      )
    );

    this.archivedCodes = this.archivedCodes.map(c =>
      c.set(
        'fee_rates',
        feeRates.filter(t => c.service_fee_ids.includes(t.id))
      )
    );
  }

  // eslint-disable-next-line class-methods-use-this
  sortByTypeAndName(a, b) {
    if (a.code_type < b.code_type) {
      return -1;
    }
    if (b.code_type < a.code_type) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    if (b.name < a.name) {
      return 1;
    }
    return 0;
  }
}

export default alt.createStore(
  ServiceFeeListingStore,
  'ServiceFeeListingStore'
);
