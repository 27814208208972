import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Grid } from '@upperhand/playmaker';
import Button from '@mui/material/Button';

import CartCommitButton from 'shared/components/checkout/CartCommitButton.jsx';
import CreditPassCredits from 'credit_passes/components/_CreditPassCredits.jsx';
import CreditPassExpiration from 'credit_passes/components/_CreditPassExpiration.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import Header from 'point_of_sale/components/Header.jsx';
import POSCreditPassPackageActions from 'point_of_sale/actions/POSCreditPassPackageActions.js';
import ProductInformation from 'shared/components/ProductInformation.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import ProfileSelector from 'shared/components/_ProfileSelector.jsx';
import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  benefitsBox: {
    backgroundColor: uhColors.lightYellow,
    marginBottom: 25,
    padding: '12px 12px 14px 18px',
  },

  cancelButton: {
    height: 50,
    marginTop: 10,
  },

  contentContainer: {
    padding: 20,
    lineHeight: '24px',
  },
  purchaseTarget: {
    fontWeight: 600,
    fontSize: 15,
  },
  creditPassExpiration: {
    display: 'block',
    marginTop: 15,
    marginBottom: 12,
    fontSize: 15,
    lineHeight: '24px',
    fontStyle: 'italic',
  },
  purchasedComponent: {
    color: uhColors.darkRed,
    textAlign: 'right',
  },
};

const CreditPassRegistering = React.memo(
  ({
    intl,
    posCreditPassPackageStore,
    posEventTypeListStore: { isLoading: typesLoading, eventTypes },
    posSpecificEventsListStore: { isLoading: eventsLoading, events },
    posStore,
  }) => {
    const {
      availableProfiles,
      changed,
      clientsPassPurchases,
      creditPass,
      creditPassPackage,
      isLoading: creditPassLoading,
      orderItemId,
    } = posCreditPassPackageStore;

    const profileCanPurchase = clientId => {
      if (clientsPassPurchases.size === 0 || creditPassLoading) {
        return true;
      }

      // we default to 10 here, if the user has never purchased they will not be in this result.
      const count = clientsPassPurchases.get(clientId, {
        purchases_remaining: 10,
      }).purchases_remaining;

      return count > 0;
    };

    const renderProfileSuffix = ({ profile }) => {
      if (profileCanPurchase(profile.id)) return null;

      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={styles.purchasedComponent}>
              {t('.Purchased', intl, __filenamespace)}
            </div>
          </Grid>
        </Grid>
      );
    };

    return (
      <div className="credit-pass-registering">
        <Header selectedClient={posStore.selectedClient}>
          <ProductInformation
            price={<FormattedCurrency value={creditPass.price} fromCents />}
            primaryText={creditPass.name}
            typeText={t('.credit_pass', intl, __filenamespace)}
          />
        </Header>
        <SpinWhileLoading
          isLoading={typesLoading || eventsLoading || creditPassLoading}
        >
          <div style={styles.contentContainer}>
            <div style={{ marginBottom: 25 }}>{creditPass.description}</div>

            {creditPass.credit_pass_credits.size > 0 && (
              <div style={styles.benefitsBox}>
                <CreditPassCredits
                  creditPass={creditPass}
                  events={events}
                  eventTypes={eventTypes}
                />

                <CreditPassExpiration
                  creditPassId={creditPass.id}
                  style={styles.creditPassExpiration}
                />
              </div>
            )}

            <ProfileSelector
              preventProfileCreation
              header={t('.for_whom', intl, __filenamespace)}
              headerStyle={styles.purchaseTarget}
              hintText={t('.choose_profile', intl, __filenamespace)}
              athletes={availableProfiles}
              value={creditPassPackage.client_id}
              errorText={
                profileCanPurchase(creditPassPackage.client_id)
                  ? ''
                  : t('.PurchasedTooltip', intl, __filenamespace)
              }
              afterProfileCreate={() => null}
              ProfileNameSuffix={renderProfileSuffix}
              onChange={(_, __, id) =>
                POSCreditPassPackageActions.selectProfile(id)
              }
            />

            <CartCommitButton
              handleAddToCartClick={() => {
                POSCreditPassPackageActions.addToCartClick(creditPassPackage);
              }}
              handleUpdateCartClick={() => {
                POSCreditPassPackageActions.updateCartClick(orderItemId, {
                  orderable: creditPassPackage,
                });
              }}
              handleViewInCartClick={
                POSCreditPassPackageActions.viewInCartClicked
              }
              hasOrderItemId={orderItemId}
              hasUpdate={changed}
              itemIsValid={profileCanPurchase(creditPassPackage.client_id)}
            />
            <Button
              fullWidth
              variant="contained"
              color="default"
              style={styles.cancelButton}
              onClick={POSCreditPassPackageActions.cancelClicked}
            >
              {t('actions.cancel', intl, __filenamespace)}
            </Button>
          </div>
        </SpinWhileLoading>
      </div>
    );
  }
);

export default injectIntl(CreditPassRegistering);
