import moment from 'moment-timezone';
import { Iterable, Record } from 'immutable';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';

class Registration extends Record({
  id: null,
  client_id: null,
  credit_expires_at: null,
  credit_redemption_id: null,
  event_id: null,
  registration_package_id: null,
  schedule_id: null,
  session_id: null,
  order_id: null,
  order_item_id: null,
  paid: false,
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    const credit_expires_at = obj.credit_expires_at
      ? moment.tz(obj.credit_expires_at, customerTZ())
      : null;

    super(merge(obj, { credit_expires_at }));
  }

  isCreditBased() {
    return !!this.credit_redemption_id;
  }
}

export default Registration;
