import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TextField, Spinner, Typography } from '@upperhand/playmaker';

import { t } from 'shared/utils/LocaleUtils';

import './styles.scss';

function ProductSelector({
  searchValue,
  products,
  productsLoading,
  onProductSearch,
  onProductSelect,
}) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const productSelectorRef = useRef(null);

  useEffect(() => {
    const handleWindowClick = e => {
      if (!productSelectorRef.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    window.addEventListener('click', handleWindowClick);

    return () => {
      window.removeEventListener('click', handleWindowClick);
    };
  }, []);

  return (
    <div className="product-selector" ref={productSelectorRef}>
      <TextField
        value={searchValue}
        icon="search"
        iconPlacement="left"
        placeholder={t('.purchased_product_placeholder', intl, __filenamespace)}
        onChange={onProductSearch}
        onFocus={() => setOpen(true)}
      />
      {open && (
        <div className="product-selector__list">
          {productsLoading && (
            <div className="product-selector__list-spinner">
              <Spinner />
            </div>
          )}
          {!productsLoading &&
            products?.map(product => (
              <div
                key={product.id}
                role="presentation"
                className="product-selector__list-item"
                onClick={() => {
                  onProductSelect(product);
                  setOpen(false);
                }}
              >
                <p>{product.name || product.title}</p>
              </div>
            ))}
          {!productsLoading && products?.length === 0 && (
            <Typography className="product-selector__list-empty">
              No products
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

ProductSelector.propTypes = {
  searchValue: PropTypes.string,
  products: PropTypes.array,
  productsLoading: PropTypes.bool,
  onProductSearch: PropTypes.func,
  onProductSelect: PropTypes.func,
};

ProductSelector.defaultProps = {
  searchValue: '',
  products: [],
  productsLoading: false,
  onProductSearch: () => null,
  onProductSelect: () => null,
};

export default ProductSelector;
