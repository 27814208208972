import * as React from 'react';
import { Tabs, Typography } from '@upperhand/playmaker';

import CheckIn from 'containers/events/admin/sessionSummaryDrawer/components/checkIn/CheckIn.jsx';
import Details from 'containers/events/admin/sessionSummaryDrawer/components/details/Details.jsx';
import Notes from 'containers/events/admin/sessionSummaryDrawer/components/notes/Notes.jsx';
import WaitList from 'containers/events/admin/sessionSummaryDrawer/components/waitlist/Waitlist.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import SessionSummaryDrawerActions from '../Actions';

const SHOW_WAITLIST = false;

// Kind of a useless variable, but lets you change all tab fonts at once.
const TAB_TYPOGRAPHY_VARIANT = 'subtitle2';

function SessionTabs({
  total,
  intl,
  isLoading,
  selectedTab,
  sessionId,
  eventId,
  isTeamEvent,
}) {
  const handleChangeTab = (_, value) =>
    SessionSummaryDrawerActions.selectTab.defer(value);

  return (
    <Tabs
      value={selectedTab}
      variant="fullWidth"
      onChange={handleChangeTab}
      tabItems={[
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.details', intl, __filenamespace)}
            </Typography>
          ),
          content: (
            <Details
              intl={intl}
              sessionId={sessionId}
              eventId={eventId}
              loadingSession={isLoading}
              isTeamEvent={isTeamEvent}
            />
          ),
          key: 'details',
        },
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.checkIn', intl, __filenamespace, { total })}
            </Typography>
          ),
          content: <CheckIn intl={intl} sessionId={sessionId} />,
          key: 'check-in',
        },
        {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.notes', intl, __filenamespace)}
            </Typography>
          ),
          content: <Notes intl={intl} sessionId={sessionId} />,
          key: 'notes',
        },
        SHOW_WAITLIST && {
          label: (
            <Typography variant={TAB_TYPOGRAPHY_VARIANT}>
              {t('.waitList', intl, __filenamespace, {
                count: 0,
              })}
            </Typography>
          ),
          content: <WaitList intl={intl} sessionId={sessionId} />,
          key: 'wait-list',
        },
      ].filter(x => !!x)}
    />
  );
}

export default SessionTabs;
