import * as React from 'react';
import { injectIntl } from 'react-intl';

import { Dropdown, Grid, Typography, Button } from '@upperhand/playmaker';

import FilterDropdown from 'components/FilterDropdown.jsx';
import SearchBar from 'shared/components/SearchBar.jsx';
import ChatBubbleIcon from 'shared/components/icons/ChatBubble.jsx';

import { t } from 'shared/utils/LocaleUtils.js';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import BrowseEventsActions from '../Actions';

const styles = {
  root: {
    padding: '16px 8px',
    width: '100%',
  },
  filterHeader: {
    height: 42,
    borderBottom: `1px solid ${uhColors.lighterGrey}`,
    display: 'flex',
    alignItems: 'center',
  },
  searchBarDivStyles: {
    display: 'flex',
    backgroundColor: '#454952',
    alignItems: 'center',
    marginBottom: '20px',
    height: '100px',
  },
  disableButtonStyles: {
    backgroundColor: uhColors.tunaBlack,
  },
  searchbarIconStyles: {
    display: 'none',
  },
  searchbarStyles: {
    padding: '0 20px',
    fontSize: 16,
    backgroundColor: uhColors.tunaBlack,
  },
  searchbarInputStyles: {
    fontWeight: 'bold',
    color: '#FFF',
  },
  announcementWrapper: {
    whiteSpace: 'pre-wrap',
    backgroundColor: 'var(--color-white)',
    padding: '2rem',
  },
};

const BrowseEventsRoomy = React.memo(
  ({
    children,
    eventItems,
    eventTypeFilter,
    sportTypeItems,
    sportTypeFilter,
    intl,
    viewMode,
    locationItems,
    locationFilter,
    staffItems,
    staffFilter,
    viewOptions,
  }) => {
    const [showSearch, setShowSearch] = React.useState(false);
    return (
      <div style={styles.root}>
        {showSearch && (
          <div style={styles.searchBarDivStyles}>
            <Button
              icon="close"
              onClick={() => {
                BrowseEventsActions.searchFilterUpdated('');
                setShowSearch(false);
              }}
              styles={styles.disableButtonStyles}
            />
            <SearchBar
              onTypingEnd={value =>
                BrowseEventsActions.searchFilterUpdated(value)
              }
              iconStyle={styles.searchbarIconStyles}
              style={styles.searchbarStyles}
              inputStyle={styles.searchbarInputStyles}
            />
          </div>
        )}
        <Grid container spacing={2} alignContent="flex-start" justify="center">
          <Grid
            container
            item
            spacing={2}
            xs={locationItems.length > 0 ? 8 : 12}
            alignContent="flex-start"
          >
            <Grid container item spacing={1} xs={12}>
              <Grid container item spacing={1} xs={12}>
                <Grid item xs={1}>
                  {showSearch ? (
                    <Button
                      onClick={() => setShowSearch(true)}
                      type="tertiary"
                      rounded
                      icon="search"
                    />
                  ) : (
                    <Button
                      onClick={() => setShowSearch(true)}
                      rounded
                      icon="search"
                      style={styles.disableButtonStyles}
                    />
                  )}
                </Grid>
                <Grid item xs={2} className="browse-events__filter-item">
                  <FilterDropdown
                    items={eventItems}
                    filterValue={eventTypeFilter}
                    name="categories"
                    onChange={BrowseEventsActions.eventTypeFilterUpdated}
                  />
                </Grid>
                <Grid item xs={2} className="browse-events__filter-item">
                  <FilterDropdown
                    items={staffItems}
                    filterValue={staffFilter}
                    name="staff"
                    onChange={BrowseEventsActions.staffFilterUpdated}
                  />
                </Grid>
                <Grid item xs={2} className="browse-events__filter-item">
                  <FilterDropdown
                    items={locationItems}
                    filterValue={locationFilter}
                    name="locations"
                    onChange={BrowseEventsActions.locationFilterUpdated}
                  />
                </Grid>
                <Grid item xs={3} className="browse-events__filter-item">
                  <FilterDropdown
                    items={sportTypeItems}
                    filterValue={sportTypeFilter}
                    name="sport type"
                    onChange={BrowseEventsActions.sportTypeFilterUpdated}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Dropdown
                    classes={{
                      selectedItem: 'browse-events__filter-item-selected',
                    }}
                    items={viewOptions}
                    fullWidth
                    outline={false}
                    onChange={BrowseEventsActions.toggleBrowseViewMode}
                    rounded
                    value={viewMode}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12}>
              {children}
            </Grid>
          </Grid>
          {locationItems.length > 0 && (
            <Grid container item spacing={2} xs={4} alignContent="flex-start">
              <Grid item container xs={12} spacing={1}>
                <Grid item>
                  <ChatBubbleIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h4">
                    {t('.announcement', intl, __filenamespace)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div style={styles.announcementWrapper}>
                  <p>
                    {currentCustomer().announcement_text ||
                      t('.announcement_empty', intl, __filenamespace)}
                  </p>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
);

export default injectIntl(BrowseEventsRoomy);
