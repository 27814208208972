import * as React from 'react';
import { Grid, Spinner } from '@upperhand/playmaker';

import ClientProfileDrawer from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/ClientProfileDrawer.jsx';
import Filters from 'containers/events/admin/clients/components/Filters.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import Table from 'containers/events/admin/clients/components/Table.jsx';
import altContainer from 'shared/hocs/altContainer.jsx';
import { ClientDataStore } from 'dataStores';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';
import { List, Set } from 'immutable';
import ClientsActions from './actions';
import ClientsStore from './store';

const styles = {
  root: {
    padding: '12px 0',
    overflow: 'hidden',
  },
};

function Clients({
  clientsStore: {
    clientIds,
    loading,
    page,
    perPage,
    search,
    filtersPresent,
    totalCount,
  },
  event,
  isClient,
  isTeamEvent,
}) {
  const ids = event.participant_ids;
  const eventId = event.id;
  React.useEffect(() => {
    // Workaround for dispatch in a dispatch error when the tab changes as a
    // result of an event load. Need long term solution.
    if (eventId && isTeamEvent && ids.size > 0) {
      ClientsActions.mounted.defer({ eventId, ids, team: isTeamEvent });
    } else if (eventId) {
      ClientsActions.mounted.defer({ eventId, ids: List(), team: isTeamEvent });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);
  return (
    <div style={styles.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters search={search} isClient={isClient} />
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Table
                clientIds={Set(clientIds)}
                eventId={eventId}
                filtersPresent={filtersPresent}
                isTeamEvent={isTeamEvent}
              />
            </Grid>
            <Grid item xs={12}>
              <Paginator
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={ClientsActions.pageSelected}
              />
            </Grid>
          </>
        )}
      </Grid>
      <ClientProfileDrawer
        onSessionSummaryOpen={() => {
          ClientProfileDrawerActions.closeScheduleDrawer();
          ClientProfileDrawerActions.closeDrawer();
        }}
      />
    </div>
  );
}

export default altContainer({
  stores: {
    clientsStore: ClientsStore,
    clientDataStore: ClientDataStore,
  },
})(Clients);
