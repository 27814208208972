import { List } from 'immutable';
import { PaymentMethodSource, ClientSource } from 'sources';
import { UpperHandStore } from 'dataStores';

import ClientBillingSettingsActions from './Actions';

class ClientBillingSettingsStore extends UpperHandStore {
  constructor() {
    super();

    this.isLoading = true;
    this.userId = null;
    this.paymentMethods = List();
    this.defaultPaymentMethod = null;
    this.accessToVault = false;
    this.customerUserId = null;

    this.bindListeners({
      mounted: ClientBillingSettingsActions.mounted,

      setDefault: ClientBillingSettingsActions.setDefault,
      setDefaultSuccess: ClientBillingSettingsActions.setDefaultSuccess,
      setDefaultError: ClientBillingSettingsActions.setDefaultError,

      deletePaymentMethod: ClientBillingSettingsActions.deletePaymentMethod,
      deletePaymentMethodSuccess:
        ClientBillingSettingsActions.deletePaymentMethodSuccess,
      deletePaymentMethodError:
        ClientBillingSettingsActions.deletePaymentMethodError,

      listPaymentMethods: ClientBillingSettingsActions.listPaymentMethods,
      listPaymentMethodsSuccess:
        ClientBillingSettingsActions.listPaymentMethodsSuccess,
      listPaymentMethodsError:
        ClientBillingSettingsActions.listPaymentMethodsError,

      updateCustomerUserAccess:
        ClientBillingSettingsActions.updateCustomerUserAccess,
      updateCustomerUserAccessSuccess:
        ClientBillingSettingsActions.updateCustomerUserAccessSuccess,
      updateCustomerUserAccessError:
        ClientBillingSettingsActions.updateCustomerUserAccessError,
    });
  }

  reset() {
    this.isLoading = true;

    this.userId = null;
    this.paymentMethods = List();
    this.defaultPaymentMethod = null;
    this.accessToVault = false;
    this.customerUserId = null;
  }

  mounted([userId, customerUserId, access, staffMode]) {
    this.reset();
    if (userId) {
      this.userId = userId;
    }
    this.accessToVault = access;
    this.customerUserId = customerUserId;
    if (this.accessToVault || !staffMode) {
      this.listPaymentMethods();
    } else {
      this.isLoading = false;
    }
  }

  updateCustomerUserAccess() {
    this.isLoading = true;
    ClientSource.patch({
      id: this.customerUserId,
      recordAttributes: { access_to_vault: !this.accessToVault },
      success: ClientBillingSettingsActions.updateCustomerUserAccessSuccess,
      error: ClientBillingSettingsActions.updateCustomerUserAccessError,
    });
  }

  updateCustomerUserAccessSuccess(data) {
    this.isLoading = false;

    this.accessToVault = data.access_to_vault;
  }

  updateCustomerUserAccessError(...args) {
    this.isLoading = false;
    this.notifyError('error updating access', args);
  }

  listPaymentMethods() {
    this.isLoading = true;

    PaymentMethodSource.list({
      userId: this.userId,
      success: ClientBillingSettingsActions.listPaymentMethodsSuccess,
      error: ClientBillingSettingsActions.listPaymentMethodsError,
    });
  }

  listPaymentMethodsSuccess({ payment_methods: paymentMethods }) {
    this.isLoading = false;
    this.isSaving = false;
    this.paymentMethods = paymentMethods;
    this.defaultPaymentMethod = this.paymentMethods.find(pm =>
      pm.get('default')
    );
  }

  listPaymentMethodsError(...args) {
    this.isLoading = false;
    this.notifyError('error listing payment methods', args);
  }

  setDefault(paymentMethodId) {
    PaymentMethodSource.update({
      userId: this.userId,
      paymentMethodId,
      recordAttributes: {
        default: true,
      },
      success: ClientBillingSettingsActions.setDefaultSuccess,
      error: ClientBillingSettingsActions.setDefaultError,
    });
  }

  setDefaultSuccess() {
    this.listPaymentMethods();
  }

  setDefaultError(...args) {
    this.notifyError('error setting default payment method', args);
  }

  deletePaymentMethod(paymentMethodId) {
    PaymentMethodSource.destroy({
      userId: this.userId,
      paymentMethodId,
      success: ClientBillingSettingsActions.deletePaymentMethodSuccess,
      error: ClientBillingSettingsActions.deletePaymentMethodError,
    });
  }

  deletePaymentMethodSuccess() {
    this.listPaymentMethods();
  }

  deletePaymentMethodError(...args) {
    this.notifyError('error deleting payment method', args);
  }
}

export default alt.createStore(
  ClientBillingSettingsStore,
  'ClientBillingSettingsStore'
);
