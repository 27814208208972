import React from 'react';
import { injectIntl } from 'react-intl';
import { Typography } from '@upperhand/playmaker';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';

import ContentTable from 'team_mgmt/index/components/_ContentTable.jsx';
import FilterDrawer from 'team_mgmt/index/components/_FilterDrawer.jsx';
import ContentToolbar from 'team_mgmt/index/components/_ContentToolbar.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import { ContentCards } from 'team_mgmt/index/components/_ContentCards.jsx';

import TeamListActions from 'team_mgmt/index/actions/TeamListActions.jsx';
import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';

import { hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const styles = {
  smallLayoutContainer: { width: '100%', margin: '0px auto 0px auto' },
};

const NoTeams = injectIntl(({ intl }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      height: '3rem',
      marginTop: '1rem',
    }}
  >
    <Typography variant="subtitle2">
      {t('.no_team', intl, __filenamespace)}
    </Typography>
  </Box>
));

function Content(props) {
  const { readOnly, teamList, automations, filter, teamTypes } = props;
  const { filterDrawerOpen, teamStatusFilters, statusFilters, typeFilters } =
    filter;
  const { isLoading, teams, page, perPage, totalCount } = teamList;
  const { teamTypes: teamTypesList } = teamTypes;
  const [selectedTeams, setSelectedTeams] = React.useState([]);

  React.useEffect(() => {
    TeamListActions.reset();
    TeamActions.list({
      fields: ['team_detail', 'team_type'],
    });
    setSelectedTeams([]);
  }, []);

  const handlePageSelect = (_page, _perPage) => {
    TeamListActions.updatePageParams({ page: _page, perPage: _perPage });
    TeamActions.list({
      fields: ['team_detail', 'team_type'],
    });
  };
  const verifyAdminRole = () => hasAdminPermission();
  return (
    <Box className="iphone-x-content">
      <ContentToolbar
        readOnly={readOnly}
        selectedTeams={selectedTeams}
        allowDeleteTeams={verifyAdminRole()}
      />
      <Divider />
      <SpinWhileLoading isLoading={isLoading} spinWhile="isLoading">
        {teams.size > 0 ? (
          <ResponsiveElement
            largeScreen={
              <ContentTable
                teams={teams}
                selectedTeams={selectedTeams}
                setSelectedTeams={setSelectedTeams}
                automations={automations}
                allowDeleteTeams={verifyAdminRole()}
              />
            }
            smallScreen={
              <Box sx={styles.smallLayoutContainer}>
                <ContentCards
                  teams={teams}
                  selectedTeams={selectedTeams}
                  setSelectedTeams={setSelectedTeams}
                  automations={automations}
                  allowDeleteTeams={verifyAdminRole()}
                />
              </Box>
            }
          />
        ) : (
          <NoTeams />
        )}
      </SpinWhileLoading>
      <Paginator
        className="teams__pagination"
        currentPage={page}
        perPage={perPage}
        totalCount={totalCount}
        onPageSelect={handlePageSelect}
        pageLimit={0}
        showInfo
      />
      <FilterDrawer
        showStatusesInFilter
        filterDrawerOpen={filterDrawerOpen}
        teamStatusFilters={teamStatusFilters}
        statusFilters={statusFilters}
        typeFilters={typeFilters}
        teamTypes={teamTypesList}
      />
    </Box>
  );
}

Content.propTypes = {
  readOnly: PropTypes.bool,
  teamList: PropTypes.object,
  filter: PropTypes.object,
  teamTypes: PropTypes.object,
  automations: PropTypes.object,
};

Content.defaultProps = {
  readOnly: false,
  teamList: {
    teams: List(),
    isLoading: false,
    page: 0,
    perPage: 20,
    totalCount: 0,
  },
  filter: {
    eventStatusFilters: [],
    statusFilters: Map(),
    typeFilters: Map(),
    filterDrawerOpen: false,
  },
  teamTypes: {
    teamTypes: List(),
  },
  automations: {
    descriptions: List(),
  },
};

export default Content;
