import { DefaultSourceActions } from './DefaultSourceActionList';

class RegistrationActions {
  constructor() {
    this.generateActions(
      ...DefaultSourceActions,
      'reschedule',
      'rescheduleSuccess',
      'rescheduleError'
    );
  }
}

export default alt.createActions(RegistrationActions);
