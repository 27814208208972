import React from 'react';
import { injectIntl } from 'react-intl';
import { Drawer } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils.js';

import Orders from '../Orders/OrdersList.jsx';
import ClientInfo from './ClientInfo.jsx';

import ClientProfileDrawerActions from './ClientProfileDrawer/Actions';
import ClientProfileDrawerStore from './ClientProfileDrawer/Store';

function OrdersDrawer({
  intl,
  clientProfileDrawerStore: {
    showOrdersDrawer,
    clientId,
    orderIds,
    pagination,
    tabLoading,
  },
}) {
  return (
    <Drawer
      classes={{ root: 'orders-drawer' }}
      open={showOrdersDrawer}
      title={t('.title', intl, __filenamespace)}
      onClose={ClientProfileDrawerActions.toggleOrdersDrawer}
      content={
        <>
          <ClientInfo clientId={clientId} />
          <Orders
            actions={ClientProfileDrawerActions}
            orderIds={orderIds}
            intl={intl}
            pagination={pagination}
            loading={tabLoading}
            isMobile
          />
        </>
      }
    />
  );
}

export default compose(
  React.memo,
  injectIntl,
  altContainer({
    stores: { clientProfileDrawerStore: ClientProfileDrawerStore },
  })
)(OrdersDrawer);
