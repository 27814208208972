import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { t } from 'shared/utils/LocaleUtils.js';

import CardNotPresentWrapper from './CardNotPresentWrapper.jsx';
import HandpointCloudWrapper from './HandpointCloudWrapper.jsx';

import { CardTypeSegment } from '../fields';
import { PaymentMethod } from '../../types';

const styles = {
  SectionHeader: {
    fontSize: 16,
    marginTop: 20,
    fontWeight: 'bold',
  },
};

const getForm = ({
  allowedPaymentMethods,
  cardUsage,
  hasAccess,
  paymentCard,
  paymentMethod,
  paymentMethods,
}) => {
  if (paymentMethod === PaymentMethod.HANDPOINT_CLOUD) {
    return (
      <HandpointCloudWrapper cardUsage={cardUsage} hasAccess={hasAccess} />
    );
  }
  return (
    <CardNotPresentWrapper
      allowedPaymentMethods={allowedPaymentMethods}
      cardUsage={cardUsage}
      paymentMethods={paymentMethods}
      paymentCard={paymentCard}
      paymentMethod={paymentMethod}
      hasAccess={hasAccess}
    />
  );
};

function PaymentCardForm({
  allowedPaymentMethods,
  cardUsage,
  hasAccess,
  intl,
  paymentCard,
  paymentMethod,
  paymentMethods,
  processingFields,
}) {
  const cardForm = getForm({
    allowedPaymentMethods,
    cardUsage,
    hasAccess,
    paymentCard,
    paymentMethod,
    paymentMethods,
  });

  return (
    <div>
      <h3 style={styles.SectionHeader}>
        {t('.credit_card', intl, __filenamespace)}
      </h3>
      {allowedPaymentMethods.has(PaymentMethod.HANDPOINT_CLOUD) && (
        <CardTypeSegment
          allowedPaymentMethods={allowedPaymentMethods}
          disabled={processingFields}
          selectedCardType={paymentMethod}
        />
      )}
      {cardForm}
    </div>
  );
}

PaymentCardForm.propTypes = {
  allowedPaymentMethods: PropTypes.object.isRequired,
  cardUsage: PropTypes.object.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  paymentCard: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  processingFields: PropTypes.bool.isRequired,
};

export default injectIntl(PaymentCardForm);
