export const BusinessTypesOptions = [
  { value: 'Multi Sport Facility', label: 'Multi Sport Facility' },
  { value: 'Teams And Leagues', label: 'Teams And Leagues' },
  { value: 'Boutique Fitness', label: 'Boutique Fitness' },
  { value: 'Sports Camp', label: 'Sports Camp' },
];

export const SportsOfferedOptions = [
  '3x3 Basketball',
  'Alpine Skiing',
  'Archery',
  'Artistic Gymnastics',
  'Artistic Swimming',
  'Athletics',
  'Badminton',
  'Baseball Softball',
  'Basketball',
  'Beach Handball',
  'Beach Volleyball',
  'Biathlon',
  'Bobsleigh',
  'Boxing',
  'Breaking',
  'Canoe Flatwater',
  'Canoe Slalom',
  'Cross Country Skiing',
  'Curling',
  'Cycling BMX Freestyle',
  'Cycling BMX Racing',
  'Cycling Mountain Bike',
  'Cycling Road',
  'Cycling Track',
  'Diving',
  'Equestrian',
  'Fencing',
  'Figure Skating',
  'Football',
  'Freestyle Skiing',
  'Futsal',
  'Golf',
  'Handball',
  'Hockey',
  'Ice Hockey',
  'Judo',
  'Karate',
  'Luge',
  'Marathon Swimming',
  'Modern Pentathlon',
  'Nordic Combined',
  'Rhythmic Gymnastics',
  'Rowing',
  'Rugby Sevens',
  'Sailing',
  'Shooting',
  'Short Track Speed Skating',
  'Skateboarding',
  'Skeleton',
  'Ski Jumping',
  'Ski Mountaineering',
  'Snowboard',
  'Speed Skating',
  'Sport Climbing',
  'Surfing',
  'Swimming',
  'Soccer',
  'Table Tennis',
  'Taekwondo',
  'Tennis',
  'Trampoline',
  'Triathlon',
  'Volleyball',
  'Water Polo',
  'Weightlifting',
  'Wrestling',
];
