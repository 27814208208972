import { List, Map } from 'immutable';
import CreditPass from 'shared/records/CreditPass.js';
import CreditPassPackage from 'shared/records/CreditPassPackage.js';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import POSCartActions from 'point_of_sale/actions/POSCartActions.jsx';
import POSCartStore from 'point_of_sale/stores/POSCartStore.jsx';
import POSCheckoutActions from 'point_of_sale/actions/POSCheckoutActions.jsx';
import POSCreditPassActions from 'point_of_sale/actions/POSCreditPassActions.js';
import POSCreditPassPackageActions from 'point_of_sale/actions/POSCreditPassPackageActions.js';
import POSCreditPassStore from 'point_of_sale/stores/POSCreditPassStore.js';
import POSProductListActions from 'point_of_sale/actions/POSProductListActions.jsx';
import POSStore from 'point_of_sale/stores/POSStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class POSCreditPassPackageStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      selectCreditPass: POSProductListActions.CREDIT_PASS_SELECTED,
      selectCreditPassEditing: POSCreditPassActions.FETCH_SUCCESS,

      getExistingDataFromCart: POSCartActions.FETCH_SUCCESS,
      selectProfile: POSCreditPassPackageActions.SELECT_PROFILE,
      listingCreditPassPurchasesSuccess:
        POSCreditPassPackageActions.listingCreditPassPurchasesSuccess,
      listingCreditPassPurchasesError:
        POSCreditPassPackageActions.listingCreditPassPurchasesError,

      reset: [
        POSActions.CLIENT_BACK_CLICKED,
        POSCartActions.ADD_SUCCESS,
        POSCartActions.UPDATE_SUCCESS,
        POSCheckoutActions.CLOSE_BUTTON_CLICKED,
        POSCreditPassPackageActions.CANCEL_CLICKED,
        POSCreditPassPackageActions.VIEW_IN_CART_CLICKED,
      ],
    });
  }

  reset() {
    this.creditPass = new CreditPass();
    this.creditPassPackage = new CreditPassPackage();
    this.changed = false;

    this.client = null;
    this.managedProfiles = List();

    this.allProfiles = List();
    this.availableProfiles = List();
    this.clientsPassPurchases = Map();

    this.cartChecked = false;
    this.orderItemId = null;

    this.isLoading = true;
  }

  selectCreditPassEditing() {
    this.waitFor(POSStore);
    this.waitFor(POSCreditPassStore);

    const { selectedClient, managedProfiles } = POSStore.getState();
    this.creditPass = POSCreditPassStore.getState().creditPass;
    this.client = selectedClient;
    this.managedProfiles = managedProfiles;
    this.cartChecked = false;
    this.getExistingDataFromCart();

    if (!this.creditPassPackage.id) {
      this.creditPassPackage = this.creditPassPackage.merge({
        credit_pass_id: this.creditPass.id,
        client_id: this.client.id,
        price: this.creditPass.price,
      });

      this.setAvailableProfiles();
    }
  }

  selectCreditPass() {
    this.waitFor(POSStore);
    this.waitFor(POSCreditPassStore);

    const { selectedClient, managedProfiles } = POSStore.getState();
    this.creditPass = POSCreditPassStore.getState().creditPass;
    this.client = selectedClient;
    this.managedProfiles = managedProfiles;
    this.cartChecked = false;

    if (!this.creditPassPackage.id) {
      this.creditPassPackage = this.creditPassPackage.merge({
        credit_pass_id: this.creditPass.id,
        client_id: this.client.id,
        price: this.creditPass.price,
      });

      this.setAvailableProfiles();
    }
  }

  setAvailableProfiles() {
    this.availableProfiles = this.managedProfiles
      .push(this.client)
      .filter(c => c && c.type === 'Client' && !c.archived);
    // check if this creditpass package has a limit of purchases
    if (this.creditPass.max_num_of_purchases !== 0) {
      this.listingCreditPassPurchases(
        this.availableProfiles.map(x => x.id).toJS()
      );
    } else {
      this.isLoading = false;
    }
  }

  listingCreditPassPurchases(clientIds) {
    this.isLoading = true;

    return uhApiClient.get({
      url: `credit_passes/${this.creditPass.id}/purchases`,
      data: { client_ids: clientIds },
      success: POSCreditPassPackageActions.listingCreditPassPurchasesSuccess,
      error: POSCreditPassPackageActions.listingCreditPassPurchasesError,
    });
  }

  listingCreditPassPurchasesSuccess(data) {
    this.clientsPassPurchases = List(data.results)
      .groupBy(o => o.client_id)
      .map(o => o.first());

    this.isLoading = false;
  }

  listingCreditPassPurchasesError(...args) {
    this.notifyError('Error listing credit pass purchases', args);

    this.isLoading = false;
  }

  selectProfile(id) {
    if (id !== this.creditPassPackage.client_id) this.changed = true;
    this.creditPassPackage = this.creditPassPackage.set('client_id', id);
  }

  getExistingDataFromCart() {
    this.waitFor(POSCartStore);

    const { cart, cartLoaded } = POSCartStore.getState();

    if (!this.creditPass.id && !cartLoaded) {
      return;
    }

    if (!this.cartChecked) {
      const cartItem =
        cart &&
        cart.order_items &&
        cart.order_items.find(
          oi => oi.getIn(['orderable', 'credit_pass_id']) === this.creditPass.id
        );

      if (cartItem) {
        this.creditPassPackage = cartItem.orderable;
        this.orderItemId = cartItem.id;
        this.setAvailableProfiles();
      }

      this.cartChecked = true;
    }
  }
}

export default alt.createStore(
  POSCreditPassPackageStore,
  'POSCreditPassPackageStore'
);
