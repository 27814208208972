import * as React from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, Typography, Button, Menu } from '@upperhand/playmaker';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import MembershipIcon from 'shared/components/icons/Membership.jsx';

import { t } from 'shared/utils/LocaleUtils';
import altContainer from 'shared/hocs/altContainer.jsx';
import { currentUser, hasAdminPermission } from 'shared/utils/UserUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';
import { customerScopedRoute } from 'shared/utils/RouteUtils';

import MembershipSubscription from 'shared/records/MembershipSubscription.jsx';

import { ClientDataStore, MembershipSubscriptionDataStore } from 'dataStores';

import MembershipChargeDateActions from 'memberships/actions/MembershipChargeDateActions.js';
import MembershipCancellationActions from 'memberships/actions/MembershipCancellationActions';
import MembershipSuspensionActions from 'memberships/actions/MembershipSuspensionActions';
import BalanceListDrawerActions from 'containers/reports/balanceListDrawer/Actions';
import EditMembershipExpireActions from 'containers/clientProfile/components/EditMembershipExpireModal/EditMembershipExpireActions.js';
import EditMembershipCancellationActions from 'containers/clientProfile/components/EditMembershipCancellationModal/Actions.js';
import MembershipEmbedActions from 'containers/clientProfile/components/MembershipEmbed/MembershipEmbedActions';
import MembershipOverviewDrawerActions from 'containers/clientProfile/components/MembershipInfo/OverviewDrawer/Actions';

import UHTheme from '_uh_theme.jsx';
import { BENEFIT_TYPES } from 'shared/records/Membership.jsx';
import SubscriptionStatusText from '../SubscriptionStatusText.jsx';

import './styles.scss';

const useIconStyles = makeStyles({
  iconRoot: {
    fill: ({ color }) => color ?? 'var(--color-primary)',
  },
  membershipContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  indicatorIcon: {
    marginRight: 25,
    position: 'relative',
    cursor: 'pointer',
  },
  chargeDateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  membershipLink: {
    width: '100%',
    paddingRight: '25px',
    '&:hover': {
      color: UHTheme.palette.activeBlue,
      textDecoration: 'none',
    },
  },
});

const getSubscriptionOptions = (client, subscription, intl) => {
  const items = [
    {
      label: t('.history', intl, __filenamespace),
      onClick: () =>
        BalanceListDrawerActions.openDrawer({
          clientId: client.managing_customer_user_id || client.id,
          membershipSubscriberId: client.id,
          membershipIds: [subscription.membership.id],
          balanceRemainingMax: null,
        }),
    },
    {
      label: t('.overview', intl, __filenamespace),
      onClick: () =>
        MembershipOverviewDrawerActions.open({
          clientId: client.id,
          subscription,
        }),
    },
  ];

  const suspendedAt = subscription.suspended_at;
  const isCancelled = subscription.is_cancelled;
  const isExpired = !subscription.isActive();
  const isSuspended = subscription.is_suspended;
  const isLifeTime = subscription.membership.isLifetime();
  const isPreSale = subscription.billing_status === 'pre_sale';
  const isStaff = currentUser().isStaff();
  const isSubscriptionCancelled =
    subscription.get('is_cancelled', false) &&
    moment(subscription.get('expires_at')).isAfter();
  const isSubscriptionCancelledInFuture =
    subscription.get('future_cancelling', false) &&
    moment(subscription.get('commitment_ends_at')).isAfter();
  const allowRemoveCancellation =
    isStaff && (isSubscriptionCancelled || isSubscriptionCancelledInFuture);

  const { features } = currentCustomer();
  const { ap_credits: appCreditsEnabled } = features;
  const { benefit_type: benefitType } = subscription.membership;
  const topUpVisible =
    appCreditsEnabled && benefitType === BENEFIT_TYPES.PLATINUM;

  if (
    !isCancelled &&
    !isExpired &&
    !suspendedAt &&
    !isLifeTime &&
    !isPreSale &&
    isStaff
  )
    items.push({
      label: t('.suspend', intl, __filenamespace),
      onClick: () =>
        MembershipSuspensionActions.suspensionRequested(
          client,
          subscription.membership
        ),
    });

  if (suspendedAt && !isSuspended && isStaff)
    items.push({
      label: t('.remove_suspension', intl, __filenamespace),
      onClick: () =>
        MembershipSuspensionActions.reactivationRequested(
          client,
          subscription.membership,
          true
        ),
    });

  if (isSuspended && isStaff)
    items.push({
      label: t('.reactivate', intl, __filenamespace),
      onClick: () =>
        MembershipSuspensionActions.reactivationRequested(
          client,
          subscription.membership,
          false
        ),
    });

  if (!isCancelled && !isExpired && !isSuspended && isStaff)
    items.push({
      label: t('.cancel', intl, __filenamespace),
      onClick: () =>
        MembershipCancellationActions.cancellationRequested(
          client,
          subscription.membership
        ),
    });

  if (allowRemoveCancellation)
    items.push({
      label: t('.remove_cancellation', intl, __filenamespace),
      onClick: () =>
        EditMembershipCancellationActions.toggleConfirmationModal(
          client,
          subscription
        ),
    });

  if (!isCancelled && !isExpired && !isSuspended && isStaff)
    items.push({
      label: t('.edit', intl, __filenamespace),
      onClick: () => {
        EditMembershipExpireActions.openModal(subscription);
      },
    });

  if (!isCancelled && !isExpired && !isSuspended && isStaff && topUpVisible)
    items.push({
      label: t('.top_up', intl, __filenamespace),
      onClick: () => MembershipEmbedActions.topUp(subscription.id),
    });

  return items;
};

function SubscriptionStatus({
  intl,
  subscriptionStatus,
  membershipSubscription,
}) {
  return (
    <div className="subscription-status">
      <div className="subscription-status-wrapper">
        <Typography className="subscription-status-text">
          <SubscriptionStatusText
            subscriptionStatus={subscriptionStatus}
            membershipSubscription={membershipSubscription}
            intl={intl}
          />
        </Typography>
      </div>
    </div>
  );
}

function MembershipInfo({
  showTitle,
  clientId,
  subscriptionId,
  clientDataStore: { clients },
  membershipSubscriptionDataStore: { membershipSubscriptions },
  intl,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const client = clients.get(clientId);
  const membershipSubscription = membershipSubscriptions.get(
    subscriptionId,
    new MembershipSubscription()
  );
  const { membership, membership_tier: membershipTier } =
    membershipSubscription;
  const classes = useIconStyles({
    color: membershipSubscription?.membership?.color,
  });

  if (!client || !membershipSubscription) return null;

  const subscriptionStatus = membershipSubscription.status;
  const isStaffAdmin = hasAdminPermission();
  const isSubscriptionCancelled =
    membershipSubscription.get('is_cancelled', false) &&
    moment(membershipSubscription.get('expires_at')).isAfter();
  const isSubscriptionCancelledInFuture =
    membershipSubscription.get('future_cancelling', false) &&
    moment(membershipSubscription.get('commitment_ends_at')).isAfter();
  const allowEditCancellationDate =
    isStaffAdmin &&
    (isSubscriptionCancelled || isSubscriptionCancelledInFuture);
  const infoItem = membership.tiered ? membershipTier : membership;
  const paymentStatus = client.has_subscription_balance_owed
    ? 'unpaid'
    : 'paid';

  const toggleExpanded = () => setExpanded(!expanded);

  const toggleMenu = event =>
    setAnchorEl(!anchorEl ? event.currentTarget : null);

  const getCancelationDate = () => {
    const isFutureCancelling =
      membershipSubscription.commitment_ends_at &&
      membershipSubscription.future_cancelling;
    const isCancelled =
      membershipSubscription.commitment_ends_at &&
      membershipSubscription.is_cancelled;

    if (isFutureCancelling) {
      return moment(membershipSubscription.commitment_ends_at).format(
        'MM/DD/YYYY'
      );
    }

    if (isCancelled) {
      return membershipSubscription.futureCancellingDateToShow();
    }

    return t('.n_a', intl, __filenamespace);
  };

  return (
    <>
      {showTitle && (
        <Typography variant="body1">
          {t('.membership_info_title', intl, __filenamespace)}
        </Typography>
      )}
      <div className="membership-info-wrapper">
        <Card
          classes={{
            root: clsx(
              'membership-info',
              expanded && 'membership-info__expanded'
            ),
            header: 'membership-info__header',
            content: clsx(
              'membership-info__content',
              !membership.tiered && 'pt'
            ),
            borderLeft: 'membership-info__border-left',
          }}
          borderPosition="left"
          borderColor={membership.color}
          header={
            <>
              <MembershipIcon color={membership.color} />
              <div className="membership-info__header-wrapper">
                <Link
                  className={classes.membershipLink}
                  to={customerScopedRoute(
                    `/${
                      currentUser().isClient()
                        ? 'client-memberships'
                        : 'memberships'
                    }/${membershipSubscription.membership_id}`
                  )}
                  underline="none"
                >
                  <Typography variant="h6" className="membership-info__name">
                    {membership.name}
                  </Typography>
                </Link>
                {membership.tiered && (
                  <Typography className="membership-info__tier-name">
                    {membershipTier.name}
                  </Typography>
                )}
                <Button
                  rounded
                  type="tertiary"
                  icon="moreVert"
                  onClick={toggleMenu}
                />
                <Menu
                  anchor={anchorEl}
                  items={getSubscriptionOptions(
                    client,
                    membershipSubscription,
                    intl
                  )}
                  onClose={toggleMenu}
                />
              </div>
            </>
          }
          menuOptions={getSubscriptionOptions(
            client,
            membershipSubscription,
            intl
          )}
        >
          <div className="membership-info__content-container">
            <div className="membership-info__status-wrapper">
              <div
                className={`membership-info__payment-status-${paymentStatus}`}
              >
                {paymentStatus}
              </div>
              <div
                className={clsx(
                  'membership-info__subscription-status',
                  `membership-info__subscription-status-${subscriptionStatus}`
                )}
              >
                <SubscriptionStatus
                  intl={intl}
                  subscriptionStatus={subscriptionStatus}
                  membershipSubscription={membershipSubscription}
                />
              </div>
            </div>
            <Button
              rounded
              type="tertiary"
              icon={expanded ? 'arrowUp' : 'arrowDown'}
              onClick={toggleExpanded}
            />
          </div>
        </Card>
        {expanded && (
          <div className="membership-info__benefits-container">
            <Grid container>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography>{t('.charge', intl, __filenamespace)}</Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography variant="subtitle2">
                  <FormattedCurrency value={infoItem.price} fromCents />
                  {infoItem.intervalMonths && (
                    <span>/{infoItem.intervalMonths}</span>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography>
                  {t('.last_payment', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography variant="subtitle2">
                  {membershipSubscription.last_payment_at
                    ? moment(membershipSubscription.last_payment_at).format(
                        'MM/DD/YYYY'
                      )
                    : t('.n_a', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography>
                  {t('.next_payment', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography variant="subtitle2">
                  {membershipSubscription.expires_at
                    ? moment(membershipSubscription.expires_at).format(
                        'MM/DD/YYYY'
                      )
                    : t('.n_a', intl, __filenamespace)}
                </Typography>
                {isStaffAdmin && (
                  <Button
                    type="tertiary"
                    rounded
                    size="1x"
                    fullWidth
                    icon="edit"
                    onClick={() =>
                      MembershipChargeDateActions.toggleShowChargeDate(
                        membershipSubscription.id
                      )
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography>
                  {t('.cancellation_date', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography variant="subtitle2">
                  {getCancelationDate()}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography>
                  {t('.expires_on', intl, __filenamespace)}
                </Typography>
              </Grid>
              <Grid item xs={6} className="membership-info__benefit-column">
                <Typography variant="subtitle2">
                  {membershipSubscription.commitment_ends_at &&
                  moment(membershipSubscription.commitment_ends_at).isAfter()
                    ? moment(membershipSubscription.commitment_ends_at).format(
                        'MM/DD/YYYY'
                      )
                    : t('.no_expires', intl, __filenamespace)}
                </Typography>
                {allowEditCancellationDate && (
                  <Button
                    rounded
                    fullWidth
                    type="tertiary"
                    size="1x"
                    icon="edit"
                    onClick={() =>
                      EditMembershipCancellationActions.toggleModal(
                        membershipSubscription
                      )
                    }
                  />
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

MembershipInfo.propTypes = {
  showTitle: PropTypes.bool,
  clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subscriptionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clientDataStore: PropTypes.object,
  membershipSubscriptionDataStore: PropTypes.object,
  intl: PropTypes.object,
};

MembershipInfo.defaultProps = {
  showTitle: true,
  clientId: null,
  subscriptionId: null,
  clientDataStore: {},
  membershipSubscriptionDataStore: {},
  intl: {},
};

export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
    membershipSubscriptionDataStore: MembershipSubscriptionDataStore,
  },
})(MembershipInfo);
