import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextField } from '@upperhand/playmaker';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';

import debounce from 'lodash.debounce';

import ConfirmationDialog from 'shared/components/ConfirmationDialog.jsx';
import TeamActions from 'team_mgmt/shared/actions/TeamActions.jsx';
import FilterActions from 'team_mgmt/index/actions/FilterActions.jsx';
import FilterButton from 'team_mgmt/index/components/_FilterButton.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { redirectTo } from 'shared/utils/RouteUtils';
import { windowWidth } from 'shared/utils/DOMUtils';
import { Box } from '@mui/material';

const styles = {
  actionMenuButton: {
    marginLeft: '0px',
    color: uhColors.hintActive,
  },

  actionMenuButtonDisabled: {
    color: uhColors.disabledGrey,
  },

  toggle: {
    alignItems: 'center',
    width: 200,
  },

  toolbar: {
    backgroundColor: 'white',
    padding: windowWidth() < 768 ? 0 : 'inherit',
  },
};

class ContentToolbar extends React.Component {
  state = {
    eventTitle: '',
    deleteConfirmationOpen: false,
    anchorEl: null,
  };

  debouncedTeamList = debounce(teamActions => {
    teamActions.list({
      fields: ['team_detail', 'team_type'],
      page: 1,
    });
  }, 600);

  showDeleteConfirmation = () => {
    this.setState({ deleteConfirmationOpen: true });
  };

  hideDeleteConfirmation = () => {
    this.setState({ deleteConfirmationOpen: false });
  };

  handleTextFieldChange = (_e, value) => {
    const { filterActions, teamActions } = this.props;
    this.setState({ eventTitle: value });
    filterActions.updateTitleFilter(value);

    this.debouncedTeamList(teamActions);
  };

  handleActions = (status, callback) => {
    const { allowDeleteTeams, selectedTeams } = this.props;

    const canUpdateStatus = !(status === 'active' && !allowDeleteTeams);

    if (canUpdateStatus) {
      selectedTeams.forEach(event => {
        TeamActions.updateTeamStore([], event.set('status', status));
      });
      TeamActions.updateTeamSelection(selectedTeams, status);
    }
    if (typeof callback === 'function') {
      callback();
    }
  };

  // eslint-disable-next-line class-methods-use-this
  handleClone = id => {
    TeamActions.clone(id);
  };

  hasSelectedEvents = () => {
    const { selectedTeams } = this.props;
    return selectedTeams && selectedTeams.length;
  };

  singleSelectionMenuOptions = () => {
    const { intl, selectedTeams, readOnly } = this.props;

    if (selectedTeams.length === 1) {
      const event = selectedTeams[0];
      const menuItems = [
        <MenuItem
          key="1"
          onClick={() => redirectTo({ path: event.admin_path })}
        >
          {t('.view', intl, __filenamespace)}
        </MenuItem>,
      ];
      if (!readOnly) {
        menuItems.push(
          <>
            {' '}
            <MenuItem
              key="2"
              onClick={() => redirectTo({ path: `${event.path}/edit` })}
            >
              {t('.edit', intl, __filenamespace)}
            </MenuItem>
            <MenuItem key="3" onClick={() => this.handleClone(event.id)}>
              {t('.clone', intl, __filenamespace)}
            </MenuItem>
          </>
        );
      }
      if (menuItems.length) {
        menuItems.push(<Divider key="divider" />);
      }
      return menuItems;
    }
    return [];
  };

  getEventTitle = () => {
    const { selectedTeams } = this.props;

    return selectedTeams.map(event => event.get('title')).join(', ');
  };

  areAllDeleted() {
    const { selectedTeams } = this.props;
    const statuses = selectedTeams.map(event => event.get('status'));
    return statuses.every(status => status === 'deleted');
  }

  render() {
    const { intl, allowDeleteTeams, showActionMenu, filterActions } =
      this.props;
    const { deleteConfirmationOpen, eventTitle, anchorEl } = this.state;
    const disableActionButton =
      !this.hasSelectedEvents() || (this.areAllDeleted() && !allowDeleteTeams);
    const actionIconColor = disableActionButton
      ? uhColors.disabledGrey
      : uhColors.hintActive;
    const disabledStyles = disableActionButton
      ? styles.actionMenuButtonDisabled
      : {};
    const actionStyles = { ...styles.actionMenuButton, ...disabledStyles };

    return (
      <Toolbar sx={styles.toolbar}>
        <Stack sx={{ width: '60%' }}>
          <TextField
            icon="search"
            fullWidth
            outline={false}
            placeholder={t('.search', intl, __filenamespace)}
            onChange={this.handleTextFieldChange}
            value={eventTitle}
          />
        </Stack>

        <Stack
          spacing={0}
          direction="row"
          sx={{
            justifyContent: 'flex-end',
            width: '40%',
          }}
        >
          <ResponsiveElement
            largeScreen={
              <FilterButton
                style={{ marginRight: '10px' }}
                filterActions={filterActions}
                large
                intl={intl}
              />
            }
            smallScreen={
              <FilterButton
                filterActions={filterActions}
                large={false}
                intl={intl}
              />
            }
          />

          {showActionMenu && (
            <>
              <Button
                sx={actionStyles}
                disabled={disableActionButton}
                startIcon={<MoreVertIcon sx={{ color: actionIconColor }} />}
                onClick={e => {
                  this.setState({ anchorEl: e.currentTarget });
                }}
              >
                <ResponsiveElement
                  largeScreen={
                    <Box component="span">
                      <FormattedMessage
                        id={messageId('.action', __filenamespace)}
                      />
                    </Box>
                  }
                  smallScreen={<Box />}
                />
              </Button>
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => {
                  this.setState({ anchorEl: null });
                }}
              >
                {this.singleSelectionMenuOptions()}
                <MenuItem
                  key="4"
                  className="cancel-event-button"
                  onClick={() => this.handleActions('cancelled')}
                >
                  {t('.cancel', intl, __filenamespace)}
                </MenuItem>
                <MenuItem
                  key="5"
                  className="complete-event-button"
                  onClick={() => this.handleActions('completed')}
                >
                  {t('.complete', intl, __filenamespace)}
                </MenuItem>
                {this.areAllDeleted() ? (
                  <MenuItem
                    key="6"
                    className="undelete-event-button"
                    onClick={() => this.handleActions('active')}
                  >
                    {t('.undelete', intl, __filenamespace)}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key="7"
                    className="delete-event-button"
                    onClick={this.showDeleteConfirmation}
                  >
                    {t('.delete', intl, __filenamespace)}
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
          <ConfirmationDialog
            awaitingConfirmation={deleteConfirmationOpen}
            onCancel={this.hideDeleteConfirmation}
            onConfirm={() =>
              this.handleActions('deleted', this.hideDeleteConfirmation)
            }
            title={t('.team_title', intl, __filenamespace, {
              team: this.getEventTitle(),
            })}
          >
            <FormattedMessage
              id={messageId('.delete_message', __filenamespace)}
            />
          </ConfirmationDialog>
        </Stack>
      </Toolbar>
    );
  }
}

ContentToolbar.propTypes = {
  filterActions: PropTypes.object,
  teamActions: PropTypes.object,
  showActionMenu: PropTypes.bool,
  allowDeleteTeams: PropTypes.bool,
  selectedTeams: PropTypes.array,
};

ContentToolbar.defaultProps = {
  filterActions: FilterActions,
  teamActions: TeamActions,
  showActionMenu: true,
  allowDeleteTeams: false,
  selectedTeams: [],
};

export default injectIntl(ContentToolbar);
