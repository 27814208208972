class NotesActions {
  constructor() {
    this.generateActions(
      'mounted',
      'updateNote',
      'updateNoteSuccess',
      'updateNoteError',
      'toggleEditMode',
      'handleFieldChange'
    );
  }
}

export default alt.createActions(NotesActions);
