import { List, Map } from 'immutable';

import { UpperHandStore } from 'dataStores';
import { EventActions } from 'sources';
import EventTranslator from 'event_mgmt/shared/translators/EventTranslator.jsx';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';

class EventDataStore extends UpperHandStore {
  constructor() {
    super();

    this.events = Map();

    this.bindListeners({
      listSuccess: EventActions.listSuccess,
      fetchSuccess: [EventActions.fetchSuccess],
      listError: EventActions.listError,
      listCalendarEvents: CalendarActions.listSuccess,
      fetchError: EventActions.fetchError,
    });
  }

  fetchSuccess(event) {
    this.events = this.events.set(event.id, event);
  }

  fetchError(...args) {
    this.notifyError('error fetching events', args);
  }

  listSuccess({ events }) {
    const fetchedEvents = events.groupBy(e => e.id).map(e => e.first());

    this.events = this.events.merge(fetchedEvents);
  }

  listError(...args) {
    this.notifyError('error listing events', args);
  }

  listCalendarEvents(data) {
    this.listSuccess({
      events: List(data.events.map(e => new EventTranslator(e).toClient())),
    });
  }
}

export default alt.createStore(EventDataStore, 'EventDataStore');
