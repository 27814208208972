import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { Drawer } from '@upperhand/playmaker';

import RosterTransferDrawer from 'containers/events/admin/clients/components/RosterTransferDrawer/index.jsx';
import Footer from 'containers/events/admin/sessionSummaryDrawer/components/Footer.jsx';
import Content from 'containers/events/admin/sessionSummaryDrawer/components/Content.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import { RegistrationDataStore, EventDataStore } from 'dataStores';
import CustomerEvent from 'event_mgmt/shared/records/CustomerEvent.jsx';

import SessionSummaryDrawerStore from 'containers/events/admin/sessionSummaryDrawer/Store.js';
import NewMessageDrawerStore from 'containers/events/admin/clients/components/NewMessageDrawer/Store';
import CheckInStore from 'containers/events/admin/sessionSummaryDrawer/components/checkIn/store.js';
import RosterTransferDrawerStore from 'containers/events/admin/clients/components/RosterTransferDrawer/Store';
import DetailsStore from 'containers/events/admin/sessionSummaryDrawer/components/details/store';
import NotesStore from 'containers/events/admin/sessionSummaryDrawer/components/notes/store';
import EditDateTimeStore from 'containers/events/admin/sessionSummaryDrawer/components/editDateTime/store';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';

import RosterTransferDrawerActions from 'containers/events/admin/clients/components/RosterTransferDrawer/Actions';
import NewMessageDrawerActions from 'containers/events/admin/clients/components/NewMessageDrawer/Actions';
import SessionSummaryDrawerActions from 'containers/events/admin/sessionSummaryDrawer/Actions';
import LocationActions from 'shared/actions/LocationActions.jsx';

import './styles.scss';

// This style rule enforces SSD to appear below the POS
// We can't make POS above directly because it uses PM components
// Default zIndex is set to 1300 for PM components
// Increasing zIndex for POS will cause popovers (like dropdown) to be under the drawer
// So instead of changing all PM components zIndexes within a POS we are lowering zIndex for SSD
// !important is needed because drawers have direct inline styles added by the MUI

const useSSDStyles = makeStyles({ wrapper: { zIndex: '1299 !important' } });

function SessionSummaryDrawer({
  eventId,
  intl,
  isOpen,
  useSSD,
  allowCancel,
  onClose,
  sessionId,
  defaultEventTitle,
  defaultSelectedTab,
  detailsStore: {
    changed: detailsChanged,
    totalRegistrations,
    registrationIds,
  },
  notesStore: { editMode: noteEditMode, dirty: noteEdited },
  editDateTimeStore: { changed: editDateTimeChanged },
  registrationDataStore: { registrations },
  checkInStore: {
    dirty: checkInDirty,
    filteredClientsToCheckIn,
    filteredClientsToCheckOut,
  },
  newMessageDrawerStore: { open: newMessageDrawerOpened },
  onNextSession,
  onPreviousSession,
  sessionSummaryDrawerStore,
  rosterTransferDrawerStore,
  eventStore: { customerEvent },
  eventDataStore: { events },
}) {
  const { selectedTab, isEditMode, isLoading, editMenu } =
    sessionSummaryDrawerStore;
  const { showTransferDrawer } = rosterTransferDrawerStore;
  let event = new CustomerEvent();
  if (customerEvent.id === eventId) {
    event = customerEvent;
  } else {
    event = events.get(
      eventId,
      new CustomerEvent({ title: defaultEventTitle })
    );
  }

  React.useEffect(() => {
    SessionSummaryDrawerActions.mounted.defer({
      sessionId,
      selectedTab: defaultSelectedTab,
    });
    LocationActions.list();
  }, [defaultSelectedTab, sessionId]);

  const isFooter =
    (detailsChanged && selectedTab === 0) ||
    isEditMode ||
    (selectedTab === 1 && checkInDirty) ||
    (selectedTab === 2 && noteEditMode);
  const clientIds = registrationIds
    .toList()
    .map(id => registrations.get(id)?.client_id);
  const classes = useSSDStyles();

  if (newMessageDrawerOpened) {
    return null;
  }

  if (showTransferDrawer) {
    return (
      <RosterTransferDrawer
        rosterTransferDrawerStore={rosterTransferDrawerStore}
        open={showTransferDrawer}
        onChange={RosterTransferDrawerActions.toggleTransferDrawer}
        transferHandler={RosterTransferDrawerActions.handleRosterTransfer}
        drawerDismissed={RosterTransferDrawerActions.toggleTransferDrawer}
      />
    );
  }

  return (
    <Drawer
      open={isOpen}
      classes={useSSD ? classes : {}}
      onClose={onClose}
      title={t('.title', intl, __filenamespace)}
      actions={
        isLoading
          ? []
          : [
              {
                icon: 'message',
                onClick: () =>
                  NewMessageDrawerActions.toggleDrawer({ sessionId }),
              },
              {
                icon: 'threeDots',
                onClick: SessionSummaryDrawerActions.toggleEditMenu,
              },
            ]
      }
      content={
        <Content
          total={totalRegistrations}
          eventId={eventId}
          intl={intl}
          isEditMode={isEditMode}
          isLoading={isLoading}
          selectedTab={selectedTab}
          sessionId={sessionId}
          editMenu={editMenu}
          allowCancel={allowCancel}
          onNextSession={onNextSession}
          onPreviousSession={onPreviousSession}
          event={event}
        />
      }
      footer={
        !isLoading &&
        isFooter && (
          <Footer
            isEditMode={isEditMode}
            noteEditMode={noteEditMode}
            checkInMode={selectedTab === 1 && checkInDirty}
            clientsToCheckInOutCount={clientIds.count(
              v =>
                filteredClientsToCheckIn.includes(v) ||
                filteredClientsToCheckOut.includes(v)
            )}
            detailsChanged={detailsChanged}
            isActiveSubmit={editDateTimeChanged || noteEdited || detailsChanged}
            selectedTab={selectedTab}
            isTeamEvent={event?.isTeamEvent()}
          />
        )
      }
    />
  );
}

SessionSummaryDrawer.propTypes = {
  detailsStore: PropTypes.object,
  editDateTimeStore: PropTypes.object,
  eventId: PropTypes.number,
  defaultEventTitle: PropTypes.string,
  intl: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  useSSD: PropTypes.bool,
  allowCancel: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  sessionId: PropTypes.string,
  sessionSummaryDrawerStore: PropTypes.object,
  defaultSelectedTab: PropTypes.number,
};

SessionSummaryDrawer.defaultProps = {
  detailsStore: {},
  editDateTimeStore: {},
  eventId: null,
  defaultEventTitle: '',
  sessionId: null,
  sessionSummaryDrawerStore: {},
  isOpen: false,
  useSSD: true,
  allowCancel: false,
  defaultSelectedTab: 0,
};

export default compose(
  altContainer({
    stores: {
      detailsStore: DetailsStore,
      editDateTimeStore: EditDateTimeStore,
      sessionSummaryDrawerStore: SessionSummaryDrawerStore,
      checkInStore: CheckInStore,
      registrationDataStore: RegistrationDataStore,
      notesStore: NotesStore,
      rosterTransferDrawerStore: RosterTransferDrawerStore,
      newMessageDrawerStore: NewMessageDrawerStore,
      eventStore: EventStore,
      eventDataStore: EventDataStore,
    },
  }),
  injectIntl
)(SessionSummaryDrawer);
