import { OrderedMap, Set } from 'immutable';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import {
  getAllUrlParameters,
  setUrlParameter,
} from 'shared/utils/UrlParameters.js';
import { getCustomerMoment } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import {
  BrowseEventSource,
  EventTypeSource,
  LocationSource,
  StaffSource,
} from 'sources';

import { StaffDataStore } from 'dataStores';

import { currentCustomer } from 'shared/utils/CustomerUtils';
import BrowseEventsActions from './Actions';

export const FILTER_ALL = 'all';
export const VIEW_MODE = {
  list: 'list',
  week: 'week',
};

class BrowseEventsStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.bindListeners({
      closeFiltersDrawer: BrowseEventsActions.closeFiltersDrawer,
      closeWaitListDrawer: BrowseEventsActions.closeWaitListDrawer,
      dateSelect: BrowseEventsActions.dateSelect,
      eventTypeFilterUpdated: BrowseEventsActions.eventTypeFilterUpdated,
      sportTypeFilterUpdated: BrowseEventsActions.sportTypeFilterUpdated,
      listEventTypeError: BrowseEventsActions.listEventTypeError,
      listEventTypeSuccess: BrowseEventsActions.listEventTypeSuccess,
      listEvents: BrowseEventsActions.listEvents,
      listEventsError: BrowseEventsActions.listEventsError,
      listEventsSuccess: BrowseEventsActions.listEventsSuccess,
      listLocationError: BrowseEventsActions.listLocationError,
      listLocationSuccess: BrowseEventsActions.listLocationSuccess,
      listStaffError: BrowseEventsActions.listStaffError,
      listStaffSuccess: BrowseEventsActions.listStaffSuccess,
      locationFilterUpdated: BrowseEventsActions.locationFilterUpdated,
      mounted: BrowseEventsActions.mounted,
      openFiltersDrawer: BrowseEventsActions.openFiltersDrawer,
      openWaitListDrawer: BrowseEventsActions.openWaitListDrawer,
      staffFilterUpdated: BrowseEventsActions.staffFilterUpdated,
      startDateChange: BrowseEventsActions.startDateChange,
      toggleBrowseViewMode: BrowseEventsActions.toggleBrowseViewMode,
      searchFilterUpdated: BrowseEventsActions.searchFilterUpdated,
      listSportTypeSuccess: BrowseEventsActions.listSportTypeSuccess,
      listSportTypeError: BrowseEventsActions.listSportTypeError,
    });
  }

  reset() {
    this.urlParams = getAllUrlParameters() || Map();
    this.isLoading = false;
    this.isMounted = false;
    this.eventLoading = false;
    this.eventTypes = Set();
    this.sportTypes = [];
    this.sportTypeLoading = false;
    this.eventTypeLoading = false;
    this.locations = Set();
    this.locationLoading = false;
    this.staffLoading = false;
    this.viewMode =
      this.urlParams.get('viewMode') ||
      currentCustomer().browse_events_default_view;
    this.isFiltersDrawerOpen = false;

    this.startDate = getCustomerMoment().startOf('week');
    this.selectedDate = getCustomerMoment();
    this.eventTypeFilter = [];
    this.eventTypeAllSelected = true;
    this.locationFilter = [];
    this.locationAllSelected = true;
    this.staff = Set();
    this.staffFilter = [];
    this.staffAllSelected = true;
    this.sportTypeFilter = [];
    this.sportTypeAllSelected = true;

    this.page = 1;
    this.perPage = 20;
    this.totalCount = 0;

    this.browseEvents = OrderedMap();
    this.search = '';
    this.waitlistDrawerOpen = false;
  }

  mounted() {
    this.reset();

    this.isLoading = true;
    this.isMounted = true;

    this.applyEventTypeFilters();
    this.applyLocationFilters();
    this.applyStaffFilters();
    this.applySportTypeFilters();

    this.listEventTypes();
    this.listStaff();
    this.listLocations();
    this.listSportTypes();
  }

  updateIsLoading() {
    this.isLoading =
      this.eventLoading ||
      this.eventTypeLoading ||
      this.locationLoading ||
      this.staffLoading ||
      this.sportTypeLoading;
  }

  applyEventTypeFilters() {
    const urlEventTypeFilter = this.urlParams.get('eventTypeFilter');

    if (urlEventTypeFilter) {
      this.eventTypeFilter = urlEventTypeFilter.toJS();
      this.eventTypeAllSelected = false;
    } else {
      this.eventTypeFilter = this.eventTypes.toArray();
      this.eventTypeAllSelected = true;
    }
  }

  applySportTypeFilters() {
    const urlSportTypeFilter = this.urlParams.get('sportTypeFilter');

    if (urlSportTypeFilter) {
      this.sportTypeFilter = urlSportTypeFilter.toJS();
      this.sportTypeAllSelected = false;
    } else {
      this.sportTypeFilter = this.sportTypes.map(sportType => sportType.value);
      this.sportTypeAllSelected = true;
    }
  }

  applyLocationFilters() {
    const urlLocationFilter = this.urlParams.get('locationFilter');

    if (urlLocationFilter) {
      this.locationFilter = urlLocationFilter.toJS();
      this.locationAllSelected = false;
    } else {
      this.locationFilter = this.locations.toArray();
      this.locationAllSelected = true;
    }
  }

  applyStaffFilters() {
    const urlStaffFilter = this.urlParams.get('staffFilter');

    if (urlStaffFilter) {
      this.staffFilter = urlStaffFilter.toJS();
      this.staffAllSelected = false;
    } else {
      this.staffFilter = this.staff.toArray();
      this.staffAllSelected = true;
    }
  }

  listEvents() {
    this.isLoading = true;
    this.eventLoading = true;

    const params = {
      page: this.page,
      per_page: this.perPage,
    };

    if (this.search) {
      params.search = this.search;
    }

    if (this.viewMode === VIEW_MODE.week) {
      params.start_time = this.selectedDate.startOf('day').toISOString();
      params.end_time = this.selectedDate.endOf('day').toISOString();
    }

    if (!this.eventTypeAllSelected) {
      params.event_type_ids = this.eventTypeFilter;
    }

    if (!this.locationAllSelected) {
      params.location_ids = this.locationFilter;
    }

    if (!this.staffAllSelected) {
      params.staff_ids = this.staffFilter;
    }

    if (!this.sportTypeAllSelected) {
      params.sport_types = this.sportTypeFilter;
    }
    BrowseEventSource.list({
      type: this.viewMode,
      params,
      success: BrowseEventsActions.listEventsSuccess,
      error: BrowseEventsActions.listEventsError,
    });
  }

  listStaff(page = 1) {
    this.isLoading = true;
    this.staffLoading = true;

    StaffSource.list({
      params: {
        access_revoked: false,
        event_statuses: ['active'],
        page,
        per_page: 50,
      },
      success: BrowseEventsActions.listStaffSuccess,
      error: BrowseEventsActions.listStaffError,
    });
  }

  listLocations(page = 1) {
    this.isLoading = true;
    this.locationLoading = true;

    LocationSource.list({
      params: {
        page,
        per_page: 50,
      },
      success: BrowseEventsActions.listLocationSuccess,
      error: BrowseEventsActions.listLocationError,
    });
  }

  listEventTypes(page = 1) {
    this.isLoading = true;
    this.eventTypeLoading = true;

    EventTypeSource.list({
      params: {
        page,
        per_page: 50,
      },
      success: BrowseEventsActions.listEventTypeSuccess,
      error: BrowseEventsActions.listEventTypeError,
    });
  }

  listEventsSuccess({ page, perPage, totalCount, events }) {
    const eventDate = events.getIn([0, 'startsAt']);

    // Preventing wrong loading after quick clicking on different days
    if (
      this.viewMode === VIEW_MODE.list ||
      !eventDate ||
      eventDate.isSame(this.selectedDate, 'day')
    ) {
      this.eventLoading = false;
      this.page = page + 1;
      this.perPage = perPage;
      this.totalCount = totalCount;

      this.waitFor(StaffDataStore);
      const { staff } = StaffDataStore.getState();
      const browseEvents = events.map(e => {
        const id = `${e.eventId || ''}-${e.sessionId || ''}`;
        const availableStaffIds = e
          .get('staffIds')
          .filter(staffId => staff.get(staffId));
        const event = e.set('staffIds', availableStaffIds);

        return [id, event];
      });

      this.browseEvents = this.browseEvents.merge(OrderedMap(browseEvents));
      this.updateIsLoading();
    }
  }

  listEventsError(...args) {
    this.eventLoading = false;
    this.updateIsLoading();
    this.notifyError('error listing events', args);
  }

  listEventTypeSuccess({ event_types: eventTypes, page, totalCount }) {
    this.eventTypes = this.eventTypes.union(eventTypes.map(e => e.id).toSet());

    if (totalCount > this.eventTypes.count()) {
      this.listEventTypes(page + 1);
      return;
    }

    this.applyEventTypeFilters();

    this.eventTypeLoading = false;
    this.updateIsLoading();
  }

  listEventTypeError() {
    this.eventTypeLoading = false;
    this.updateIsLoading();
  }

  listSportTypes(page = 1) {
    this.isLoading = true;
    this.sportTypeLoading = true;

    BrowseEventSource.listSportType({
      params: {
        page,
        per_page: 50,
      },
      success: BrowseEventsActions.listSportTypeSuccess,
      error: BrowseEventsActions.listSportTypeError,
    });
  }

  listSportTypeSuccess({ sport_types: sportTypes }) {
    this.sportTypes = sportTypes.map(sportType => ({
      label: sportType,
      value: sportType,
    }));
    this.applySportTypeFilters();

    this.sportTypeLoading = false;
    this.updateIsLoading();
  }

  listSportTypeError() {
    this.sportTypeLoading = false;
    this.updateIsLoading();
  }

  listStaffSuccess({ page, staff, totalCount }) {
    this.staff = this.staff.union(staff.map(s => s.id).toSet());

    if (totalCount > this.staff.count()) {
      this.listStaff(page + 1);
      return;
    }

    this.listEvents();
    this.applyStaffFilters();

    this.staffLoading = false;
    this.updateIsLoading();
  }

  listStaffError() {
    this.staffLoading = false;
    this.updateIsLoading();
  }

  listLocationSuccess({ locations, page, totalCount }) {
    this.locations = this.locations.union(locations.map(l => l.id).toSet());

    if (totalCount > this.locations.count()) {
      this.listLocations(page + 1);
      return;
    }

    this.locationLoading = false;
    this.applyLocationFilters();
    this.updateIsLoading();
  }

  listLocationError() {
    this.locationLoading = false;
    this.updateIsLoading();
  }

  filterUpdated(filterName, newFilter, allSelected) {
    this[`${filterName}Filter`] = newFilter;
    this[`${filterName}AllSelected`] = allSelected;
    setUrlParameter(`${filterName}Filter`, allSelected ? [] : newFilter);
    this.page = 1;
    this.totalCount = 0;
    this.browseEvents = OrderedMap();

    if (
      (this.staffFilter.length || !this.staff.count()) &&
      (this.eventTypeFilter.length || !this.eventTypes.count()) &&
      (this.sportTypeFilter.length || !this.sportTypes.length) &&
      (this.locationFilter.length || !this.locations.count())
    ) {
      this.listEvents();
    }
  }

  searchFilterUpdated(value) {
    this.search = value;
    this.page = 1;
    this.totalCount = 0;
    this.browseEvents = OrderedMap();
    this.listEvents();
  }

  eventTypeFilterUpdated([newEventTypeFilter, allEventTypeSelected]) {
    this.filterUpdated('eventType', newEventTypeFilter, allEventTypeSelected);
  }

  locationFilterUpdated([newLocationFilter, allLocationSelected]) {
    this.filterUpdated('location', newLocationFilter, allLocationSelected);
  }

  staffFilterUpdated([newStaffFilter, allStaffSelected]) {
    this.filterUpdated('staff', newStaffFilter, allStaffSelected);
  }

  sportTypeFilterUpdated([newSportTypeFilter, allSportTypeSelected]) {
    this.filterUpdated('sportType', newSportTypeFilter, allSportTypeSelected);
  }

  toggleBrowseViewMode([e]) {
    this.viewMode = e.target.value;
    this.page = 1;
    this.totalCount = 0;
    this.browseEvents = OrderedMap();
    setUrlParameter('viewMode', this.viewMode, window.location.pathname);

    this.listEvents();
  }

  closeFiltersDrawer() {
    this.isFiltersDrawerOpen = false;
  }

  openFiltersDrawer() {
    this.isFiltersDrawerOpen = true;
  }

  openWaitListDrawer() {
    this.waitlistDrawerOpen = true;
  }

  closeWaitListDrawer() {
    this.waitlistDrawerOpen = false;
  }

  startDateChange([_e, { startDate }]) {
    this.startDate = getCustomerMoment(startDate);
    this.selectedDate = getCustomerMoment(startDate).day(
      this.selectedDate.isoWeekday()
    );

    this.page = 1;
    this.totalCount = 0;
    this.browseEvents = OrderedMap();
    this.listEvents();
  }

  dateSelect([_e, { selectedDate }]) {
    this.selectedDate = getCustomerMoment(selectedDate);

    this.page = 1;
    this.totalCount = 0;
    this.browseEvents = OrderedMap();
    this.listEvents();
  }
}

export default alt.createStore(BrowseEventsStore, 'BrowseEventsStore');
