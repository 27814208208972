import * as React from 'react';

import { FlexBoxJustify } from 'shared/components/FlexBox.jsx';

import {
  CardHolderField,
  CardNumberField,
  CvcField,
  ExpiryMonthYearField,
  ZipCodeField,
} from '../fields';

function StripeCardForm({ card, onChange, style }) {
  return (
    <div style={style}>
      <CardNumberField
        errorText={card.errors.getErrors('number')}
        onCardNumberChange={onChange}
        value={card.number}
      />
      <CardHolderField
        errorText={card.errors.getErrors('name')}
        onChangeCardHolder={onChange}
        value={card.name}
      />
      <FlexBoxJustify style={{ flexWrap: 'wrap', margin: '0 -5px' }}>
        <div style={{ flex: '1 0 5em', margin: '0 5px' }}>
          <ExpiryMonthYearField
            errorText={card.errors.getErrors('exp')}
            onChange={onChange}
            value={card.exp}
          />
        </div>
        <div style={{ flex: '0 0 5em', margin: '0 5px' }}>
          <CvcField
            errorText={card.errors.getErrors('cvc')}
            onChangeCvc={onChange}
            value={card.cvc}
          />
        </div>
        <div style={{ flex: '1 0 5em', margin: '0 5px' }}>
          <ZipCodeField
            errorText={card.errors.getErrors('address_zip')}
            onChangeZipCode={onChange}
            value={card.address_zip}
          />
        </div>
      </FlexBoxJustify>
    </div>
  );
}

export default StripeCardForm;
