import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ContentModal, Dropdown } from '@upperhand/playmaker';

import {
  NewCardForm,
  PaymentACHForm,
} from 'containers/payments/components/forms';
import { injectIntl } from 'react-intl';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';
import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';

import { PaymentMethod } from 'containers/payments/types';

import { PaymentCard, PaymentAch } from 'containers/payments/records';

import PaymentMethodModalActions from './Actions';
import PaymentMethodModalStore from './Store';

import './styles.scss';

function PaymentMethodModal({
  intl,
  onSuccess,
  paymentMethodModalStore: { isSaving, newPaymentMethod, isOpen },
  userId,
}) {
  useEffect(() => {
    PaymentMethodModalActions.mounted.defer(userId, onSuccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentModal
      classes={{
        root: 'client-settings-payment-modal',
        content: 'client-settings-payment-modal__wrapper',
        title: 'client-settings-payment-modal__title',
        closeButton: 'client-settings-payment-modal__close-button',
      }}
      open={isOpen}
      showActions={false}
      title=""
      onSuccess={PaymentMethodModalActions.closeModal}
      onAbort={PaymentMethodModalActions.closeModal}
      size="1x"
    >
      <div className="client-settings-payment-modal__content">
        <SpinWhileLoading
          isLoading={isSaving}
          spinnerContainerStyle={{ marginBottom: '3em' }}
        >
          {enabledCustomerFeatures(['ach']) && (
            <Dropdown
              fullWidth
              label={t('.add_payment_method', intl, __filenamespace)}
              items={[
                {
                  label: t('.ach', intl, __filenamespace),
                  value: PaymentMethod.ACH,
                },
                {
                  label: t('.card', intl, __filenamespace),
                  value: PaymentMethod.CARD_NEW,
                },
              ]}
              value={
                newPaymentMethod instanceof PaymentAch
                  ? PaymentMethod.ACH
                  : PaymentMethod.CARD_NEW
              }
              onChange={e =>
                PaymentMethodModalActions.changePaymentMethodType(
                  e.target.value
                )
              }
            />
          )}

          {newPaymentMethod instanceof PaymentCard && (
            <div style={{ paddingTop: 15 }}>
              <NewCardForm
                cardRecord={newPaymentMethod}
                onChange={PaymentMethodModalActions.updatePaymentMethodRecord}
              />
            </div>
          )}
          {newPaymentMethod instanceof PaymentAch && (
            <PaymentACHForm
              ach={newPaymentMethod}
              currentPaymentMethod={{ id: null }}
              onChange={(name, value) =>
                PaymentMethodModalActions.updatePaymentMethodRecord([
                  name,
                  value,
                ])
              }
            />
          )}
        </SpinWhileLoading>
      </div>
      {!isSaving && (
        <Button
          classes={{
            root: 'client-settings-payment-modal__btn',
            label: 'client-settings-payment-modal__btn-label',
          }}
          type="primary"
          onClick={PaymentMethodModalActions.createPaymentMethod}
        >
          {t('.btn', intl, __filenamespace)}
        </Button>
      )}
    </ContentModal>
  );
}

PaymentMethodModal.propTypes = {
  intl: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  paymentMethodModalStore: PropTypes.shape({
    newPaymentMethod: PropTypes.oneOfType([
      PropTypes.instanceOf(PaymentCard),
      PropTypes.instanceOf(PaymentAch),
    ]),
    isSaving: PropTypes.bool,
    isOpen: PropTypes.bool,
  }),
  userId: PropTypes.number.isRequired,
};

PaymentMethodModal.defaultProps = {
  onSuccess: () => {},
  paymentMethodModalStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      paymentMethodModalStore: PaymentMethodModalStore,
    },
  })
)(PaymentMethodModal);
