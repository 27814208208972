import * as React from 'react';
import { Button, Grid, Icon, Spinner, TextField } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { Box } from '@mui/material';

import ClientCard from 'containers/contacts/contactsClients/components/ClientCard.jsx';
import UpdateContact from 'containers/contacts/updateContact/UpdateContact.jsx';
import ClientProfileDrawer from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/ClientProfileDrawer.jsx';
import ActiveFilters from 'containers/contacts/contactsClients/components/ActiveFilters.jsx';
import Paginator from 'shared/components/Paginator.jsx';
import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import ContactsFiltersDrawer from 'containers/contacts/contactsFilters/ContactsFiltersDrawer.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import ContactsClientsActions from 'containers/contacts/contactsClients/Actions';
import ClientProfileDrawerActions from 'containers/clientProfile/components/Drawers/ClientProfileDrawer/Actions';

import InviteDialogActions from 'shared/actions/InviteDialogActions.jsx';
import ContactsFiltersDrawerActions from 'containers/contacts/contactsFilters/Actions';

import { ClientDataStore } from 'dataStores';
import ContactsClientsStore from './Store';

import '../styles.scss';

const onSearchChange = (_event, value) =>
  ContactsClientsActions.searchUpdated(value);

function CommonSection({ children }) {
  return (
    <div style={{ paddingTop: 16 }}>
      <Grid container spacing={1} alignItems="center">
        {children}
      </Grid>
    </div>
  );
}

function ContactsClientsDesktop({
  clientDataStore: { clients },
  contactsClientsStore: {
    clientIds,
    page,
    perPage,
    totalCount,
    search,
    loading,
    updateContactId,
    appliedFilters,
  },
  intl,
}) {
  React.useEffect(() => {
    ContactsClientsActions.mounted();
  }, [updateContactId]);

  // after we have set and rendered the edit model we need to clear our state so store controls open state.
  if (updateContactId) {
    ContactsClientsActions.updateContact(null);
  }

  return (
    <CommonSection>
      <Grid container spacing={1} alignItems="center">
        <div className="contacts__add-button">
          <Button
            onClick={InviteDialogActions.openInviteDialog}
            rounded
            icon={<Icon name="add" className="contacts__add-button-icon" />}
            size="2x"
          />
        </div>
        <Grid item xs>
          <TextField
            fullWidth
            rounded
            outline={false}
            icon="search"
            placeholder="Search"
            value={search}
            onChange={onSearchChange}
          />
        </Grid>

        <Grid item>
          <Button
            classes={{
              root: 'contacts__buttons-wrapper',
              label: 'contacts__header-button-label',
            }}
            icon="filter"
            iconPosition="right"
            labelAlign="space-between"
            type="tertiary"
            rounded
            onClick={() => {
              ContactsFiltersDrawerActions.openDrawer();
            }}
          >
            {t('.btn_filter', intl, __filenamespace)}
          </Button>
        </Grid>
        <Grid
          container
          spacing={1}
          alignItems="center"
          className="contacts__active-filters-list"
        >
          <Grid item />
          <Grid item>
            <ActiveFilters appliedFilters={appliedFilters} />
          </Grid>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', marginTop: 100 }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item container spacing={1} className="contacts__clients-list">
              {clientIds.map(id => (
                <Grid item xs={12} key={id}>
                  <ClientCard client={clients.get(id)} />
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Paginator
                className="contacts__pagination"
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={ContactsClientsActions.pageSelected}
                pageLimit={0}
                showInfo
              />
            </Grid>
          </>
        )}
      </Grid>
      <ContactsFiltersDrawer appliedFilters={appliedFilters} />
      <UpdateContact contactId={updateContactId} />
    </CommonSection>
  );
}
function ContactsClientsMobile({
  clientDataStore: { clients },
  contactsClientsStore: {
    clientIds,
    page,
    perPage,
    totalCount,
    search,
    loading,
    updateContactId,
    appliedFilters,
  },
  intl,
}) {
  React.useEffect(() => {
    ContactsClientsActions.mounted();
  }, [updateContactId]);

  // after we have set and rendered the edit model we need to clear our state so store controls open state.
  if (updateContactId) {
    ContactsClientsActions.updateContact(null);
  }
  return (
    <CommonSection>
      <Grid container spacing={1} alignItems="center">
        <Box className="contacts__nav-header-mobile">
          <div className="contacts__add-button">
            <Button
              onClick={InviteDialogActions.openInviteDialog}
              rounded
              icon={<Icon name="add" className="contacts__add-button-icon" />}
              size="2x"
            />
          </div>
          <TextField
            fullWidth
            rounded
            outline={false}
            icon="search"
            placeholder="Search"
            value={search}
            onChange={onSearchChange}
          />
        </Box>
        <Grid item xs={12}>
          <Button
            classes={{
              root: 'contacts__filter-button',
              label: 'contacts__header-button-label',
            }}
            fullWidth
            icon="filter"
            iconPosition="right"
            labelAlign="space-between"
            type="tertiary"
            rounded
            onClick={() => {
              ContactsFiltersDrawerActions.openDrawer();
            }}
          >
            {appliedFilters.name
              ? t('.btn_filter_count', intl, __filenamespace, {
                  count: appliedFilters.name,
                })
              : t('.btn_filter', intl, __filenamespace)}
          </Button>
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <div style={{ textAlign: 'center', marginTop: 100 }}>
              <Spinner type="indeterminate" />
            </div>
          </Grid>
        ) : (
          <>
            <Grid item container spacing={1} className="contacts__clients-list">
              {clientIds.map(id => (
                <Grid item xs={12} key={id}>
                  <ClientCard client={clients.get(id)} />
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Paginator
                className="contacts__pagination"
                currentPage={page}
                perPage={perPage}
                totalCount={totalCount}
                onPageSelect={ContactsClientsActions.pageSelected}
                pageLimit={0}
                showInfo
              />
            </Grid>
          </>
        )}
      </Grid>

      <UpdateContact contactId={updateContactId} />
      <ClientProfileDrawer
        onSessionSummaryOpen={() => {
          ClientProfileDrawerActions.closeScheduleDrawer();
          ClientProfileDrawerActions.closeDrawer();
        }}
      />
      <ContactsFiltersDrawer appliedFilters={appliedFilters} />
    </CommonSection>
  );
}

function ContactsClients(props) {
  return (
    <ResponsiveElement
      // eslint-disable-next-line react/jsx-props-no-spreading
      largeScreen={<ContactsClientsDesktop {...props} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      smallScreen={<ContactsClientsMobile {...props} />}
    />
  );
}
export default altContainer({
  stores: {
    clientDataStore: ClientDataStore,
    contactsClientsStore: ContactsClientsStore,
  },
})(injectIntl(ContactsClients));
