import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Button, Typography, Card, Menu } from '@upperhand/playmaker';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { t } from 'shared/utils/LocaleUtils.js';
import PaymentCard from 'containers/payments/records/PaymentCard';
import PaymentAch from 'containers/payments/records/PaymentAch';
import PaymentMethodTypes from 'containers/payments/types/PaymentMethod';

import CardIcon from 'containers/client/settings/billing/components/CardIcon.jsx';

import ClientBillingSettingsActions from '../Actions';

function PaymentMethodInfo({
  paymentMethod,
  isDefault,
  intl,
  paymentMethodId,
  paymentMethodType,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isCardMethod = paymentMethodType === PaymentMethodTypes.CARD;

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Card
        classes={{
          root: 'card-info',
          content: 'card-info__wrapper',
        }}
      >
        <div className="card-info__title">
          <Typography variant="h5" className="card-info__title-text">
            {t(isCardMethod ? '.credit_card' : '.bank', intl, __filenamespace)}
          </Typography>
          {isDefault && (
            <div className="card-info__default-label">
              {t('.label_default', intl, __filenamespace)}
            </div>
          )}
          <Button
            classes={{ root: 'card-info__menu' }}
            type="tertiary"
            icon="moreVert"
            rounded
            onClick={handleMenuOpen}
          />
        </div>
        <div className="card-info__content">
          <div className="card-info__number">
            {isCardMethod ? (
              <>
                <span>{paymentMethod.last4.padStart(19, '•••• ')}</span>
                {paymentMethod.brand && (
                  <CardIcon brand={paymentMethod.brand} />
                )}
              </>
            ) : (
              <>
                <span>{paymentMethod.last2.padStart(12, '••• ')}</span>
                <AccountBalanceIcon />
              </>
            )}
          </div>
          <div className="card-info__info">
            <span className="card-info__cardholder">{paymentMethod.name}</span>
            {isCardMethod && (
              <span className="card-info__expiration">
                {moment(
                  `${paymentMethod.expMonth}-${paymentMethod.expYear}`,
                  'M-YYYY'
                ).format('MM/YYYY')}
              </span>
            )}
          </div>
        </div>
      </Card>
      <Menu
        anchor={anchorEl}
        onClose={handleMenuClose}
        items={[
          ...(isDefault
            ? []
            : [
                {
                  label: t('.menu.set_default', intl, __filenamespace),
                  onClick: () =>
                    ClientBillingSettingsActions.setDefault(paymentMethodId),
                },
              ]),
          {
            label: t('.menu.delete', intl, __filenamespace),
            onClick: () =>
              ClientBillingSettingsActions.deletePaymentMethod(paymentMethodId),
          },
        ]}
      />
    </>
  );
}

PaymentMethodInfo.propTypes = {
  paymentMethod: PropTypes.oneOfType([
    PropTypes.instanceOf(PaymentCard),
    PropTypes.instanceOf(PaymentAch),
  ]),
  intl: PropTypes.object.isRequired,
  isDefault: PropTypes.bool,
  paymentMethodId: PropTypes.string.isRequired,
};

PaymentMethodInfo.defaultProps = {
  paymentMethod: new PaymentCard(),
  isDefault: false,
};

export default React.memo(PaymentMethodInfo);
