import * as React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { TextField, Button } from '@upperhand/playmaker';

import altContainer from 'shared/hocs/altContainer.jsx';
import { compose } from 'shared/utils/SharedUtils.js';
import { t } from 'shared/utils/LocaleUtils.js';

import NotesActions from './actions';
import NotesStore from './store';

import './styles.scss';

function Notes({ intl, sessionId, notesStore }) {
  React.useEffect(() => {
    NotesActions.mounted.defer(sessionId);
  }, [sessionId]);

  const { sessionNote, editMode } = notesStore;
  const showAddBtn = !sessionNote && !editMode;

  return (
    <div className="session-note__container">
      {!editMode && (
        <Button
          rounded
          icon={showAddBtn ? 'add' : 'edit'}
          iconPosition="left"
          type="tertiary"
          size="1x"
          classes={{ root: 'action-btn' }}
          onClick={NotesActions.toggleEditMode}
        >
          {showAddBtn
            ? t('.add_btn', intl, __filenamespace)
            : t('.edit_btn', intl, __filenamespace)}
        </Button>
      )}
      {editMode && (
        <TextField
          fullWidth
          multiline
          value={sessionNote}
          onChange={(_e, value) =>
            NotesActions.handleFieldChange({ field: 'sessionNote', value })
          }
        />
      )}
      {sessionNote && !editMode && <div>{sessionNote}</div>}
    </div>
  );
}

Notes.propTypes = {
  intl: PropTypes.object,
  sessionId: PropTypes.string,
  notesStore: PropTypes.object,
};

Notes.defaultProps = {
  intl: {},
  sessionId: '',
  notesStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: { notesStore: NotesStore },
  })
)(Notes);
