/* eslint-disable camelcase */

import { Map, Set, Record, Iterable } from 'immutable';
import moment from 'moment-timezone';

import MagicSession from 'event_mgmt/shared/records/MagicSession';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

export const SESSION_STATUSES = {
  standard: 'standard',
  non_conforming: 'non_conforming',
  cancelled: 'cancelled',
  conflicting: 'conflicting',
};

class Session extends Record({
  id: null,
  starts_at: null,
  ends_at: null,
  client_ids: Set(),
  staff_ids: Set(),
  schedule_id: null,
  event_id: null,
  resource_ids: Set(),
  registration_ids: Set(),
  attendance_map: Map(),
  spots_remaining: null,
  status: null,
  note: null,
  created_at: null,
  updated_at: null,
  scheduling_deadline: null,
  cancellation_deadline: null,
  max_size: 999,
}) {
  constructor(_obj = {}) {
    let obj = _obj;
    if (Iterable.isIterable(_obj)) {
      obj = _obj.toJS();
    }

    const client_ids = Set(obj.client_ids || []);
    const staff_ids = Set(obj.staff_ids || []);
    const resource_ids = Set(obj.resource_ids || []);
    const registration_ids = Set(obj.registration_ids || []);
    const attendance_map = Map({
      booked: Set(obj.attendance_map ? obj.attendance_map.booked : []),
      checked_in: Set(obj.attendance_map ? obj.attendance_map.checked_in : []),
      no_show: Set(obj.attendance_map ? obj.attendance_map.no_show : []),
    });

    const starts_at = obj.starts_at
      ? moment.tz(obj.starts_at, customerTZ())
      : null;
    const ends_at = obj.ends_at ? moment.tz(obj.ends_at, customerTZ()) : null;
    const created_at = obj.created_at
      ? moment.tz(obj.created_at, customerTZ())
      : null;
    const updated_at = obj.updated_at
      ? moment.tz(obj.updated_at, customerTZ())
      : null;

    super({
      ...obj,
      attendance_map,
      starts_at,
      ends_at,
      created_at,
      updated_at,
      client_ids,
      staff_ids,
      resource_ids,
      registration_ids,
    });
  }

  duration() {
    if (this.ends_at == null) {
      return null;
    }
    return moment.duration(this.ends_at.diff(this.starts_at));
  }

  isCancelled() {
    return this.status === SESSION_STATUSES.cancelled;
  }

  isConflicting() {
    return this.status === SESSION_STATUSES.conflicting;
  }

  hasNoStaff() {
    return this.staff_ids.isEmpty();
  }

  toMagicSession() {
    return new MagicSession({
      startDateTime: this.starts_at,
      endDateTime: this.ends_at,
    });
  }

  formattedTime() {
    return `${this.starts_at?.format('ddd, MMM D')} | ${this.starts_at?.format(
      'h:mma'
    )} - ${this.ends_at.format('h:mma z')}`;
  }

  classGracePeriodReached() {
    return this.starts_at?.isBefore(moment().subtract(7, 'days'));
  }
}

export default Session;
