import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';

import TeamPrice from 'shared/components/TeamPrice.jsx';
import TeamLink from 'team_mgmt/shared/components/_TeamLink.jsx';
import Label from 'shared/components/Label.jsx';
import { messageId } from 'shared/utils/LocaleUtils.js';
import { snakeToTitleCase } from 'shared/utils/FormattingUtils.jsx';
import { Box } from '@mui/material';

const styles = {
  rowTopText: {
    margin: '0px',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: '3px',
  },

  rowBottomText: {
    margin: '0px',
    textTransform: 'capitalize',
    paddingTop: '6px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  teamTitleTypeColorIndicator: {
    borderRadius: '2px',
    border: '2px',
    width: 4,
    marginRight: '0.5em',
    display: 'inline-block',
    position: 'absolute',
    bottom: '0.5em',
    height: '70%',
  },

  privacyColumn: {
    width: '8em',
  },
};

function ContentTable(props) {
  const {
    teams,
    allowDeleteTeams,
    automations,
    selectedTeams,
    setSelectedTeams,
  } = props;
  const { descriptions } = automations;
  const isAllSelected = teams.size === selectedTeams.size;
  const handleRowSelection = (index, teamId) => {
    if (index === 'all') {
      setSelectedTeams(teams);
    } else if (index === 'none') {
      setSelectedTeams([]);
    } else {
      const isSelected = selectedTeams.find(team => team.id === teamId);

      if (isSelected) {
        const filteredTeams = selectedTeams.filter(team => team.id !== teamId);
        setSelectedTeams(filteredTeams);
      } else {
        setSelectedTeams([...selectedTeams, teams.get(index)]);
      }
    }
  };

  const isRowSelected = team =>
    !!selectedTeams.find(selectedTeam => selectedTeam.id === team.id);

  return (
    <TableContainer>
      <Table size="small" style={{ backgroundColor: 'var(--color-white)' }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isAllSelected}
                onClick={() =>
                  handleRowSelection(isAllSelected ? 'none' : 'all')
                }
              />
            </TableCell>
            <TableCell className="teams__header">
              <FormattedMessage id={messageId('.name', __filenamespace)} />
            </TableCell>
            <TableCell className="teams__header">
              <FormattedMessage id={messageId('.status', __filenamespace)} />
            </TableCell>
            <TableCell className="teams__header">
              <FormattedMessage
                id={messageId('.roster_size', __filenamespace)}
              />
            </TableCell>
            <TableCell className="teams__header">
              <FormattedMessage id={messageId('.price', __filenamespace)} />
            </TableCell>
            <TableCell className="teams__privacy-column-header" />
          </TableRow>
        </TableHead>
        <TableBody>
          {teams.map((team, index) => (
            <TableRow
              key={team.id}
              onClick={() => handleRowSelection(index, team.id)}
            >
              <TableCell padding="checkbox">
                <Checkbox color="primary" checked={isRowSelected(team)} />
              </TableCell>
              <TableCell style={{ position: 'relative' }}>
                <Box
                  sx={{
                    ...styles.teamTitleTypeColorIndicator,
                    borderLeft: team?.team_type
                      ? `4px solid ${team.team_type?.color}`
                      : '4px solid white',
                  }}
                />
                <Box
                  component="p"
                  sx={{ ...styles.rowTopText, marginLeft: '.8rem' }}
                >
                  <TeamLink team={team} showDeletedTeams={allowDeleteTeams} />
                </Box>
                <Box
                  component="p"
                  style={{ ...styles.rowBottomText, marginLeft: '.8rem' }}
                >
                  {team.team_type ? team.team_type.name : '&ensp;'}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="p" sx={styles.rowTopText}>
                  {snakeToTitleCase(team.get('status'))}
                </Box>
              </TableCell>
              <TableCell>
                <Box component="p" sx={styles.rowTopText}>
                  {team.getIn(['team_detail', 'roster_size']) || '0'}
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={styles.rowTopText}>
                  <TeamPrice team={team} automations={descriptions} />
                </Box>
              </TableCell>
              <TableCell>
                {team.privacy && (
                  <Label style={{ textTransform: 'uppercase' }}>
                    <FormattedMessage
                      id={messageId('.private', __filenamespace)}
                    />
                  </Label>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

ContentTable.propTypes = {
  allowDeleteTeams: PropTypes.bool,
  teams: PropTypes.instanceOf(List),
  automations: PropTypes.object,
  selectedTeams: PropTypes.array,
};

ContentTable.defaultProps = {
  allowDeleteTeams: false,
  teams: List(),
  automations: {
    descriptions: List(),
  },
  selectedTeams: [],
};

export default ContentTable;
