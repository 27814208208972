import PropTypes from 'prop-types';
import * as React from 'react';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FlexBox } from 'shared/components/FlexBox.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

const styles = {
  labelContainer: {
    cursor: 'pointer',
    height: 56,
    fontSize: 18,
    alignItems: 'center',
    color: uhColors.blue,
    justifyContent: 'space-between',
  },
  separator: {
    flexGrow: 1,
    margin: '',
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 0,
  },
};

class Expander extends React.Component {
  constructor(props) {
    super(props);
    const { startsExpanded } = this.props;

    this.state = { expanded: startsExpanded };
  }

  getIconStyle() {
    const { expanded } = this.state;

    return expanded ? { transform: 'rotate(-180deg)' } : {};
  }

  toggleExpanded = () => {
    const { expanded } = this.state;

    this.setState({ expanded: !expanded });
  };

  isIconAtLeft = () => {
    const { iconPosition } = this.props;

    return iconPosition === 'left';
  };

  render() {
    const {
      label,
      showDivider,
      style,
      labelContainerStyle,
      contentContainerStyle,
      children,
    } = this.props;
    const { expanded } = this.state;

    return (
      <div style={style}>
        <FlexBox
          style={merge(styles.labelContainer, labelContainerStyle)}
          onClick={this.toggleExpanded}
        >
          {this.isIconAtLeft() && (
            <ExpandLessIcon
              sx={{ color: uhColors.blue }}
              style={this.getIconStyle()}
            />
          )}
          <div>{label}</div>
          {!this.isIconAtLeft() && (
            <ExpandLessIcon
              sx={{ color: uhColors.blue }}
              style={this.getIconStyle()}
            />
          )}
          {showDivider && <Divider style={styles.separator} />}
        </FlexBox>

        {expanded && <div style={contentContainerStyle}>{children}</div>}
      </div>
    );
  }
}

Expander.propTypes = {
  startsExpanded: PropTypes.bool,
  iconPosition: PropTypes.string,
  /* TODO more props types need to be defined */
};

Expander.defaultProps = {
  startsExpanded: false,
  iconPosition: 'right',
  /* TODO more default Props need to be listed here */
};
export default Expander;
