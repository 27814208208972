class ClassesListActions {
  constructor() {
    this.generateActions(
      'mounted',
      'reset',
      'selectClass',
      'switchMode',
      'listClasses',
      'listClassesSuccess',
      'listClassesError',
      'listSessions',
      'listSessionsSuccess',
      'listSessionsError',
      'sessionsPageChange',
      'schedulePageChange',
      'listSchedules',
      'listSchedulesSuccess',
      'listSchedulesError',
      'listStaff',
      'listStaffSuccess',
      'listStaffError',
      'listResources',
      'listResourcesSuccess',
      'listResourcesError',
      'listLocations',
      'listLocationsSuccess',
      'listLocationsError',
      'toggleSessionSummaryDrawer',
      'searchSessions',
      'statusFilterChange',
      'staffFilterChange',
      'resourceFilterChange',
      'locationFilterChange',
      'fetchEvent',
      'fetchEventSuccess',
      'fetchEventError',
      'handleTabChange',
      'listClassRoster',
      'listClassRosterSuccess',
      'listClassRosterError',
      'rosterPageChange',
      'searchRoster',
      'staffPageChange',
      'searchStaff',
      'searchClasses'
    );
  }
}

export default alt.createActions(ClassesListActions);
