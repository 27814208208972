import * as React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Spinner, Typography } from '@upperhand/playmaker';
import { injectIntl } from 'react-intl';
import { Set } from 'immutable';

import { t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';

import { StaffDataStore } from 'dataStores';

import SessionSchedulingActions from './Actions';
import SessionGrouping from './components/SessionGrouping.jsx';
import SessionSchedulingStore from './Store';
import StaffFilter from './components/StaffFilter.jsx';
import DayOfWeekFilter from './components/DayOfWeekFilter.jsx';
import LocationFilter from './components/LocationFilter.jsx';

import './styles.scss';

const styles = {
  loadingContainer: {
    textAlign: 'center',
  },

  button: {
    height: 56,
  },

  footer: {
    borderTop: `1px solid ${uhColors.lighterGrey}`,
    width: '100%',
    padding: '18px 0 0 0',
  },
};

function SessionScheduling(props) {
  const {
    clientId,
    eventId,
    allowPast,
    intl,
    onNext,
    onBack,
    selectedSessionIds: mountedSelectedSessionIds,
    sessionSchedulingStore: {
      bookingProcessing,
      creditCountsLoading,
      creditsAvailable,
      sessionIds,
      staffIds,
      selectedSessionIds,
      sessionsLoading,
      staffLoading,
      staffFilter,
      sessionsHasMore,
      selectedDayOfWeek,
      selectedLocations,
      locationIds,
    },
  } = props;

  React.useEffect(() => {
    SessionSchedulingActions.mounted.defer({
      allowPast,
      clientId,
      eventId,
      onNext,
      selectedSessionIds: mountedSelectedSessionIds,
    });

    return () => {
      SessionSchedulingActions.unmount.defer();
    };
  }, [clientId, eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  const loading = staffLoading || creditCountsLoading || !!bookingProcessing;

  React.useEffect(() => {
    const scrollContainer = document.querySelector(
      '.scheduling-sessions__list'
    );

    const handleLoadMore = () => {
      if (!scrollContainer) return;

      const atBottom =
        scrollContainer.scrollTop + scrollContainer.clientHeight >=
        scrollContainer.scrollHeight;

      if (atBottom && sessionsHasMore && !sessionsLoading) {
        SessionSchedulingActions.sessionsLoadMore();
      }
    };

    if (!loading) {
      scrollContainer?.addEventListener('scroll', handleLoadMore);
    }

    return () => scrollContainer?.removeEventListener('scroll', handleLoadMore);
  }, [loading, sessionsHasMore, sessionsLoading]);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        {!!bookingProcessing && (
          <Typography variant="h6">
            {t('.processing', intl, __filenamespace, {
              countProcessed: selectedSessionIds.size - bookingProcessing + 1,
              countAll: selectedSessionIds.size,
            })}
          </Typography>
        )}
        <Spinner type="indeterminate" />
      </div>
    );
  }

  return (
    <div className="scheduling-sessions">
      <div>
        <Grid container>
          <Grid item xs={12}>
            <StaffFilter
              staffIds={staffIds}
              value={staffFilter}
              onChange={SessionSchedulingActions.filterStaff}
            />
          </Grid>
          <DayOfWeekFilter
            value={selectedDayOfWeek}
            intl={intl}
            onChange={SessionSchedulingActions.updateSelectedDayOfWeek}
          />
          <LocationFilter
            value={selectedLocations}
            locationIds={locationIds}
            onChange={SessionSchedulingActions.updateSelectedLocations}
          />
          <Grid item xs={12}>
            <Typography variant="body3" color="secondary">
              {t('.sessions_title', intl, __filenamespace)}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="scheduling-sessions__list">
        <SessionGrouping
          clientId={clientId}
          onSelect={SessionSchedulingActions.selectSession}
          selectedSessions={selectedSessionIds}
          sessionIds={sessionIds}
          sessionsLoading={sessionsLoading}
        />
      </div>
      {sessionsLoading && (
        <div className="scheduling-sessions__spinner">
          <Spinner />
        </div>
      )}

      <div style={styles.footer}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              fullWidth
              onClick={SessionSchedulingActions.bookSessions}
              disabled={selectedSessionIds.size <= 0}
              size="3x"
            >
              <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="button2">
                    {t('.btn_ok', intl, __filenamespace)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {t('.btn_ok_info', intl, __filenamespace, {
                      selectedCount: selectedSessionIds.size,
                      allCount:
                        creditsAvailable === Infinity ? '∞' : creditsAvailable,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              type="tertiary"
              onClick={onBack}
              size="3x"
              variant="body2"
            >
              {t('.btn_cancel', intl, __filenamespace)}
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

SessionScheduling.propTypes = {
  allowPast: PropTypes.bool,
  clientId: PropTypes.number,
  eventId: PropTypes.number,
  intl: PropTypes.object.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  selectedSessionIds: PropTypes.instanceOf(Set), // TODO: this should be a Set of strings
  sessionSchedulingStore: PropTypes.object,
};

SessionScheduling.defaultProps = {
  allowPast: false,
  clientId: 0,
  eventId: 0,
  onNext: () => {},
  onBack: () => {},
  selectedSessionIds: Set(),
  sessionSchedulingStore: {},
};

export default compose(
  injectIntl,
  altContainer({
    stores: {
      staffDataStore: StaffDataStore,
      sessionSchedulingStore: SessionSchedulingStore,
    },
  })
)(SessionScheduling);
