import debounce from 'lodash.debounce';
import moment from 'moment-timezone';
import { List } from 'immutable';

import { ClientSource, ContactFilterSource } from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import ContactsFiltersDrawerStore from 'containers/contacts/contactsFilters/Store';

import { downloadFile } from 'shared/utils/SharedUtils';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';

import ContactsFiltersDrawerActions from 'containers/contacts/contactsFilters/Actions';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import ContactsClientActions from './Actions';

export const FILTER_STATUS_ALL = 'all';
export const FILTER_STATUS_ACTIVE = 'active';
export const FILTER_STATUS_INVITED = 'invited';
export const FILTER_STATUS_UNCLAIMED = 'unclaimed';

class ContactsClientsStore extends UpperHandStore {
  constructor() {
    super();

    this.clientIds = List();
    this.page = 1;
    this.perPage = 25;
    this.totalCount = 0;
    this.search = '';
    this.statuses = FILTER_STATUS_ALL;
    this.loading = false;
    this.loadedAt = null;
    this.updateContactId = null;
    this.appliedFilters = new ContactFilter();
    this.isContactListDownloading = false;

    this.debouncedListClients = debounce(() => {
      this.listClients();
      this.emitChange();
    }, 600);

    this.bindListeners({
      mounted: ContactsClientActions.mounted,
      clientListSuccess: ContactsClientActions.clientListSuccess,
      clientListError: ContactsClientActions.clientListError,
      pageSelected: ContactsClientActions.pageSelected,
      searchUpdated: ContactsClientActions.searchUpdated,
      statusesUpdated: ContactsClientActions.statusesUpdated,
      clientArchived: ContactsClientActions.clientArchived,
      clientArchiveSuccess: ContactsClientActions.clientArchiveSuccess,
      clientArchiveError: ContactsClientActions.clientArchiveError,
      updateContact: ContactsClientActions.updateContact,
      downloadList: ContactsClientActions.downloadList,
      downloadListSuccess: ContactsClientActions.downloadListSuccess,
      downloadListError: ContactsClientActions.downloadListError,
      updateFilterAfterGroupAction: [
        ContactGroupActions.createSuccess,
        ContactGroupActions.deleteSuccess,
      ],

      applyFilter: ContactsFiltersDrawerActions.applyFilter,
      selectActiveFilter: ContactsFiltersDrawerActions.selectSavedFilter,
      saveFilterSuccess: ContactsFiltersDrawerActions.saveFilterSuccess,
      removeAppliedFilter: ContactsClientActions.removeAppliedFilter,
      clearFilters: ContactsFiltersDrawerActions.clearFilters,
    });
  }

  mounted = () => {
    if (
      !this.loadedAt ||
      this.loadedAt.isBefore(moment().subtract(5, 'minutes'))
    ) {
      this.listClients();
    }
  };

  applyFilter = appliedFilters => {
    this.setState({ appliedFilters });
    this.listClients();
  };

  selectActiveFilter = () => {
    this.waitFor(ContactsFiltersDrawerStore);

    const { filter } = ContactsFiltersDrawerStore.getState();

    this.setState({ appliedFilters: filter });
    this.listClients();
  };

  saveFilterSuccess = filter => {
    this.setState({ appliedFilters: new ContactFilter(filter) });
    this.listClients();
  };

  clearFilters = () => {
    this.setState({ appliedFilters: new ContactFilter() });
    this.listClients();
  };

  removeAppliedFilter = ({ name, value }) => {
    if (name === 'name') {
      this.setState({ appliedFilters: new ContactFilter() });
      this.listClients();
      return;
    }

    if (name === 'age') {
      this.setState({
        appliedFilters: this.appliedFilters
          .set('max_age', null)
          .set('min_age', null),
      });
      this.listClients();
      return;
    }

    if (name === 'account_statuses') {
      this.setState({
        appliedFilters: this.appliedFilters.set(
          name,
          this.appliedFilters.get(name).delete(value)
        ),
      });
      this.listClients();
      return;
    }

    if (name === 'subscription_statuses') {
      this.setState({
        appliedFilters: this.appliedFilters.set(
          name,
          this.appliedFilters.get(name).delete(value)
        ),
      });
      this.listClients();
      return;
    }

    if (name === 'month_of_birth') {
      this.setState({
        appliedFilters: this.appliedFilters.set(
          name,
          this.appliedFilters.get(name).delete(value)
        ),
      });
      this.listClients();
      return;
    }

    const path = name.split('.');

    this.setState({ appliedFilters: this.appliedFilters.setIn(path, value) });
    this.debouncedListClients();
  };

  listClients = () => {
    this.setState({ loading: true });
    ClientSource.list({
      params: this.getParams(),
      success: ContactsClientActions.clientListSuccess,
      error: ContactsClientActions.clientListError,
    });
  };

  clientListSuccess = ({ clients, totalCount }) => {
    this.setState({
      totalCount,
      clientIds: clients.map(c => c.id),
      loading: false,
      loadedAt: moment(),
    });
  };

  clientListError = (...args) => {
    this.setState({ loading: false });
    this.notifyError('error listing client contacts', args);
  };

  pageSelected = ([page, perPage]) => {
    this.setState({ page, perPage });
    this.listClients();
  };

  searchUpdated = newSearch => {
    this.setState({ search: newSearch, page: 1 });
    this.debouncedListClients();
  };

  statusesUpdated = newStatuses => {
    if (this.statuses === newStatuses) {
      return;
    }

    this.setState({ statuses: newStatuses, page: 1 });
    this.listClients();
  };

  /* eslint-disable class-methods-use-this */
  // this is an alt action listener, it has to be a method on the class object.
  clientArchived = id => {
    ClientSource.patch({
      id,
      recordAttributes: { archived: true },
      success: ContactsClientActions.clientArchiveSuccess,
      error: ContactsClientActions.clientArchiveError,
    });
  };
  /* eslint-enable class-methods-use-this */

  clientArchiveSuccess = () => {
    this.listClients();
  };

  clientArchiveError = (...args) => {
    this.notifyError('error archiving client', args);
  };

  updateContact = id => {
    this.setState({ updateContactId: id });
  };

  downloadList = () => {
    this.setState({ isContactListDownloading: true });

    const params = this.getParams();
    delete params.page;
    delete params.per_page;

    ContactFilterSource.downloadCsv({
      params,
      success: ContactsClientActions.downloadListSuccess,
      error: ContactsClientActions.downloadListError,
    });
  };

  downloadListSuccess = data => {
    downloadFile({
      data,
      fileName: 'contact_list.csv',
    });
    this.setState({ isContactListDownloading: false });
  };

  downloadListError = () => {
    this.setState({ isContactListDownloading: false });
  };

  updateFilterAfterGroupAction = group => {
    const groupId = group ? group.id : null;

    this.appliedFilters = this.appliedFilters.set('contact_group_id', groupId);

    if (!groupId) {
      ContactsFiltersDrawerActions.listFilters.defer();
    }
  };

  getParams = () => {
    const statuses =
      this.statuses === FILTER_STATUS_ALL
        ? [FILTER_STATUS_ACTIVE, FILTER_STATUS_INVITED, FILTER_STATUS_UNCLAIMED]
        : [this.statuses];

    const filters = this.appliedFilters.toJS();

    const params = {
      ...filters,
      page: this.page,
      per_page: this.perPage,
      statuses:
        filters.account_statuses.length > 0
          ? filters.account_statuses
          : statuses,
      account_type: filters.account_type || 'all',
      gender: filters.gender || 'all',
      purchased_event_ids:
        filters.purchased_products?.events?.map(e => e.id) || [],
      purchased_membership_ids:
        filters.purchased_products?.memberships?.map(m => m.id) || [],
      credit_pass_ids:
        filters.purchased_products?.credit_passes?.map(c => c.id) || [],
    };

    delete params.purchased_products;
    delete params.account_statuses;
    delete params.id;
    delete params.contact_group_id;

    if (this.search) {
      params.search = this.search;
    }
    return params;
  };
}

export default alt.createStore(ContactsClientsStore, 'ContactsClientsStore');
