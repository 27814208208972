import { List, Map, Record, fromJS } from 'immutable';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

export const EVENT_TIME_STATUS = {
  CANCELLED: 'cancelled',
  DRAFT: 'draft',
  STANDARD: 'standard',
  DELETED: 'deleted',
};

const moment = extendMoment(Moment);

class EventTime extends Record({
  attendance_customer_user_ids: List(),
  no_show_customer_user_ids: List(),
  client_status: Map({
    pending: List(),
    failed: List(),
    succeeded: List(),
  }),
  end_time: moment().format('HH:mm:ss'),
  event_date: moment().format('YYYY-MM-DD'),
  event_id: undefined,
  resource_ids: List(),
  staff_ids: List(),
  start_time: moment().format('HH:mm:ss'),
  status: EVENT_TIME_STATUS.STANDARD,
  debit_source_ids: List(),
  session_id: '',
  note: null,
}) {
  constructor(props = {}) {
    const clientStatusDefault = {
      pending: [],
      succeeded: [],
      failed: [],
      other: [],
      refunded: [],
      cancelled: [],
      past_due: [],
    };
    const rawClientStatus = Object.assign(
      clientStatusDefault,
      props.client_status
    );

    const attributes = {
      ...props,
      staff_ids: List(props.staff_ids),
      resource_ids: List(props.resource_ids),
      debit_source_ids: List(props.debit_source_ids),
      attendance_customer_user_ids: List(props.attendance_customer_user_ids),
      no_show_customer_user_ids: List(props.no_show_customer_user_ids),
      client_status: fromJS(rawClientStatus),
    };

    super(attributes);
  }

  dateRange() {
    const startDate = moment.tz(
      `${this.event_date} ${this.start_time}`,
      'YYYY-MM-DD HH:mm:ss',
      customerTZ()
    );
    const endDate = moment.tz(
      `${this.event_date} ${this.end_time}`,
      'YYYY-MM-DD HH:mm:ss',
      customerTZ()
    );

    return moment.range(startDate, endDate);
  }

  clientIds() {
    return this.client_status.toList().flatten(true).toSet();
  }

  unpaidClients() {
    return this.client_status.delete('succeeded').toList().flatten().toSet();
  }

  isAllPaid(clients) {
    return clients && clients.size && this.unpaidClients().size === 0;
  }

  isEventStandard() {
    return this.status === EVENT_TIME_STATUS.STANDARD;
  }

  isEventDraft() {
    return this.status === EVENT_TIME_STATUS.DRAFT;
  }

  isEventCancelled() {
    return this.status === EVENT_TIME_STATUS.CANCELLED;
  }

  isEventDeleted() {
    return this.status === EVENT_TIME_STATUS.DELETED;
  }

  isAllCheckedIn(clients) {
    return (
      clients &&
      this.attendance_customer_user_ids.size &&
      clients.size &&
      clients.size === this.attendance_customer_user_ids.size
    );
  }

  isAllNoShow(clients) {
    if (this.no_show_customer_user_ids.size > 0) {
      const isNoShow = clients.every(client =>
        this.no_show_customer_user_ids.some(id => id === client.id)
      );
      return isNoShow;
    }
    return false;
  }

  getStatusIntl() {
    if (this.isEventCancelled()) {
      return '.title_cancelled';
    }
    if (this.isEventDraft()) {
      return '.title_draft';
    }

    if (this.isEventDeleted()) {
      return '.title_deleted';
    }

    return '.title_draft';
  }
}

export default EventTime;
