import { List } from 'immutable';

import Parser from 'sources/Parser';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { onSuccess, onError } from 'sources/handlers';
import { PaymentMethod } from 'records';

import DataStoreActions from './PaymentMethodActions';

const parser = new Parser({
  type: PaymentMethod,
  paginationKey: 'payment_methods',
});

const url = userId => `/users/${userId}/payment_methods`;

export const list = ({ userId, success, error }) => {
  uhApiClient.get({
    url: url(userId),
    success: onSuccess(
      data => parser.parsePagination(data),
      List([DataStoreActions.listSuccess, success])
    ),
    error: onError(List([DataStoreActions.listError, error])),
  });
};

export const create = ({ userId, params, success, error }) => {
  uhApiClient.post({
    url: url(userId),
    data: JSON.stringify(params),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.createSuccess, success])
    ),
    error: onError(List([DataStoreActions.createError, error])),
  });
};

export const update = ({
  userId,
  paymentMethodId,
  recordAttributes,
  success,
  error,
}) => {
  uhApiClient.put({
    url: `${url(userId)}/${paymentMethodId}`,
    data: JSON.stringify({
      attributes: recordAttributes,
    }),
    success: onSuccess(
      data => parser.parseSingle(data),
      List([DataStoreActions.updateSuccess, success])
    ),
    error: onError(List([DataStoreActions.updateError, error])),
  });
};

export const destroy = ({ userId, paymentMethodId, success, error }) => {
  uhApiClient.delete({
    url: `${url(userId)}/${paymentMethodId}`,
    success: onSuccess(data => parser.parseNone(data), success),
    error,
  });
};
