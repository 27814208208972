import { List, Map } from 'immutable';

import Product from 'shared/records/Product.js';
import OptionType from 'shared/records/OptionType.js';
import Variant from 'shared/records/Variant.js';
import { Balance } from 'records';

import { ClientSource, BalancesSource, OrderItemSource } from 'sources';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';

import QuickpayModalActions from 'quickpay/actions/QuickpayModalActions.js';
import RefundModalActions from 'containers/reports/refundModal/Actions';
import CalendarActions from 'calendar/actions/CalendarActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

class QuickpayModalStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();

    this.bindListeners({
      closeModal: QuickpayModalActions.closeModal,

      handleClientFetchSuccess: QuickpayModalActions.fetchClientSuccess,
      handleClientFetchError: QuickpayModalActions.fetchClientError,

      handleCheckoutSuccess: QuickpayModalActions.checkoutSuccess,
      handleChangePaymentAmount: QuickpayModalActions.changePaymentAmount,

      toggleQuickpayModal: QuickpayModalActions.toggleQuickpayModal,
      toggleCalendarQuickpayModal:
        QuickpayModalActions.toggleCalendarQuickpayModal,

      togglePayAllQuickpayModal: QuickpayModalActions.togglePayAllQuickpayModal,

      handleSuccessfulBalanceWaive: RefundModalActions.waiveSuccess,

      fetchBalanceSuccess: QuickpayModalActions.fetchBalanceSuccess,
      fetchBalanceError: QuickpayModalActions.fetchBalanceError,
      fetchBalanceDetailsSuccess:
        QuickpayModalActions.fetchBalanceDetailsSuccess,
      fetchBalanceDetailsError: QuickpayModalActions.fetchBalanceDetailsError,

      fetchOrderItemSuccess: QuickpayModalActions.fetchOrderItemSuccess,
      fetchOrderItemError: QuickpayModalActions.fetchOrderItemError,

      toggleAddonProductModal: QuickpayModalActions.toggleAddonProductModal,
      selectRetailProduct: QuickpayModalActions.selectRetailProduct,

      listRetailProducts: QuickpayModalActions.listRetailProducts,
      listRetailProductsSuccess: QuickpayModalActions.listRetailProductsSuccess,
      listRetailProductsError: QuickpayModalActions.listRetailProductsError,

      selectOption: QuickpayModalActions.selectOption,
      setQuantity: QuickpayModalActions.setQuantity,

      listOptionTypesSuccess: QuickpayModalActions.listOptionTypesSuccess,
      listOptionTypesError: QuickpayModalActions.listOptionTypesError,
      listVariantsSuccess: QuickpayModalActions.listVariantsSuccess,
      listVariantsError: QuickpayModalActions.listVariantsError,

      addRetailProduct: QuickpayModalActions.addRetailProduct,
      addRetailProductSuccess: QuickpayModalActions.addRetailProductSuccess,
      addRetailProductError: QuickpayModalActions.addRetailProductError,

      removeRetailProduct: QuickpayModalActions.removeRetailProduct,
      removeRetailProductSuccess:
        QuickpayModalActions.removeRetailProductSuccess,
      removeRetailProductError: QuickpayModalActions.removeRetailProductError,

      fetchAddonSuccess: QuickpayModalActions.fetchAddonSuccess,
      fetchAddonError: QuickpayModalActions.fetchAddonError,

      changeAccountCreditAmount: QuickpayModalActions.changeAccountCreditAmount,
    });
  }

  reset() {
    this.balance = new Balance();
    this.mode = 'balance';
    this.isLoading = false;
    this.isClientLoading = false;
    this.isBalanceLoading = false;
    this.isBalanceDetailsLoading = false;
    this.isOrderItemLoading = false;
    this.isAddonLoading = false;
    this.isModalOpen = false;
    this.isOnCalendar = false;
    this.clientId = null;
    this.paymentAmount = this.balance.outstanding;
    this.amount = this.balance.outstanding;
    this.accountCreditAmount = null;
    this.availableAccountCreditAmount = 0;
    this.orderItem = null;
    this.addonOrder = null;
    this.resetAddonModal();
  }

  resetAddonModal() {
    this.hasMoreProducts = false;
    this.productsLoading = false;
    this.optionsLoading = false;
    this.addonModalOpen = false;
    this.retailProducts = List();
    this.selectedRetailProduct = null;
    this.retailProductOptionTypes = List();
    this.selectedOptions = Map();
    this.selectedVariant = null;
    this.selectedQuantity = 1;
    this.pagination = Map({
      retailProducts: Map({
        searchString: '',
        page: 1,
        perPage: 25,
        totalCount: 0,
      }),
    });
  }

  closeModal() {
    this.reset();
  }

  openModal() {
    this.isModalOpen = true;
  }

  changeAccountCreditAmount(amount) {
    this.accountCreditAmount =
      amount < this.paymentAmount ? amount : this.paymentAmount;

    QuickpayModalActions.changePaymentAmount.defer(
      amount !== null
        ? this.paymentAmount - this.accountCreditAmount
        : this.amount
    );
  }

  handleChangePaymentAmount(amount) {
    const total = this.amount - this.accountCreditAmount;

    this.paymentAmount = amount > total ? total : amount;
  }

  toggleQuickpayModal([balance, mode]) {
    this.reset();
    this.balance = balance;
    this.mode = mode;
    this.paymentAmount = this.balance.outstanding;
    this.amount = this.balance.outstanding;
    this.isLoading = true;
    this.isClientLoading = true;
    this.fetchClient(this.balance.clientId);
    this.openModal();
  }

  toggleCalendarQuickpayModal(eventTime) {
    this.reset();
    this.isClientLoading = true;
    this.isBalanceLoading = true;
    this.isOrderItemLoading = true;
    this.isLoading = true;
    this.isOnCalendar = true;

    const clientId = eventTime.client_status.get('pending').first();
    this.balance = this.balance.merge({
      clientId,
      productId: eventTime.debit_source_ids.first(),
    });

    this.fetchBalance(eventTime.debit_source_ids.first());
    this.openModal();
  }

  togglePayAllQuickpayModal({ mode, clientId, balanceRemaining }) {
    this.reset();
    this.mode = mode;
    this.balance = new Balance({ clientId, balanceRemaining });
    this.paymentAmount = balanceRemaining;
    this.isLoading = true;
    this.isClientLoading = true;
    this.fetchClient(clientId);
    this.openModal();
  }

  handleCheckoutSuccess() {
    this.reset();
  }

  // eslint-disable-next-line class-methods-use-this
  fetchClient(clientId) {
    ClientSource.fetch({
      id: clientId,
      params: {
        fields: ['total_account_credit', 'agreement_details', 'total_credits'],
      },
      success: QuickpayModalActions.fetchClientSuccess,
      error: QuickpayModalActions.fetchClientError,
    });
  }

  handleClientFetchSuccess(client) {
    this.availableAccountCreditAmount = client.total_account_credit;
    this.clientId = client.id;
    this.isClientLoading = false;
    this.updateLoadingState();
  }

  handleClientFetchError(...args) {
    this.notifyError('Error while fetching client:', args);
    this.reset();
  }

  handleSuccessfulBalanceWaive({ amount }) {
    const isOnCalendar = window.location.pathname.includes('calendar');
    const shouldUpdate = this.isModalOpen && amount !== this.paymentAmount;

    if (amount === this.paymentAmount) {
      this.reset();
    }

    if (shouldUpdate) {
      this.balance = this.balance.set(
        'balanceRemaining',
        this.balance.get('balanceRemaining') + amount
      );
      this.amount -= amount;
      this.paymentAmount -= amount;
    }

    // Refetch calendar only if user is on the calendar page.
    if (isOnCalendar) {
      CalendarActions.list.defer();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  fetchBalance(sourceId) {
    BalancesSource.list({
      params: { product_ids: [sourceId] },
      success: QuickpayModalActions.fetchBalanceSuccess,
      error: QuickpayModalActions.fetchBalanceError,
    });
  }

  fetchBalanceSuccess({ records: [balance] }) {
    if (this.isOnCalendar) {
      this.fetchOrderItem(balance);
    } else {
      this.paymentAmount = this.balance.outstanding;
      this.amount = this.balance.outstanding;
    }

    this.balance = balance;
    this.fetchClient(balance.clientId);
    this.fetchBalanceDetails(balance);
    this.isBalanceLoading = false;
    this.updateLoadingState();
  }

  fetchBalanceError(...args) {
    this.notifyError('Error while fetching balance', args);
    this.reset();
  }

  fetchBalanceDetails(balance) {
    this.isBalanceDetailsLoading = true;
    BalancesSource.details({
      productId: balance.productId,
      compoundId: balance.compoundId,
      success: QuickpayModalActions.fetchBalanceDetailsSuccess,
      error: QuickpayModalActions.fetchBalanceDetailsError,
    });
  }

  fetchBalanceDetailsSuccess(details) {
    this.balance = this.balance.set('details', details.collection);
    this.isBalanceDetailsLoading = false;
    this.updateLoadingState();
  }

  fetchBalanceDetailsError(...args) {
    this.notifyError('Error while fetching balance details', args);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchOrderItem(balance) {
    if (balance) {
      OrderItemSource.list({
        params: { ids: [balance.productId] },
        success: QuickpayModalActions.fetchOrderItemSuccess,
        error: QuickpayModalActions.fetchOrderItemError,
      });
    }
  }

  fetchOrderItemSuccess({ order_items: [orderItem] }) {
    this.orderItem = orderItem;

    if (orderItem && orderItem.orderable_type === 'registration_package') {
      this.paymentAmount = orderItem.total / this.balance.quantity;
      this.amount = orderItem.total / this.balance.quantity;
    }
    this.isOrderItemLoading = false;
    this.updateLoadingState();
    this.fetchAddonOrder();
  }

  fetchOrderItemError(...args) {
    this.notifyError('Error while fetching order item', args);
    this.reset();
  }

  updateLoadingState() {
    this.isLoading =
      this.isClientLoading ||
      this.isBalanceLoading ||
      this.isOrderItemLoading ||
      this.isBalanceDetailsLoading ||
      this.isAddonLoading;
  }

  toggleAddonProductModal() {
    this.addonModalOpen = !this.addonModalOpen;

    if (this.addonModalOpen) {
      this.listRetailProducts({});
    } else {
      this.resetAddonModal();
    }
  }

  setQuantity(qty) {
    this.selectedQuantity = qty;
  }

  selectRetailProduct(product) {
    this.selectedRetailProduct = new Product(product || {});
    this.retailProductOptionTypes = List();
    this.selectedOptions = Map();
    this.selectedVariant = null;
    this.selectedQuantity = 1;

    if (!this.selectedRetailProduct.option_type_ids.isEmpty()) {
      this.optionsLoading = true;
      uhApiClient.get({
        url: `products/${this.selectedRetailProduct.id}/option_types`,
        success: QuickpayModalActions.listOptionTypesSuccess,
        error: QuickpayModalActions.listOptionTypesError,
      });
    } else {
      this.loadVariant();
    }
  }

  loadVariant() {
    uhApiClient.get({
      url: `products/${this.selectedRetailProduct.id}/variants`,
      data: {
        option_value_ids: this.selectedOptions.toList().toJS(),
      },
      success: QuickpayModalActions.listVariantsSuccess,
      error: QuickpayModalActions.listVariantsError,
    });
  }

  listVariantsSuccess(data) {
    if (data && data.variants.length === 1) {
      this.selectedVariant = new Variant(data.variants[0]);
    }
  }

  listVariantsError(...args) {
    this.notifyError('Error listing product variants', args);
  }

  selectOption([optionTypeId, optionValueId]) {
    this.selectedOptions = this.selectedOptions.set(
      optionTypeId,
      optionValueId
    );
    this.loadVariant();
  }

  listOptionTypesSuccess(data) {
    this.retailProductOptionTypes = List(
      data.option_types.map(o => new OptionType(o))
    );
    this.optionsLoading = false;
  }

  listOptionTypesError(...args) {
    this.optionsLoading = false;
    this.notifyError('Error listing product option types', args);
  }

  listRetailProducts({ searchString = '', loadMore = false }) {
    this.pagination = this.pagination.setIn(
      ['retailProducts', 'searchString'],
      searchString
    );
    this.productsLoading = true;

    const retailProductsPagination = this.pagination.get('retailProducts');
    const page = retailProductsPagination.get('page');
    const perPage = retailProductsPagination.get('perPage');
    const search = retailProductsPagination.get('searchString');

    uhApiClient.get({
      url: 'products',
      data: {
        page: this.hasMoreProducts && loadMore ? page + 1 : page,
        per_page: perPage,
        name: search,
        statuses: ['active'],
        product_types: 'retail',
      },
      success: QuickpayModalActions.listRetailProductsSuccess,
      error: QuickpayModalActions.listRetailProductsError,
    });
  }

  listRetailProductsSuccess({
    products,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) {
    const newProducts = List(
      products.filter(p => p.published).map(p => new Product(p))
    );
    this.retailProducts =
      page === 1 ? newProducts : this.retailProducts.concat(newProducts);
    this.productsLoading = false;
    this.hasMoreProducts = page * perPage < totalCount;
  }

  listRetailProductsError(...args) {
    this.productsLoading = false;
    this.notifyError('Error listing products', args);
  }

  fetchAddonOrder() {
    const addonOrderId = this.orderItem?.get('addon_order_item_id');

    if (addonOrderId) {
      this.isAddonLoading = true;

      uhApiClient.get({
        url: `order_items/${this.orderItem.get('addon_order_item_id')}`,
        success: QuickpayModalActions.fetchAddonSuccess,
        error: QuickpayModalActions.fetchAddonError,
      });
    }
  }

  fetchAddonSuccess(orderItem) {
    this.addonOrder = orderItem;
    this.isAddonLoading = false;
    this.updateLoadingState();
  }

  fetchAddonError(...args) {
    this.notifyError('Error fetching addon product', args);
    this.isAddonLoading = false;
  }

  addRetailProduct() {
    uhApiClient.post({
      url: 'order_items',
      data: JSON.stringify({
        attributes: {
          orderable_id: this.selectedVariant.id,
          orderable_type: 'variant',
          quantity: this.selectedQuantity,
        },
        customer_user_id: this.orderItem.buyer_id,
        master_order_item_id: this.orderItem.id,
      }),
      success: QuickpayModalActions.addRetailProductSuccess,
      error: QuickpayModalActions.addRetailProductError,
    });
  }

  addRetailProductSuccess(orderItem) {
    this.addonOrder = orderItem;
    this.orderItem = this.orderItem.set('addon_order_item_id', orderItem.id);
    this.resetAddonModal();
  }

  addRetailProductError(...args) {
    this.notifyError('Error adding retail product', args);
  }

  // eslint-disable-next-line class-methods-use-this
  removeRetailProduct(id) {
    uhApiClient.delete({
      url: `order_items/${id}`,
      success: QuickpayModalActions.removeRetailProductSuccess,
      error: QuickpayModalActions.removeRetailProductError,
    });
  }

  removeRetailProductSuccess() {
    this.addonOrder = null;
    this.orderItem = this.orderItem.set('addon_order_item_id', null);
  }

  removeRetailProductError(...args) {
    this.notifyError('Error deleting retail product', args);
  }
}

export default alt.createStore(QuickpayModalStore, 'QuickpayModalStore');
