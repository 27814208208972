import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { List } from 'immutable';

import MuiList from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { Typography, Tabs, Grid, Icon } from '@upperhand/playmaker';
import { FormattedMessage, injectIntl } from 'react-intl';

import altContainer from 'shared/hocs/altContainer.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { smallScreen } from 'shared/utils/DOMUtils.js';
import { humanizeDateDuration } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';

import MembershipActions from 'shared/actions/MembershipActions.jsx';
import SecondaryDrawerActions from 'shared/actions/SecondaryDrawerActions.jsx';
import RetailCategoryListingStore from 'shared/stores/RetailCategoryListingStore.jsx';
import EventTypeListingStore from 'shared/stores/EventTypeListingStore.jsx';
import MembershipViewingStore from 'memberships/stores/MembershipViewingStore.jsx';
import RetailCategoryListingActions from 'shared/actions/RetailCategoryListingActions.jsx';
import EventTypeListingActions from 'shared/actions/EventTypeListingActions.jsx';
import CreditPassListingStore from 'credit_passes/stores/CreditPassListingStore.jsx';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import SecondaryDrawerStore from 'shared/stores/SecondaryDrawerStore.jsx';
import MembershipListingUIStore from 'memberships/stores/MembershipListingUIStore.jsx';
import MembershipPermissionStore from 'memberships/stores/MembershipPermissionStore.jsx';
import MembershipPurchasingStore from 'memberships/stores/MembershipPurchasingStore.jsx';
import AthleteStore from 'event_mgmt/shared/stores/AthleteStore.jsx';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import MembershipSubscriptionActions from 'memberships/actions/MembershipSubscriptionActions.jsx';
import MembershipSubscriptionStore from 'memberships/stores/MembershipSubscriptionStore.jsx';
import CreditPassListingActions from 'credit_passes/actions/CreditPassListingActions.js';
import MembershipPurchasingActions from 'memberships/actions/MembershipPurchasingActions.jsx';
import { MembershipTierDataStore } from 'dataStores';

import UserAvatar from 'shared/components/_UserAvatar.jsx';
import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import PurchaseDrawer from 'memberships/components/PurchaseDrawer.jsx';
import MembershipTierCard from 'memberships/components/MembershipViewClient/_MembershipTierCard.jsx';

import ResponsiveElement from 'shared/components/ResponsiveElement.jsx';
import PageHeaderBanner from './_PageHeaderBanner.jsx';

import './styles.scss';

const isMobile = smallScreen();

const styles = {
  descriptionBox: {
    padding: 3,
    fontSize: '14px',
    backgroundColor: 'white',
    lineHeight: 1.2,
    whiteSpace: 'pre-line',
    borderRadius: '10px',
    border: '1px solid var(--color-divide)',
  },
  pricingInfo: {
    fontSize: isMobile ? '1rem' : '2rem',
    padding: isMobile ? '0px 0px' : '10px 0px',
    fontWeight: 800,
    color: 'var(--color-white)',
  },
  pricingInfoLabel: {
    fontSize: isMobile ? '1rem' : '1.5rem',
    color: 'var(--color-white)',
  },
  joinFee: {
    textAlign: 'center',
    display: 'flex',
  },
  pricingCard: {
    height: isMobile ? '70px' : '100px',
    display: 'flex',
  },
  intervalCard: {
    width: '5%',
  },
  feeCard: {
    width: '95%',
    backgroundColor: '#454952',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  renderPricing: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 5,
  },
  commitmentBox: {
    padding: 3,
    height: '50px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  enrollmentBox: {
    padding: 3,
    backgroundColor: 'white',
    width: '100%',
    fontSize: '14px',
    marginBottom: 1,
    borderRadius: '10px',
    border: '1px solid var(--color-divide)',
  },
  commitmentMonth: {
    fontSize: '14px',
  },
  AddIcon: {
    color: 'var(--color-white)',
    fontSize: isMobile ? '1rem' : '2rem',
  },
  addIconWrapper: {
    paddingTop: '12px',
  },
  creditBenefitsStyles: {
    paddingBottom: '10px',
  },
  benefitsBox: {
    backgroundColor: 'white',
    padding: 3,
  },
  membershipSectionsBox: {
    px: {
      xs: 10,
      md: 20,
      lg: 25,
    },
  },
  membershipSectionsBoxMobile: {
    padding: 3,
    position: 'relative',
  },
  tiersList: {
    display: 'flex',
    gap: '35px',
    overflowX: 'scroll',
    paddingBottom: '10px',
  },
  tiersListMobile: {
    flexDirection: 'column',
  },
};

const MemberLength = injectIntl(({ activeSubscriptionStartDate, intl }) => (
  <Box>
    {activeSubscriptionStartDate &&
      humanizeDateDuration({
        duration: moment.duration(
          new Date() - activeSubscriptionStartDate,
          'milliseconds'
        ),
        styles: { default: 'medium', years: 'long' },
        intl,
        precision: 'months',
      })}
  </Box>
));

function ActiveEnrollment({ activeMembers, subscriptions }) {
  if (activeMembers.size === 0 && subscriptions.size === 0) {
    return (
      <Box
        sx={merge(styles.enrollmentBox, {
          display: 'flex',
          alignItems: 'center',
        })}
      >
        <FormattedMessage id={messageId('.no_enrollments', __filenamespace)} />
      </Box>
    );
  }
  return (
    <MuiList sx={{ padding: 0 }}>
      {activeMembers.map(member => {
        const subscription = subscriptions.find(
          sub => sub.get('client_id') === member.id
        );
        const activeSubscriptionStartDate = subscription?.starts_at;
        return (
          <ListItem key={member.id} sx={styles.enrollmentBox}>
            <ListItemIcon>
              <UserAvatar user={member} />
            </ListItemIcon>
            <ListItemText primary={member.name()} />
            <MemberLength
              activeSubscriptionStartDate={activeSubscriptionStartDate}
            />
          </ListItem>
        );
      })}
    </MuiList>
  );
}

function LargeScreen({
  membership,
  commitmentLabel,
  eventTypes,
  creditPasses,
  retailCategories,
  activeMembers,
  subscriptions,
  tiersData,
}) {
  const [hasScroll, setHasScroll] = useState(false);
  const { tiers, tiersIds } = tiersData;
  const { tiered } = membership;
  const tierListContainer = document.querySelector(
    '.membership-tiers-list__container'
  );

  useEffect(() => {
    const tierList = document.querySelector(
      '.membership-tiers-list__container'
    );

    const checkScroll = () => {
      setHasScroll(tierList?.scrollWidth > tierList?.clientWidth);
    };

    checkScroll();

    window.addEventListener('resize', checkScroll);

    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, [tiersIds]);

  const handleScroll = direction => {
    const scrollAmount = 355;

    if (direction === 'left') {
      tierListContainer.scrollTo({
        left: tierListContainer.scrollLeft - scrollAmount,
        behavior: 'smooth',
      });
    } else {
      tierListContainer.scrollTo({
        left: tierListContainer.scrollLeft + scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box className="membership-client-view">
      <PageHeaderBanner
        membership={membership}
        commitmentLabel={commitmentLabel}
      />
      <Box sx={styles.membershipSectionsBox}>
        <Grid container spacing={2}>
          {tiered && (
            <Grid item xs={12} className="membership-tiers-list">
              {hasScroll && (
                <IconButton
                  className="membership-tiers-list__prev-tier"
                  onClick={() => handleScroll('left')}
                >
                  <Icon name="arrowLeft" size="x-large" />
                </IconButton>
              )}
              <Box
                className="membership-tiers-list__container"
                sx={styles.tiersList}
              >
                {tiersIds.map(id => {
                  const tier = tiers.get(id);

                  return (
                    <MembershipTierCard
                      key={id}
                      tier={tier}
                      expanded={tiersIds.size === 1}
                      membership={membership}
                      eventTypes={eventTypes}
                      creditPasses={creditPasses}
                      retailCategories={retailCategories}
                    />
                  );
                })}
              </Box>
              {hasScroll && (
                <IconButton
                  className="membership-tiers-list__next-tier"
                  onClick={() => handleScroll()}
                >
                  <Icon name="arrowRight" size="x-large" />
                </IconButton>
              )}
            </Grid>
          )}
          {!tiered && (
            <Grid item container justify="flex-end" xs={12} lg={5}>
              <MembershipTierCard
                expanded
                hideExpand
                tier={membership}
                membership={membership}
                eventTypes={eventTypes}
                creditPasses={creditPasses}
                retailCategories={retailCategories}
              />
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            lg={tiered ? 12 : 7}
            spacing={tiered ? 2 : 0}
          >
            <Grid item xs={tiered ? 6 : 12}>
              <Box>
                <Typography
                  variant="h4"
                  className={clsx(
                    'membership-section-heading',
                    !membership.tiered && 'mt-0'
                  )}
                >
                  <FormattedMessage
                    id={messageId('.header_text_summary', __filenamespace)}
                  />
                </Typography>
                <Box sx={styles.descriptionBox}>{membership.description}</Box>
              </Box>
            </Grid>
            <Grid item xs={tiered ? 6 : 12}>
              <Box>
                <Typography variant="h4" className="membership-section-heading">
                  <FormattedMessage
                    id={messageId('.header_text_enrollment', __filenamespace)}
                  />
                </Typography>
                <ActiveEnrollment
                  activeMembers={activeMembers}
                  subscriptions={subscriptions}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function SmallScreen({
  membership,
  commitmentLabel,
  eventTypes,
  creditPasses,
  retailCategories,
  activeMembers,
  subscriptions,
  tiersData,
}) {
  const [tabSelected, setTabSelected] = useState(0);
  const { tiered } = membership;
  const { tiers, tiersIds } = tiersData;
  const tabs = [
    {
      label: 'Pricing',
      content: (
        <Box sx={styles.membershipSectionsBoxMobile}>
          <Button
            fullWidth
            variant="contained"
            sx={{ marginBottom: 3 }}
            onClick={() => {
              MembershipPurchasingActions.purchaseClicked({
                membership,
                buyerId: currentUser().customer_user_id,
              });
            }}
          >
            <FormattedMessage id={messageId('.purchase', __filenamespace)} />
          </Button>
          <Grid container spacing={2}>
            {tiered && (
              <Grid item xs={12}>
                <Box sx={{ ...styles.tiersList, ...styles.tiersListMobile }}>
                  {tiersIds.map(id => {
                    const tier = tiers.get(id);

                    return (
                      <MembershipTierCard
                        key={id}
                        tier={tier}
                        membership={membership}
                        eventTypes={eventTypes}
                        creditPasses={creditPasses}
                        retailCategories={retailCategories}
                      />
                    );
                  })}
                </Box>
              </Grid>
            )}
            {!tiered && (
              <Grid item xs={12}>
                <MembershipTierCard
                  tier={membership}
                  membership={membership}
                  eventTypes={eventTypes}
                  creditPasses={creditPasses}
                  retailCategories={retailCategories}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      ),
    },
    {
      label: 'Overview',
      content: (
        <Box sx={styles.membershipSectionsBoxMobile}>
          <Typography
            variant="h5"
            className="membership-section-heading-mobile"
          >
            <FormattedMessage
              id={messageId('.header_text_enrollment', __filenamespace)}
            />
          </Typography>
          <ActiveEnrollment
            activeMembers={activeMembers}
            subscriptions={subscriptions}
          />

          <Typography
            variant="h5"
            className="membership-section-heading-mobile"
          >
            <FormattedMessage
              id={messageId('.header_text_summary', __filenamespace)}
            />
          </Typography>
          <Box sx={styles.descriptionBox}>{membership.description}</Box>
        </Box>
      ),
    },
  ];
  const onTabChange = (_event, value) => setTabSelected(value);
  return (
    <>
      <PageHeaderBanner
        membership={membership}
        commitmentLabel={commitmentLabel}
      />

      <Tabs
        variant="fullWidth"
        tabItems={tabs}
        onChange={onTabChange}
        value={tabSelected}
        classes={{
          root: 'membership-view-tabs',
        }}
        labelVariant="h5"
      />
    </>
  );
}

function MembershipViewClient(props) {
  const isClient = currentUser().isClient();
  const { membershipID } = useParams();
  const {
    retailCategoryListingStore: { retailCategories },
    eventTypeListingStore: { eventTypes, isLoading },
    creditPassListingStore: { records: creditPasses },
    membershipViewingStore: { record: membership, tiersIds, tiersLoading },
    membershipPermissionStore: { isPermittedToPurchase },
    membershipTierDataStore: { tiers },
    membershipPurchasing,
    intl,
    drawer,
    athleteStore,
    cartStore,
    ui: { loading },
    membershipSubscriptionStore: { subscriptions },
  } = props;
  const athleteMembershipMap = athleteStore.allAthletes.groupBy(
    a => a.active_membership_id
  );
  const activeMembers = athleteMembershipMap.get(membership.id, List());
  let commitmentLabel;
  if (membership.commitment_months === 0) {
    commitmentLabel = '.no_commitment';
  } else if (membership.commitment_months === 1) {
    commitmentLabel = '.one_month_commitment';
  } else {
    commitmentLabel = '.commitment_months';
  }

  useEffect(() => {
    if (membershipID) {
      if (isClient) {
        AthleteActions.list({
          fields: ['active_membership_id', 'permitted_membership_ids'],
        });
      }
      CreditPassListingActions.listRequested({});
      EventTypeListingActions.list();
      RetailCategoryListingActions.list();
      MembershipActions.fetch({ id: membershipID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (activeMembers.size > 0) {
      MembershipSubscriptionActions.enrolledClientsSubscriptionsList({
        clientIds: activeMembers.map(client => client.id).toArray(),
        membershipIds: [membershipID],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMembers.size]);
  return (
    <SpinWhileLoading isLoading={isLoading || loading}>
      <PurchaseDrawer
        tiersLoading={tiersLoading}
        drawer={drawer}
        cartStore={cartStore}
        eventTypes={eventTypes}
        creditPasses={creditPasses}
        athleteStore={athleteStore}
        retailCategories={retailCategories}
        isPermittedToPurchase={isPermittedToPurchase}
        membershipPurchasing={membershipPurchasing}
        close={SecondaryDrawerActions.close}
        open={drawer.activeDrawer}
        title={t('.header', intl, __filenamespace)}
        tiers={tiersIds.map(id => tiers.get(id)).toList()}
      />
      <ResponsiveElement
        largeScreen={
          <LargeScreen
            membership={membership}
            intl={intl}
            commitmentLabel={commitmentLabel}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            activeMembers={activeMembers}
            subscriptions={subscriptions}
            tiersData={{ tiers, tiersIds }}
          />
        }
        smallScreen={
          <SmallScreen
            membership={membership}
            intl={intl}
            commitmentLabel={commitmentLabel}
            eventTypes={eventTypes}
            creditPasses={creditPasses}
            retailCategories={retailCategories}
            activeMembers={activeMembers}
            subscriptions={subscriptions}
            tiersData={{ tiers, tiersIds }}
          />
        }
      />
    </SpinWhileLoading>
  );
}

export default altContainer({
  stores: {
    retailCategoryListingStore: RetailCategoryListingStore,
    eventTypeListingStore: EventTypeListingStore,
    creditPassListingStore: CreditPassListingStore,
    membershipViewingStore: MembershipViewingStore,
    membershipPermissionStore: MembershipPermissionStore,
    cartStore: CartStore,
    drawer: SecondaryDrawerStore,
    ui: MembershipListingUIStore,
    membershipPurchasing: MembershipPurchasingStore,
    athleteStore: AthleteStore,
    membershipSubscriptionStore: MembershipSubscriptionStore,
    membershipTierDataStore: MembershipTierDataStore,
  },
  actions: { SecondaryDrawerActions },
})(injectIntl(MembershipViewClient));
