import { Iterable, Record } from 'immutable';
import AddressType from './AddressType';

class Address extends Record({
  id: null,
  line_1: '',
  line_2: '',
  line_3: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  address_type: new AddressType(),
}) {
  constructor(obj = {}) {
    if (Iterable.isIterable(obj)) {
      obj = obj.toJS();
    }

    super({ ...obj, address_type: new AddressType(obj.address_type) });
  }

  isBlank() {
    return (
      !this.line_1 &&
      !this.line_2 &&
      !this.line_3 &&
      !this.city &&
      !this.state &&
      !this.postal_code
    );
  }

  isValid() {
    return this.line_1 && this.city && this.state && this.postal_code;
  }
}

export default Address;
