import { List } from 'immutable';
import { onError, onSuccess } from 'sources/handlers';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

export const fetchContactFilters = ({ success, error }) => {
  uhApiClient.get({
    url: '/contact_filters',
    success: onSuccess(data => data, List([success])),
    error: onError(List([error])),
  });
};

export const createOrUpdateFilter = ({ id, params, success, error }) => {
  const url = id ? `contact_filters/${id}` : 'contact_filters';
  const method = id ? 'put' : 'post';
  uhApiClient[method]({
    url,
    data: JSON.stringify(params),
    success: onSuccess(data => data, success),
    error: onError(List([error])),
  });
};

export const deleteContactFilter = ({ id, success, error }) => {
  uhApiClient.delete({
    url: `/contact_filters/${id}`,
    success: onSuccess(data => data, success),
    error: onError(List([error])),
  });
};

export const downloadCsv = ({ params, success, error }) => {
  uhApiClient.get({
    url: 'clients/csv',
    headers: { Accept: 'application/csv', 'Content-Type': 'application/csv' },
    data: params,
    success: onSuccess(data => data, success),
    error,
  });
};
