import { Map } from 'immutable';
import Staff from 'shared/records/Client.jsx';
import AutoCompleteStaffListActions from 'shared/actions/AutoCompleteStaffListActions';
import PayrollReportActions from 'reporting/payroll/actions/PayrollReportActions';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';

class AutoCompleteStaffListStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();

    this.bindListeners({
      listClients: PayrollReportActions.MOUNTED,
      listSuccess: AutoCompleteStaffListActions.LIST_SUCCESS,
      listError: AutoCompleteStaffListActions.LIST_ERROR,

      updateSearchString: AutoCompleteStaffListActions.SEARCH_STRING_UPDATED,
    });
  }

  reset() {
    this.staff = Map();
    this.searchString = '';
    this.page = 1;
    this.perPage = 10;
    this.totalCount = 0;
    this.shouldFetch = true;
    this.isLoading = false;

    this.formattedStaff = Map();
  }

  listClients() {
    if (this.shouldFetch) {
      this.isLoading = true;
      this.shouldFetch = false;

      uhApiClient.get({
        url: 'customer_users?types[]=staff_admin&types[]=staff_member',
        data: this.queryParams(),
        success: AutoCompleteStaffListActions.listSuccess,
        error: AutoCompleteStaffListActions.listError,
      });
    }
  }

  listSuccess({ customer_users: customerUsers, total_count: totalCount }) {
    this.isLoading = false;
    this.totalCount = totalCount;
    this.staff = Map().withMutations(staffMap =>
      customerUsers.map(c => staffMap.set(c.id, new Staff(c)))
    );
    this.formattedStaff = this.staff
      .map(staff => ({ text: staff.name(), value: staff.id }))
      .toList()
      .toJS();
  }

  listError(...args) {
    this.isLoading = false;
    this.notifyError('error listing clients', args);
  }

  updateSearchString(searchString) {
    if (!this.shouldFetch && this.searchString === searchString) {
      return;
    }

    this.searchString = searchString;
    this.page = 1;
    this.shouldFetch = true;

    this.listClients();
  }

  queryParams() {
    return {
      page: this.page,
      per_page: this.perPage,
      search_string: this.searchString,
      fields: [
        'managing_customer_user_id',
        'managing_customer_user_name',
        'active_membership_id',
      ],
    };
  }
}

export default alt.createStore(
  AutoCompleteStaffListStore,
  'AutoCompleteStaffListStore'
);
