import { List, Map, Record, Set } from 'immutable';
import Coupon from 'shared/records/Coupon.jsx';
import OrderItem from 'shared/records/OrderItem';
import { sumInt } from 'shared/utils/ImmutableUtils';
import { currentCustomer } from 'shared/utils/CustomerUtils';

class Order extends Record({
  id: null,
  due_immediately: 0,
  failure_message: null,
  failure_suggestion: null,
  order_items: List(),
  status: 'in_progress',
  subtotal: 0,
  tax: 0,
  fee: 0,
  tax_exempt: false,
  fee_exempt: false,
  tax_id: '',
  total: 0,
  total_due_at_checkout: 0,
  coupon: null,
  prorate_date: null,
  insurance_proposal_ids: Set(),
  insurance_amount: 0,
  insurance_metadata: Map(),
}) {
  constructor(obj = {}, options = {}) {
    const orderItems = List(obj.order_items).map(
      item => new OrderItem(item, options)
    );
    const coupon = obj.coupon ? new Coupon(obj.coupon) : null;

    super({
      ...obj,
      coupon,
      order_items: orderItems,
      insurance_proposal_ids: new Set(obj.insurance_proposal_ids),
      insurance_metadata: new Map(obj.insurance_metadata),
    });
  }

  addInsurance(insurance) {
    return this.withMutations(order => {
      order.set(
        'insurance_metadata',
        order
          .get('insurance_metadata', Map())
          .set(insurance.proposal_id, insurance.price)
      );
    });
  }

  removeInsurance(proposalId) {
    return this.withMutations(order => {
      order.set(
        'insurance_metadata',
        order.get('insurance_metadata', Map()).delete(proposalId)
      );
    });
  }

  addItem(item) {
    return this.withMutations(order => {
      order.updateIn(['order_items'], List(), list => list.push(item));
      order.set('total', order.total + item.subtotal);
      order.set('subtotal', order.subtotal + item.subtotal);
    });
  }

  removeItem(id) {
    const [index, item] = this.order_items.findEntry(
      orderItem => orderItem.id === id
    );
    return this.withMutations(order => {
      order.deleteIn(['order_items', index]);
      order.set('total', order.total - item.subtotal);
      order.set('subtotal', order.subtotal - item.subtotal);
    });
  }

  couponAdjustment() {
    return this.order_items
      .flatMap(i => i.applied_adjustments)
      .filter(a => a.get('type') === 'Coupon')
      .reduce(sumInt('amount'), 0);
  }

  сouponAdjustments() {
    return this.order_items
      .flatMap(i => i.applied_adjustments)
      .filter(a => a.get('type') === 'Coupon');
  }

  couponAdjustmentWithPreSale() {
    return this.couponAdjustment() + this.recurringPreSaleCouponAdjustment();
  }

  recurringCoupon() {
    const adjustment = this.recurringMemberships()
      .flatMap(m => m.applied_adjustments)
      .filter(a => a.isCoupon() && a.details.isRecurring())
      .first();

    return adjustment ? adjustment.details : null;
  }

  recurringPreSaleCouponAdjustment() {
    const coupon = this.recurringCoupon();

    if (!coupon) {
      return 0;
    }

    return -this.recurringMemberships()
      .filter(m => m.orderable.isPreSale())
      .map(
        m =>
          coupon.discount.discountFor(m.orderable.subscribable_base_price) *
          m.orderable.customer_user_ids.size
      )
      .reduce((sum, discount) => sum + discount, 0);
  }

  /**
   * Returns a Map mapping customer user ids to the name and ids of memberships
   * they are associated to in this order.
   *
   * @return {string} Map of the form cu_id => [membership_id, membership_name]
   */
  customerUserMembershipMap() {
    return Map().withMutations(membershipMap => {
      this.order_items
        .filter(oi => oi.isMembershipItem())
        .map(oi => oi.orderable)
        .forEach(msp =>
          msp.customer_user_ids.forEach(id =>
            membershipMap.set(id, [msp.membership_id, msp.subscribable_name])
          )
        );
    });
  }

  /**
   * Returns a Map mapping customer user ids to the name and ids of credit_passes
   * they are associated to in this order.
   *
   * @return {Map} Map of the form cu_id => [credit_pass_id, credit_pass_name]
   */
  customerUserCreditPassMap() {
    return Map().withMutations(creditPassMap => {
      this.order_items
        .filter(oi => oi.isCreditPassItem())
        .map(oi => oi.orderable)
        .forEach(pkg => creditPassMap.set(pkg.client_id, pkg.credit_pass_id));
    });
  }

  isCouponEligible() {
    return this.order_items.some(oi =>
      ['registration_package'].includes(oi.orderable_type)
    );
  }

  hasMemberships() {
    return this.order_items.some(i => i.isMembershipItem());
  }

  hasRetailItem() {
    return this.order_items.some(i => i.isRetailItem());
  }

  hasRecurringMembership() {
    return this.recurringMemberships().count() > 0;
  }

  hasPaymentPlans() {
    return this.due_immediately !== this.total;
  }

  hasFutureBillableItem() {
    return this.hasRecurringMembership() || this.hasPaymentPlans();
  }

  hasPreSaleItem() {
    return this.order_items.some(
      i => i.isMembershipItem() && i.orderable.isPreSale()
    );
  }

  couponSingleTimeAndMembership() {
    return (
      this.hasMemberships() &&
      this.coupon !== null &&
      this.coupon?.isOneTime100Percent()
    );
  }

  chargeInBE() {
    return (
      this.hasMemberships() && currentCustomer().charge_membership_cart_in_be
    );
  }

  chargedNow() {
    return this.chargeInBE() ? 0 : this.total_due_at_checkout;
  }

  billedLaterToday() {
    if (this.chargeInBE()) {
      return this.due_immediately;
    }
    return this.due_immediately - this.total_due_at_checkout;
  }

  recurringMemberships() {
    return this.order_items.filter(i => i.isRecurringMembershipItem());
  }

  updateSensitiveFields() {
    return {
      coupon_code: this.coupon && this.coupon.get('code'),
      tax_exempt: this.tax_exempt,
      tax_id: this.tax_id,
    };
  }

  updateSensitiveFieldsFee() {
    return {
      coupon_code: this.coupon?.get('code'),
      fee_exempt: this.fee_exempt,
    };
  }
}

export default Order;
