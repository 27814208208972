import * as React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Spinner } from '@upperhand/playmaker';

import Header from './Header.jsx';
import EditMenu from './EditMenu.jsx';

import SessionTabs from './SessionTabs.jsx';
import EditDateTime from './editDateTime/EditDateTime.jsx';

const styles = {
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  hide: {
    display: 'none',
  },
  spinner: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
};

function Content({
  total,
  eventId,
  intl,
  selectedTab,
  isEditMode,
  isLoading,
  sessionId,
  editMenu,
  allowCancel,
  onNextSession,
  onPreviousSession,
  event,
}) {
  const isTeamEvent = event?.isTeamEvent();
  return (
    <>
      {isLoading && (
        <div style={styles.spinner}>
          <Spinner type="indeterminate" />
        </div>
      )}
      {!isLoading && (
        <div style={styles.content}>
          <EditMenu
            intl={intl}
            anchorEl={editMenu.get('anchorEl')}
            allowCancel={allowCancel}
            sessionId={sessionId}
          />
          <Header
            sessionId={sessionId}
            isEditMode={isEditMode}
            onNextSession={onNextSession}
            onPreviousSession={onPreviousSession}
            event={event}
          />
          {isEditMode ? (
            <EditDateTime
              intl={intl}
              sessionId={sessionId}
              isTeamEvent={isTeamEvent}
            />
          ) : (
            <SessionTabs
              total={total}
              intl={intl}
              sessionId={sessionId}
              eventId={eventId}
              selectedTab={selectedTab}
              isTeamEvent={isTeamEvent}
            />
          )}
        </div>
      )}
    </>
  );
}

Content.propTypes = {
  editMenu: PropTypes.instanceOf(Map).isRequired,
  eventId: PropTypes.number,
  intl: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedTab: PropTypes.number.isRequired,
  sessionId: PropTypes.string,
};

Content.defaultProps = {
  eventId: null,
  sessionId: null,
};

export default Content;
