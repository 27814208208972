import React, { memo, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { TextField, Dropdown, Typography } from '@upperhand/playmaker';

import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';

import { t } from 'shared/utils/LocaleUtils';
import { compose } from 'shared/utils/SharedUtils';

const getTimeFrame = (schedulingFrame, returnUnits = false) => {
  if (schedulingFrame && schedulingFrame % 30 === 0) {
    return returnUnits ? 'month' : schedulingFrame / 30;
  }

  if (schedulingFrame && schedulingFrame % 7 === 0) {
    return returnUnits ? 'week' : schedulingFrame / 7;
  }

  return returnUnits ? 'day' : schedulingFrame;
};

function SchedulingTimeFrame({ intl, schedule, handleScheduleChange }) {
  const { scheduling_timeframe: schedulingTimeFrame } = schedule;

  const handleToggle = e => {
    handleScheduleChange(['scheduling_timeframe'], e.target.checked ? 1 : null);
  };

  const timeFrameValue = getTimeFrame(schedulingTimeFrame);
  const timeFrameUnits = getTimeFrame(schedulingTimeFrame, true);

  const updateTimeFrame = useCallback(
    (timeFrame, units = timeFrameUnits) => {
      if (timeFrame === '' || timeFrame === '0') {
        handleScheduleChange(['scheduling_timeframe'], null);
      } else {
        switch (units) {
          case 'month':
            handleScheduleChange(['scheduling_timeframe'], timeFrame * 30);
            break;
          case 'week':
            handleScheduleChange(['scheduling_timeframe'], timeFrame * 7);
            break;
          default:
            handleScheduleChange(['scheduling_timeframe'], timeFrame);
        }
      }
    },
    [handleScheduleChange, timeFrameUnits]
  );

  const handleTimeframe = (_, value) => {
    updateTimeFrame(value || 1);
  };

  return (
    <Stack className="scheduling-timeframe">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="fieldLabel"
          className="scheduling-timeframe__label"
        >
          {t('.label', intl, __filenamespace)}
        </Typography>
        <Checkbox checked={!!schedulingTimeFrame} onChange={handleToggle} />
      </Stack>
      {!schedulingTimeFrame && (
        <Typography className="scheduling-timeframe__explanation">
          {t('.explanation', intl, __filenamespace)}
        </Typography>
      )}
      {schedulingTimeFrame && (
        <Stack
          spacing={0.5}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography className="scheduling-timeframe__from-label">
            {t('.from_label', intl, __filenamespace)}
          </Typography>
          <TextField
            name="deadline"
            type="number"
            value={timeFrameValue}
            classes={{ root: 'scheduling-timeframe__frame-input' }}
            onChange={handleTimeframe}
          />
          <Dropdown
            classes={{ select: 'scheduling-timeframe__units-select' }}
            value={timeFrameUnits}
            onChange={e => updateTimeFrame(timeFrameValue, e.target.value)}
            items={[
              {
                value: 'day',
                label: t('.day', intl, __filenamespace, {
                  n: timeFrameValue,
                }),
              },
              {
                value: 'week',
                label: t('.week', intl, __filenamespace, {
                  n: timeFrameValue,
                }),
              },
              {
                value: 'month',
                label: t('.month', intl, __filenamespace, {
                  n: timeFrameValue,
                }),
              },
            ]}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default compose(memo, injectIntl)(SchedulingTimeFrame);
