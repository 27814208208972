import * as React from 'react';
import PropTypes from 'prop-types';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';

import PaymentActions from '../../Actions';
import PaysafeCardForm from './PaysafeCardForm.jsx';
import StripeCardForm from './StripeCardForm.jsx';
import { PaymentCard } from '../../records';

function NewCardForm({ cardRecord, onChange, style }) {
  const handleChange = (e, value, name) => {
    if (onChange) {
      onChange(e.target.name || name, value);
    } else {
      PaymentActions.updateNewCardForm(e.target.name || name, value);
    }
  };

  if (currentCustomer().use_paysafe) {
    return (
      <PaysafeCardForm
        card={cardRecord}
        onChange={handleChange}
        style={style}
      />
    );
  }

  if (currentCustomer().use_stripe) {
    return (
      <StripeCardForm card={cardRecord} onChange={handleChange} style={style} />
    );
  }

  throw new Error('Unknown gateway');
}

NewCardForm.propTypes = {
  cardRecord: PropTypes.instanceOf(PaymentCard),
  onChange: PropTypes.func,
};

NewCardForm.defaultProps = {
  cardRecord: {},
  onChange: undefined,
};

export default NewCardForm;
