// TODO rename athletes -> managed_customer_users
import { List, Set } from 'immutable';
import AthleteActions from 'event_mgmt/shared/actions/AthleteActions.jsx';
import Client from 'shared/records/Client.jsx';
import ClientActions from 'shared/actions/ClientActions.jsx';
import CreditGrantsActions from 'shared/actions/CreditGrantsActions';
import EventDisplayActions from 'event_mgmt/display/actions/EventDisplayActions.jsx';
import ManagedClientActions from 'shared/actions/ManagedClientActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

class AthleteStore extends UpperHandStore {
  constructor() {
    super();

    this.allAthletes = List(); // for listing
    this.isLoading = false;
    this.selectedAthleteId = null;
    this.findById = id => this.allAthletes.find(client => client.id === id);

    this.bindListeners({
      handleClientEventDisplayMounted: EventDisplayActions.clientDisplayMounted,

      handleList: AthleteActions.list,
      handleListSuccess: AthleteActions.listSuccess,
      handleListError: AthleteActions.listError,

      handleCreateSuccess: [
        ClientActions.createSuccess,
        ManagedClientActions.createSuccess,
      ],
      handleUpdateSuccess: ClientActions.updateSuccess,
      handleSelectAthlete: AthleteActions.selectAthlete,
      handleAgreementSignedSuccess: AthleteActions.onAgreementSignedSuccess,
    });
  }

  handleClientEventDisplayMounted() {
    if (!isLoggedIn()) {
      return;
    }

    this.handleList({ fields: ['active_membership_id'] });
  }

  handleList(opts) {
    const options = opts || { fields: [] };

    options.fields = options.fields
      ? [...options.fields, 'agreement_details']
      : ['agreement_details'];
    this.isLoading = true;

    return uhApiClient.get({
      url: '/athletes',
      data: merge({ per_page: 100 }, options),
      success: AthleteActions.listSuccess,
      error: AthleteActions.listError,
    });
  }

  handleListSuccess(data) {
    this.isLoading = false;

    this.allAthletes = this.sorted(
      List(data.athletes.map(object => new Client(object)))
    );
    const allAthletesIds = this.allAthletes.reduce(
      (set, credit) => set.add(credit.id),
      Set()
    );

    if (allAthletesIds.size) {
      CreditGrantsActions.list.defer({
        clientIds: allAthletesIds,
      });
    }
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing client', args);
  }

  handleCreateSuccess(data) {
    if (data.managed_by_id === currentUser().id) {
      this.allAthletes = this.sorted(this.allAthletes.push(new Client(data)));
      this.handleSelectAthlete(data.id);
    }
  }

  handleUpdateSuccess(data) {
    if (data.managed_by_id === currentUser().id) {
      const newAthlete = new Client(data);
      const i = this.allAthletes.findKey(
        athlete => athlete.id === newAthlete.id
      );

      if (i >= 0) {
        this.allAthletes = this.allAthletes.set(i, newAthlete);
      } else {
        this.allAthletes = this.allAthletes.push(newAthlete);
      }
      this.allAthletes = this.sorted(this.allAthletes);
    }
  }

  handleSelectAthlete(id) {
    this.selectedAthleteId = id;
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(list) {
    return list.sortBy(client => client.name());
  }

  handleAgreementSignedSuccess(data) {
    data.forEach(updatedData => {
      const index = this.allAthletes.findIndex(
        client => client.get('id') === updatedData.client_id
      );

      if (index !== -1) {
        const client = this.allAthletes.get(index).set('agreement_details', {
          agreement: updatedData,
          agreement_updated_at: currentCustomer().agreement_updated_at,
        });

        this.allAthletes = this.allAthletes.set(index, new Client(client));
      }
    });
  }
}

export default alt.createStore(AthleteStore, 'AthleteStore');
