import React from 'react';
import moment from 'moment-timezone';
import { Box } from '@mui/material';
import { Confirmation, Typography } from '@upperhand/playmaker';
import { FormattedMessage, injectIntl } from 'react-intl';

import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import StripedList from 'shared/components/StripedList.jsx';

import altContainer from 'shared/hocs/altContainer.jsx';
import { boldText, uhColors } from 'shared/styles/uhStyles.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { compose } from 'shared/utils/SharedUtils.js';
import { customerTZ } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

import {
  ClientDataStore,
  PaymentPlanDataStore,
  ScheduledPaymentDataStore,
} from 'dataStores';
import PaymentPlanDetailsDrawerActions from 'containers/reports/paymentPlanDetailsDrawer/Actions';
import PaymentPlanDetailsDrawerStore from 'containers/reports/paymentPlanDetailsDrawer/Store';

const styles = {
  container: {
    marginBottom: '20px',
    lineHeight: '22px',
  },
  reasonContainer: {
    borderTop: '1px',
    borderTopStyle: 'solid',
    borderTopColor: uhColors.lighterGrey,
  },
  warning: {
    color: uhColors.warning,
  },
  paymentItemStyle: {
    padding: '6px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  planListStyle: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
};

function DiscountedAmount({ payment }) {
  return (
    <Box>
      <Box component="span" sx={{ marginRight: '5px' }}>
        <FormattedCurrency value={payment.get('amount')} fromCents />
      </Box>
      <Box component="span" sx={{ textDecoration: 'line-through' }}>
        <FormattedCurrency value={payment.get('original_amount')} fromCents />
      </Box>
    </Box>
  );
}

function PaymentItem({ payment }) {
  return (
    <Box sx={styles.paymentItemStyle}>
      <Box>{moment(payment.get('dueDate')).format('MMM DD, YYYY')}</Box>
      {payment.get('discount') > 0 ? (
        <DiscountedAmount payment={payment} />
      ) : (
        <FormattedCurrency value={payment.get('amount')} fromCents />
      )}
    </Box>
  );
}

function PaymentPlanList({ scheduledPayments }) {
  return (
    <Box sx={{ width: '160px', marginBottom: '1rem' }}>
      <Typography variant="subtitle1">
        <FormattedMessage
          id={messageId('.plans_installments', __filenamespace)}
          values={{
            count: scheduledPayments.size,
          }}
        />
      </Typography>
      <StripedList>
        {scheduledPayments
          .sortBy(p => p.get('date'))
          .map(p => <PaymentItem key={p.id} payment={p} />)
          .toList()}
      </StripedList>
    </Box>
  );
}

function CancellationDialog({
  clientDataStore: { clients },
  paymentPlanDataStore: { records },
  paymentPlanDetailsDrawerStore: {
    isCancelAllPaymentsModalOpen,
    paymentPlanId,
    scheduledPaymentIds,
  },
  scheduledPaymentDataStore: { scheduledPayments: scheduledPaymentsData },
  intl,
}) {
  const paymentPlan = records.get(paymentPlanId);
  const client = clients.get(paymentPlan?.clientId);
  const clientName = client?.name();
  const scheduledPayments = scheduledPaymentIds
    .map(id => scheduledPaymentsData.get(id))
    .filter(pmt => !(pmt.isCancelled || pmt.isCompleted));
  const handleCancelAllPayments = () => {
    const params = scheduledPayments.map(payment => ({
      id: payment.id,
      payment_status: 'cancelled',
      due_date: moment.tz(payment.dueDate, customerTZ()),
    }));
    PaymentPlanDetailsDrawerActions.updateScheduledPayments({
      scheduled_payments: params,
    });
  };
  return (
    <Confirmation
      title={
        <FormattedMessage
          id={messageId('.cancel_all_payments', __filenamespace)}
        />
      }
      open={isCancelAllPaymentsModalOpen}
      level="warning"
      hasCheckbox={false}
      confirmButtonLabel={t('.confirm_cancellation', intl, __filenamespace)}
      cancelButtonLabel={t('.discard_cancellation', intl, __filenamespace)}
      onCancel={PaymentPlanDetailsDrawerActions.toggleCancelAllPayments}
      onConfirm={handleCancelAllPayments}
    >
      <Box sx={styles.container}>
        <FormattedMessage
          id={messageId('.are_you_sure', __filenamespace)}
          values={{
            clientName: (
              <Box component="span" sx={boldText}>
                {clientName}
              </Box>
            ),
          }}
        />
      </Box>
      <PaymentPlanList scheduledPayments={scheduledPayments} />
    </Confirmation>
  );
}

export default compose(
  injectIntl,
  altContainer({
    stores: {
      paymentPlanDetailsDrawerStore: PaymentPlanDetailsDrawerStore,
      clientDataStore: ClientDataStore,
      paymentPlanDataStore: PaymentPlanDataStore,
      scheduledPaymentDataStore: ScheduledPaymentDataStore,
    },
  })
)(CancellationDialog);
