import * as React from 'react';
import moment from 'moment-timezone';
import { List } from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Grid, Typography } from '@upperhand/playmaker';
import DoneIcon from '@mui/icons-material/Done';

import { messageId } from 'shared/utils/LocaleUtils.js';
import altContainer from 'shared/hocs/altContainer.jsx';

import { EventDataStore, SessionDataStore, StaffDataStore } from 'dataStores';

import Staff from 'shared/records/Staff.jsx';

import SelectableContainer from './SelectableContainer.jsx';

const styles = {
  avatar: {
    margin: '0 0.75em',
  },
  textContainer: {
    flexGrow: 1,
    paddingRight: '0.75em',
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    height: 18,
    width: 18,
    color: 'var(--color-primary)',
  },
};

function SessionState({
  isCancelled,
  isFreeSpots,
  ownsSession,
  registrationClosed,
}) {
  if (isCancelled) {
    return (
      <Grid item>
        <Typography variant="body2" noWrap>
          <FormattedMessage id={messageId('.cancelled', __filenamespace)} />
        </Typography>
      </Grid>
    );
  }
  if (ownsSession) {
    return (
      <Grid item>
        <DoneIcon style={styles.icon} />
      </Grid>
    );
  }
  if (!ownsSession && !isFreeSpots) {
    return (
      <Grid item>
        <Typography variant="body2" noWrap>
          <FormattedMessage id={messageId('.no_spots', __filenamespace)} />
        </Typography>
      </Grid>
    );
  }
  if (registrationClosed) {
    return (
      <Grid item>
        <Typography variant="body2" noWrap>
          <FormattedMessage
            id={messageId('.registration_closed', __filenamespace)}
          />
        </Typography>
      </Grid>
    );
  }

  return null;
}

const SessionCard = injectIntl(
  ({
    clientId,
    onSelect,
    selectedSessions,
    sessionDataStore: { sessions },
    sessionId,
    staffDataStore: { staff },
    eventDataStore: { events },
  }) => {
    const session = sessions.get(sessionId);
    const event = events.get(session.event_id);
    const eventSchedules = event.get('schedules', List());
    const schedule = eventSchedules.find(s => s.id === session.schedule_id);
    const location = schedule ? schedule.location : null;
    const locationName = location ? location.name : '';

    if (!session || session.hasNoStaff()) {
      return null;
    }

    const staffCount = session.staff_ids.size;
    const staffItem = staff.get(session.staff_ids.first(), new Staff());
    const staffName = staffItem.id ? staffItem.name() : '';

    const times = session.starts_at
      .format('h:mma')
      .concat(' - ', session.ends_at.format('h:mma'));

    const ownsSession = session.client_ids.includes(clientId);
    const isFreeSpots = session.spots_remaining > 0;
    const isCancelled = session.isCancelled();

    const registrationClosed = session.scheduling_deadline
      ? moment()
          .add(session.scheduling_deadline, 'seconds')
          .isSameOrAfter(session.starts_at)
      : false;

    return (
      <SelectableContainer
        selected={isFreeSpots && selectedSessions.includes(sessionId)}
        disabled={
          ownsSession || !isFreeSpots || isCancelled || registrationClosed
        }
        onClick={() => onSelect(sessionId)}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="button2" noWrap>
              {times}
            </Typography>
          </Grid>
          <Grid item container justify="space-between" direction="column">
            {locationName && (
              <Typography variant="body2" noWrap>
                {locationName}
              </Typography>
            )}
            {/* Purposefully outside of a Grid item so that we get the ellipsis on long names. */}
            <Typography variant="body2" noWrap>
              {staffCount === 1 ? (
                staffName
              ) : (
                <FormattedMessage
                  id={messageId('.n_staff', __filenamespace)}
                  values={{ n: staffCount.toString() }}
                />
              )}
            </Typography>
            <SessionState
              isCancelled={isCancelled}
              ownsSession={ownsSession}
              isFreeSpots={isFreeSpots}
              registrationClosed={registrationClosed}
            />
          </Grid>
        </Grid>
      </SelectableContainer>
    );
  }
);

export default altContainer({
  stores: {
    sessionDataStore: SessionDataStore,
    eventDataStore: EventDataStore,
    staffDataStore: StaffDataStore,
  },
})(SessionCard);
