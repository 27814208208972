import { Map, Record, Set } from 'immutable';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import { merge, isPresent } from 'shared/utils/ObjectUtils.jsx';

const ContactFilterDefaults = {
  id: null,
  name: null,
  min_age: null,
  max_age: null,
  subscription_statuses: Set(),
  account_statuses: Set(),
  account_type: null,
  gender: null,
  zip_code: null,
  date_of_birth: Map(),
  month_of_birth: Set(),
  first_purchase_date: Map(),
  last_purchase_date: Map(),
  purchased_products: Map(),
  contact_group_id: null,
  errors: new FieldErrors(),
};

class ContactFilter extends Record(ContactFilterDefaults) {
  constructor(obj = {}) {
    super(
      merge(obj, {
        subscription_statuses: Set(obj.subscription_statuses),
        account_statuses: Set(obj.account_statuses),
        date_of_birth: Map(obj.date_of_birth),
        month_of_birth: Set(obj.month_of_birth),
        first_purchase_date: Map(obj.first_purchase_date),
        last_purchase_date: Map(obj.last_purchase_date),
        purchased_products: Map(obj.purchased_products),
      })
    );
  }

  validate() {
    let errors = new FieldErrors();

    const checkDateField = field => {
      const {
        start_date: startDate,
        end_date: endDate,
        operator,
      } = this.getIn([field], Map()).toJS();
      const isBetween = operator === 'between';
      const startDatePresent = isPresent(startDate);
      const endDatePresent = isPresent(endDate);

      if (operator && (!startDatePresent || (isBetween && !endDatePresent))) {
        if (!startDatePresent) {
          errors = errors.add([field, 'start_date'], 'records.errors.required');
        }
        if (isBetween && !endDatePresent) {
          errors = errors.add([field, 'end_date'], 'records.errors.required');
        }
      }
    };

    ['date_of_birth', 'first_purchase_date', 'last_purchase_date'].forEach(
      checkDateField
    );

    if (!this.name) {
      errors = errors.add('name', 'records.errors.required');
    }

    return this.set('errors', errors);
  }

  isValid() {
    return this.errors.isEmpty();
  }
}

export default ContactFilter;
