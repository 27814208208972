import { Map } from 'immutable';

// eslint-disable-next-line import/no-cycle
import { UpperHandStore } from 'dataStores';
import { RegistrationActions } from 'sources';

class RegistrationDataStore extends UpperHandStore {
  constructor() {
    super();

    this.registrations = Map();

    this.bindListeners({
      listSuccess: [RegistrationActions.listSuccess],
      listError: [RegistrationActions.listError],
      rescheduleSuccess: [RegistrationActions.rescheduleSuccess],
      rescheduleError: [RegistrationActions.rescheduleError],
    });
  }

  listSuccess({ registrations }) {
    const fetchedRegistrations = registrations
      .groupBy(t => t.id)
      .map(t => t.first());

    this.registrations = this.registrations.merge(fetchedRegistrations);
  }

  listError(...args) {
    this.notifyError('error while listing registrations', args);
  }

  rescheduleSuccess(registration) {
    this.registrations = this.registrations.set(registration.id, registration);
  }

  rescheduleError(...args) {
    this.notifyError('error while rescheduling session', args);
  }
}

export default alt.createStore(RegistrationDataStore, 'RegistrationDataStore');
