import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import { PaymentMethodSource } from 'sources';
import { UpperHandStore } from 'dataStores';

import { PaymentMethodManagementService } from 'containers/payments/services';
import { PaymentCard, PaymentAch } from 'containers/payments/records';
import {
  PaymentMethodManagementServiceResponse,
  PaymentMethod,
} from 'containers/payments/types';
import PaymentMethodModalActions from './Actions';

class PaymentMethodModalStore extends UpperHandStore {
  constructor() {
    super();

    this.isSaving = true;
    this.isOpen = false;
    this.userId = null;
    this.onSuccess = null;
    this.newPaymentMethod = new PaymentCard();

    this.bindListeners({
      mounted: PaymentMethodModalActions.mounted,

      updatePaymentMethodRecord:
        PaymentMethodModalActions.updatePaymentMethodRecord,

      createPaymentMethod: PaymentMethodModalActions.createPaymentMethod,
      paymentMethodResponse: PaymentMethodModalActions.paymentMethodResponse,
      createPaymentMethodSuccess:
        PaymentMethodModalActions.createPaymentMethodSuccess,
      createPaymentMethodError:
        PaymentMethodModalActions.createPaymentMethodError,

      openModal: PaymentMethodModalActions.openModal,
      closeModal: PaymentMethodModalActions.closeModal,

      changePaymentMethodType:
        PaymentMethodModalActions.changePaymentMethodType,
    });
  }

  mounted([userId, onSuccess]) {
    this.userId = userId;
    this.onSuccess = onSuccess;
  }

  openModal() {
    this.isSaving = false;
    this.isOpen = true;
    this.newPaymentMethod = new PaymentCard();
  }

  closeModal() {
    this.isOpen = false;
  }

  changePaymentMethodType(paymentMethodType) {
    if (paymentMethodType === PaymentMethod.CARD_NEW) {
      this.newPaymentMethod = new PaymentCard();
    }

    if (paymentMethodType === PaymentMethod.ACH) {
      this.newPaymentMethod = new PaymentAch();
    }
  }

  updatePaymentMethodRecord([key, value]) {
    this.newPaymentMethod = this.newPaymentMethod.set(key, value);

    if (this.newPaymentMethod.errors.getErrors(key).length) {
      this.newPaymentMethod = this.newPaymentMethod.validate();
    }
  }

  createPaymentMethod() {
    this.newPaymentMethod = this.newPaymentMethod.validate();

    if (!this.newPaymentMethod.isValid() || this.isSaving) return;

    if (this.newPaymentMethod instanceof PaymentAch) {
      PaymentMethodManagementService.createAch({
        userId: this.userId,
        achRecord: this.newPaymentMethod,
        response: PaymentMethodModalActions.paymentMethodResponse.defer,
      });
    } else {
      PaymentMethodManagementService.createCard({
        userId: this.userId,
        cardRecord: this.newPaymentMethod,
        response: PaymentMethodModalActions.paymentMethodResponse.defer,
      });
    }
  }

  paymentMethodResponse([type, response, payload = {}]) {
    switch (type) {
      case PaymentMethodManagementServiceResponse.CREATE_SUCCESS:
        if (!payload.ach_bank_account) {
          PaymentMethodSource.create({
            userId: this.userId,
            params: {
              card_token_id: payload.card_token_id,
              default: false,
            },
            success: PaymentMethodModalActions.createPaymentMethodSuccess,
            error: PaymentMethodModalActions.createPaymentMethodError,
          });
        } else {
          this.createPaymentMethodSuccess();
        }
        break;

      case PaymentMethodManagementServiceResponse.CREATE_ERROR:
        this.isSaving = false;
        MessageWindowActions.addMessage.defer(response);
        break;

      case PaymentMethodManagementServiceResponse.TOKEN_ERROR:
        this.isSaving = false;
        MessageWindowActions.addMessage.defer(response);
        break;

      case PaymentMethodManagementServiceResponse.TOKEN_SUCCESS:
        this.isSaving = true;
        break;

      default:
        break;
    }
  }

  createPaymentMethodSuccess() {
    this.onSuccess();
    this.isOpen = false;
    this.isSaving = false;
  }

  createPaymentMethodError() {
    this.isSaving = false;
  }
}

export default alt.createStore(
  PaymentMethodModalStore,
  'PaymentMethodModalStore'
);
