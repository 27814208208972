import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import Session from 'shared/records/Session';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import { SessionDataStore } from 'dataStores';

import { SessionSource } from 'sources';

import NotesActions from './actions';

class NotesStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      mounted: NotesActions.mounted,
      toggleEditMode: NotesActions.toggleEditMode,
      handleFieldChange: NotesActions.handleFieldChange,
      updateNote: NotesActions.updateNote,
      updateNoteSuccess: NotesActions.updateNoteSuccess,
      updateNoteError: NotesActions.updateNoteError,
    });
  }

  reset() {
    this.sessionId = null;
    this.sessionNote = null;
    this.editMode = false;
    this.dirty = false;
  }

  setSessionNote() {
    this.waitFor(SessionDataStore);

    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(this.sessionId, new Session());

    this.sessionNote = session.get('note');
  }

  mounted(sessionId) {
    this.reset();
    this.sessionId = sessionId;
    this.setSessionNote();
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    this.setSessionNote();
  }

  handleFieldChange({ field, value }) {
    this.dirty = true;
    this[field] = value;
  }

  updateNote() {
    this.waitFor(SessionDataStore);

    const { sessions } = SessionDataStore.getState();
    const session = sessions.get(this.sessionId, new Session());
    const params = {
      fields: ['note'],
      attributes: session.merge({ note: this.sessionNote }),
    };

    SessionSource.put({
      id: this.sessionId,
      params,
      success: NotesActions.updateNoteSuccess,
      error: NotesActions.updateNoteError,
    });
  }

  updateNoteSuccess() {
    this.toggleEditMode();
    MessageWindowActions.addMessage.defer(
      new TranslatableMessage({
        id: '.note_added',
        filename: __filenamespace,
      })
    );
  }

  updateNoteError(...args) {
    this.notifyError('error updating session note', args);
    this.toggleEditMode();
  }
}

export default alt.createStore(NotesStore, 'NotesStore');
