import * as React from 'react';
import { Card, Grid, Typography } from '@upperhand/playmaker';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import history from 'routes/History.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

import { formatClientTime } from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';

function DesktopView({
  startAt,
  endAt,
  eventTitle,
  eventTypeName,
  isPaid,
  isNoShow,
  scheduleType,
  intl,
}) {
  return (
    <>
      <Grid item className="schedule__row-item" xs={2}>
        <Typography variant="subtitle2" display="inline">
          {scheduleType}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={3}>
        <Typography variant="subtitle1">
          {startAt ? startAt.format('ddd, MMM D') : '-'}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={3}>
        <Typography variant="body1">
          {formatClientTime(startAt, 'h:mma')}
          &ensp;-&ensp;
          {formatClientTime(endAt, 'h:mma')}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={2}>
        <Typography variant="subtitle1" className="event-title">
          {eventTitle}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        className={clsx(
          'schedule__row-item',
          (!isPaid || isNoShow) && 'schedule__event-item-unpaid'
        )}
      >
        <Typography variant="body1" className="event-type">
          {eventTypeName}
        </Typography>
        <Grid item xs>
          {!isPaid && (
            <Typography variant="body2" className="unpaid-label">
              {t('.unpaid', intl, __filenamespace)}
            </Typography>
          )}
          {isNoShow && (
            <Typography variant="body2" className="noShow-label">
              {t('.is_no_show', intl, __filenamespace)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
}

function MobileView({
  startAt,
  endAt,
  eventTitle,
  eventTypeName,
  isPaid,
  isNoShow,
  scheduleType,
  intl,
}) {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle2" display="inline">
          {scheduleType}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={6}>
        <Typography variant="subtitle1">
          {startAt ? startAt.format('ddd, MMM D') : '-'}
        </Typography>
        <Typography variant="body1">
          {formatClientTime(startAt, 'h:mma')}
          &ensp;-&ensp;
          {formatClientTime(endAt, 'h:mma')}
        </Typography>
      </Grid>
      <Grid item className="schedule__row-item" xs={6}>
        <Typography variant="subtitle1" className="event-title">
          {eventTitle}
        </Typography>
        <Grid
          item
          xs="auto"
          className={clsx(
            'schedule__row-item',
            (!isPaid || isNoShow) && 'schedule__event-item-unpaid'
          )}
        >
          <Typography variant="body1" className="event-type">
            {eventTypeName}
          </Typography>
          <Grid item xs={12}>
            {!isPaid && (
              <Typography variant="body2" className="unpaid-label">
                {t('.unpaid', intl, __filenamespace)}
              </Typography>
            )}
            {isNoShow && (
              <Typography variant="body2" className="noShow-label">
                {t('.is_no_show', intl, __filenamespace)}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

function ScheduleListItem({
  actions,
  registrationId,
  intl,
  registrations,
  events,
  sessions,
  isMobile,
  onSessionSummaryOpen,
  onRescheduleOpen,
}) {
  const registration = registrations.get(registrationId);
  const eventId = registration.get('event_id');
  const sessionId = registration.get('session_id');
  const orderId = registration.get('order_id');
  const orderItemId = registration.get('order_item_id');
  const event = events.get(eventId);
  const session = sessions.get(sessionId);
  const eventType = event?.get('event_type');
  const isFixedSchedule = event?.isFixedSchedule();
  const isOpenBooking = event?.isOpenBooking();
  const isTeamEvent = event?.isTeamSchedule();
  const eventSchedules = event?.getSchedule();
  const cancellationDeadline =
    session?.get('cancellation_deadline') ||
    eventSchedules?.get('cancellation_deadline');
  const eventTypeName = isTeamEvent
    ? event.team_type.name
    : eventType?.get('name');
  const eventTypeColor = isTeamEvent
    ? event.team_type.color
    : eventType?.get('color');
  const startAt = session?.get('starts_at');
  const endAt = session?.get('ends_at');
  const isPaid = registration.get('paid');
  const isStaff = currentUser().isStaff();
  const isClient = currentUser().isClient();
  const isNoShow = session?.attendance_map
    .get('no_show')
    .includes(registration?.client_id);
  const isManagedBy = currentUser().managed_by_id;
  const isReschedule = typeof onRescheduleOpen === 'function';
  const schedules = event?.schedules;
  const scheduleId = session?.get('schedule_id');
  const schedule = schedules?.find(sh => sh.id === scheduleId);
  const scheduleType = isTeamEvent
    ? schedule?.label
    : event?.getScheduleTypeLabel();

  const isDeadlineReached = () =>
    !!cancellationDeadline &&
    moment().add(cancellationDeadline, 'seconds').isSameOrAfter(startAt);

  const isCancellable = () =>
    !isFixedSchedule && moment(startAt).isAfter() && !isDeadlineReached();

  const showCancel = () => {
    if (isTeamEvent && isClient) {
      return false;
    }
    return isStaff ? moment(startAt).isAfter() : isCancellable();
  };

  const menuItems = [
    {
      onClick: () => actions.openViewBalanceDrawer(null, [], orderItemId),
      label: t('.pay_balance', intl, __filenamespace),
      isAvailable: (isStaff || !isManagedBy) && !isPaid && orderItemId,
    },
    {
      onClick: () => actions.openOrderDetailsDrawer(orderId),
      label: t('.order_details', intl, __filenamespace),
      isAvailable: isStaff || !isManagedBy,
    },
    {
      onClick: () =>
        onRescheduleOpen({
          session,
          registration,
        }),
      label: t('.reschedule', intl, __filenamespace),
      isAvailable: isReschedule && isOpenBooking && isClient,
    },
    {
      onClick: () => {
        if (typeof onSessionSummaryOpen === 'function') {
          onSessionSummaryOpen({ sessionId, eventId });
        }

        actions.openSessionSummaryDrawer({ sessionId, eventId });
      },
      label: t('.session_summary', intl, __filenamespace),
      isAvailable: isStaff,
    },
    {
      onClick: () => actions.setRegistrationToRemove({ registration, event }),
      label: t('.cancel_registration', intl, __filenamespace),
      isAvailable: showCancel(),
    },
  ].filter(item => item.isAvailable);

  return (
    <Card
      borderColor={eventTypeColor}
      borderPosition="left"
      contentDirection="row"
      classes={{
        root: 'schedule__row',
        content: 'schedule__row-content',
      }}
      menuOptions={menuItems.length ? menuItems : undefined}
      onClick={() => history.push(event.url())}
    >
      <Grid container spacing={1} alignContent="center" justify="center">
        {!isMobile && (
          <DesktopView
            intl={intl}
            endAt={endAt}
            startAt={startAt}
            isPaid={isPaid}
            isNoShow={isNoShow}
            eventTypeName={eventTypeName}
            eventTitle={event?.title}
            scheduleType={scheduleType}
          />
        )}
        {isMobile && (
          <MobileView
            intl={intl}
            endAt={endAt}
            isNoShow={isNoShow}
            startAt={startAt}
            isPaid={isPaid}
            eventTypeName={eventTypeName}
            eventTitle={event?.title}
            scheduleType={scheduleType}
          />
        )}
      </Grid>
    </Card>
  );
}

ScheduleListItem.propTypes = {
  registrations: PropTypes.instanceOf(Map).isRequired,
  events: PropTypes.instanceOf(Map).isRequired,
  sessions: PropTypes.instanceOf(Map).isRequired,
  isMobile: PropTypes.bool,
};

ScheduleListItem.defaultProps = {
  isMobile: false,
};

export default React.memo(ScheduleListItem);
