import { List, Map } from 'immutable';
import CartStore from 'event_mgmt/shared/stores/CartStore.jsx';
import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import CreditPass from 'shared/records/CreditPass.js';
import CreditPassPackage from 'shared/records/CreditPassPackage.js';
import CreditPassPurchasingActions from 'credit_passes/actions/CreditPassPurchasingActions.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import CurrentContextStore from 'shared/stores/CurrentContextStore.jsx';

class CreditPassPurchasingStore extends UpperHandStore {
  constructor() {
    super();

    this.creditPass = new CreditPass();
    this.creditPassPackage = new CreditPassPackage();
    this.clientsPassPurchases = Map();
    this.isLoading = true;
    this.buyerId = null;
    this.orderItem = null;

    this.availableProfiles = List();

    this.bindListeners({
      addProfile: [
        CreditPassPurchasingActions.PROFILE_ADDED,
        CreditPassPurchasingActions.PROFILE_CREATED,
      ],

      updatePackageFromCart: CartActions.FETCH_SUCCESS,
      purchase: CreditPassPurchasingActions.PURCHASE_CLICKED,
      listingCreditPassPurchasesSuccess:
        CreditPassPurchasingActions.listingCreditPassPurchasesSuccess,
      listingCreditPassPurchasesError:
        CreditPassPurchasingActions.listingCreditPassPurchasesError,
    });
  }

  listingCreditPassPurchases(clientIds) {
    this.isLoading = true;

    return uhApiClient.get({
      url: `credit_passes/${this.creditPass.id}/purchases`,
      data: { client_ids: clientIds },
      success: CreditPassPurchasingActions.listingCreditPassPurchasesSuccess,
      error: CreditPassPurchasingActions.listingCreditPassPurchasesError,
    });
  }

  listingCreditPassPurchasesSuccess(data) {
    this.clientsPassPurchases = List(data.results)
      .groupBy(o => o.client_id)
      .map(o => o.first());

    this.isLoading = false;
  }

  listingCreditPassPurchasesError(...args) {
    this.notifyError('Error listing credit pass purchases', args);

    this.isLoading = false;
  }

  updatePackageFromCart() {
    if (!this.creditPass.id) {
      return;
    }
    this.getCart();
    const passFromCart = this.orderItemFromCart(this.creditPass.id);
    if (passFromCart) {
      this.creditPassPackage = passFromCart.orderable;
    }
  }

  orderItemFromCart(creditPassId) {
    return this.cart.order_items.find(
      oi =>
        oi.isCreditPassItem() &&
        oi.orderable.credit_pass_id === creditPassId &&
        oi.orderable.client_id === this.buyerId
    );
  }

  getCart() {
    this.waitFor(CartStore);
    this.cart = CartStore.getState().cart;
  }

  purchase({ creditPass, buyerId, orderItem = null }) {
    this.orderItem = orderItem;
    this.isLoading = true;
    this.waitFor([CurrentContextStore]);
    const { customerUser } = CurrentContextStore.getState();

    this.availableProfiles =
      customerUser && customerUser.managed_customer_users
        ? customerUser.managed_customer_users
            .push(customerUser)
            .filter(client => client.isClient())
        : [];

    if (!creditPass) return;

    this.getCart();
    if (this.creditPass.id !== creditPass.id) {
      const clientId = orderItem
        ? orderItem?.getIn(['orderable', 'client_id'], null)
        : buyerId;
      this.buyerId = buyerId;
      this.creditPassPackage = new CreditPassPackage({
        credit_pass_id: creditPass.id,
        price: creditPass.price,
        client_id: clientId,
      });
    }
    this.creditPass = creditPass;

    // check if this creditpass package has a limit of purchases
    if (this.creditPass.max_num_of_purchases !== 0) {
      this.listingCreditPassPurchases(
        this.availableProfiles.map(x => x.id).toJS()
      );
    } else {
      this.isLoading = false;
    }
  }

  addProfile(customerUserId) {
    this.buyerId = customerUserId;

    this.creditPassPackage = new CreditPassPackage({
      credit_pass_id: this.creditPass.id,
      price: this.creditPass.price,
      client_id: customerUserId,
    });
  }
}

export default alt.createStore(
  CreditPassPurchasingStore,
  'CreditPassPurchasingStore'
);
