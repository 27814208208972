import * as React from 'react';
import { OrderedSet, Map } from 'immutable';
import { Expander, Typography } from '@upperhand/playmaker';
import PropTypes from 'prop-types';

import { t } from 'shared/utils/LocaleUtils.js';

import SchedulingDrawerActions from 'shared/actions/SchedulingDrawerActions';
import ClientProfileActions from 'containers/clientProfile/Actions';

import SchedulesList from './SchedulesList.jsx';

function Schedules({
  intl,
  actions,
  registrationIds,
  pastRegistrationIds,
  pastLoading,
  upcomingLoading,
  pagination,
  isMobile,
  onSessionSummaryOpen,
  onRescheduleOpen,
}) {
  const upcomingPagination = pagination.get('upcomingRegistrations');
  const pastPagination = pagination.get('pastRegistrations');

  const handlePastSessionExpanded = () => {
    if (pastRegistrationIds.size === 0) {
      actions.listRegistrations(false);
    }
  };

  return (
    <div className="schedule">
      <Expander
        expanded
        arrowRight
        contentOffset={false}
        classes={{
          expanderRoot: 'schedule__root-expander',
        }}
        header={
          <Typography variant="subtitle1">
            {t('.upcoming_sessions', intl, __filenamespace)}
          </Typography>
        }
      >
        <SchedulesList
          actions={actions}
          isMobile={isMobile}
          intl={intl}
          pagination={upcomingPagination}
          loading={upcomingLoading}
          registrationIds={registrationIds}
          onPageSelect={(page, perPage) =>
            actions.listRegistrationsPrevNext({
              page,
              perPage,
              isUpcoming: true,
            })
          }
          onSessionSummaryOpen={onSessionSummaryOpen}
          onRescheduleOpen={onRescheduleOpen}
        />
      </Expander>
      <Expander
        arrowRight
        contentOffset={false}
        classes={{
          expanderRoot: 'schedule__root-expander',
        }}
        header={
          <Typography variant="subtitle1">
            {t('.past_sessions', intl, __filenamespace)}
          </Typography>
        }
        onChange={handlePastSessionExpanded}
      >
        <SchedulesList
          actions={actions}
          isMobile={isMobile}
          intl={intl}
          pagination={pastPagination}
          loading={pastLoading}
          registrationIds={pastRegistrationIds}
          onPageSelect={(page, perPage) =>
            actions.listRegistrationsPrevNext({
              page,
              perPage,
              isUpcoming: false,
            })
          }
          onSessionSummaryOpen={onSessionSummaryOpen}
        />
      </Expander>
    </div>
  );
}

Schedules.propTypes = {
  registrationIds: PropTypes.instanceOf(OrderedSet).isRequired,
  pastRegistrationIds: PropTypes.instanceOf(OrderedSet).isRequired,
  pastLoading: PropTypes.bool,
  upcomingLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  pagination: PropTypes.instanceOf(Map).isRequired,
  actions: PropTypes.object,
  onRescheduleOpen: PropTypes.func,
};

Schedules.defaultProps = {
  pastLoading: false,
  upcomingLoading: false,
  isMobile: false,
  actions: ClientProfileActions,
  onRescheduleOpen: SchedulingDrawerActions.toggleReschedule,
};

export default Schedules;
