import * as React from 'react';
import { states } from 'shared/components/StateSelector.jsx';
import SelectField from 'onboarding/components/AccountSetupPaysafe/fields/_SelectField.jsx';

const StateField = ({ fieldName, ...props }) => (
  <SelectField
    fieldName={fieldName}
    fullWidth
    items={states
      .map((name, abbrev) => ({ label: name, value: abbrev }))
      .toArray()}
    {...props}
  />
);

export default StateField;
