class ClientProfileActions {
  constructor() {
    this.generateActions(
      'mounted',
      'closeDrawer',
      'openScheduleDrawer',
      'closeScheduleDrawer',
      'toggleOrdersDrawer',
      'togglePaymentsDrawer',
      'openScheduleDrawer',
      'closeScheduleDrawer',
      'fetchSuccess',
      'fetchError',
      'tabSelected',
      'listManagedProfiles',
      'listManagedProfilesError',
      'listManagedProfilesSuccess',
      'fetchSubscription',
      'fetchSubscriptionSuccess',
      'fetchStatisticSuccess',
      'fetchStatisticError',
      'imageSelected',
      'clientSaved',
      'clientUpdateSuccess',
      'clientUpdateError',
      'listOrders',
      'listOrdersSuccess',
      'listOrdersError',
      'listOrdersPrevNext',
      'listRegistrations',
      'listRegistrationsSuccess',
      'listRegistrationsPrevNext',
      'listPastRegistrationsSuccess',
      'listRegistrationsError',
      'listEventsSuccess',
      'listSessionsSuccess',
      'openOrderDetailsDrawer',
      'fetchOrderSuccess',
      'fetchOrderError',
      'paginationReset',
      'openSessionSummaryDrawer',
      'closeSessionSummaryDrawer',
      'setRegistrationToRemove',
      'registrationCancel',
      'registrationCancelSuccess',
      'registrationCancelError',
      'setCreditOperation',
      'setWaive',
      'openViewBalanceDrawer',
      'onPaymentSuccess',
      'handleGoBack',
      'documentSelected',
      'onPdfFileChange',
      'onDeleteFile',
      'toggleFileUploadDrawer',
      'resetPassword',
      'resetPasswordSuccess',
      'resetPasswordError',
      'verifyEmail',
      'verifyEmailSuccess',
      'verifyEmailError',
      'additionalEmailsList',
      'additionalEmailsListSuccess',
      'additionalEmailsListError',
      'postAdditionalEmail',
      'postAdditionalEmailSuccess',
      'postAdditionalEmailError',
      'updateAdditionalEmail',
      'updateAdditionalEmailSuccess',
      'updateAdditionalEmailError',
      'deleteAdditionalEmail',
      'deleteAdditionalEmailSuccess',
      'deleteAdditionalEmailError',
      'setAdditionalEmail',
      'updateStore',
      'openModal',
      'closeModal'
    );
  }
}

export default alt.createActions(ClientProfileActions);
