class ClientBillingSettingsActions {
  constructor() {
    this.generateActions(
      'mounted',
      'deletePaymentMethod',
      'deletePaymentMethodSuccess',
      'deletePaymentMethodError',
      'setDefault',
      'setDefaultSuccess',
      'setDefaultError',
      'listPaymentMethods',
      'listPaymentMethodsSuccess',
      'listPaymentMethodsError',
      'updateCustomerUserAccess',
      'updateCustomerUserAccessSuccess',
      'updateCustomerUserAccessError'
    );
  }
}

export default alt.createActions(ClientBillingSettingsActions);
