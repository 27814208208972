/* eslint-disable camelcase */
import { fromJS } from 'immutable';

import BankAccount from 'shared/records/BankAccount.jsx';
import Customer from 'shared/records/Customer.jsx';
import CustomerActions from 'shared/actions/CustomerActions.jsx';
import FieldErrors from 'shared/records/FieldErrors.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { setAddressDataIn } from 'shared/utils/ObjectUtils.jsx';
import moment from 'moment-timezone';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

const url = id => {
  if (id) {
    return `customers/${id}`;
  }
  throw new Error('a customer ID must be provided');
};

class CustomerStore extends UpperHandStore {
  constructor() {
    super();
    // TODO: can any of these be removed?
    this.customer = new Customer();
    this.fieldErrors = new FieldErrors();
    this.isLoadingCustomer = false;
    this.isSaving = false;
    this.isValid = false;
    this.hasBeenValidated = false;
    this.newBankAccount = new BankAccount();
    this.activeSaveButton = false;
    this.blackoutDates = [];
    this.sportsOffered = [];
    this.bindListeners({
      handleFetch: CustomerActions.fetch,
      handleFetchSuccess: CustomerActions.fetchSuccess,
      handleFetchError: CustomerActions.fetchError,

      handleUpdate: CustomerActions.update,
      handleUpdateSuccess: CustomerActions.updateSuccess,
      handleUpdateError: CustomerActions.updateError,

      handleRemoveBankAccount: CustomerActions.removeBankAccount,
      handleRemoveBankAccountSuccess: CustomerActions.removeBankAccountSuccess,
      handleRemoveBankAccountError: CustomerActions.removeBankAccountError,

      handleAddBankAccount: CustomerActions.addBankAccount,
      handleAddBankAccountSuccess: CustomerActions.addBankAccountSuccess,
      handleAddBankAccountError: CustomerActions.addBankAccountError,

      handleChangeEmailPreference: CustomerActions.changeEmailPreference,

      handleUpdateLegalEntity: CustomerActions.updateLegalEntity,
      handleUpdateNewBankAccount: CustomerActions.updateNewBankAccount,
      handleValidate: CustomerActions.validate,
      saveBlackoutDatesError: CustomerActions.saveBlackoutDatesError,
      handleUpdateStore: CustomerActions.updateStore,
      setBlackoutDates: CustomerActions.setBlackoutDates,
      deleteBlackoutDates: CustomerActions.deleteBlackoutDates,
      saveBlackoutDates: CustomerActions.saveBlackoutDates,
      blackoutDateSuccess: CustomerActions.blackoutDateSuccess,
      handleUpdateSportsOffered: CustomerActions.handleUpdateSportsOffered,

      handleAvailabilityChange: CustomerActions.handleAvailabilityChange,
    });
  }

  handleUpdateLegalEntity(data) {
    const newLegalEntity = this.customer
      .get('legal_entity')
      .merge(fromJS(data));
    this.customer = this.customer.set('legal_entity', newLegalEntity);
    this.legalEntityUpdated = true;
    this.handleValidate();
  }

  handleUpdateNewBankAccount(data) {
    this.newBankAccount = this.newBankAccount.mergeDeep(fromJS(data));
    this.newBankAccountUpdated = true;
    this.handleValidate();
  }

  handleUpdateStore(data) {
    const newCustomer = this.customer.toServer
      ? this.customer
      : new Customer(this.customer);
    // eslint-disable-next-line no-param-reassign
    data = setAddressDataIn(data, newCustomer);
    this.customer = this.customer
      .mergeDeep(newCustomer)
      .mergeDeep(data)
      .mergeDeep({ id: this.customer.id });
    this.handleValidate();
  }

  handleFetch({ id, fields }) {
    if (!id) return null;

    this.isLoadingCustomer = true;

    return uhApiClient.get({
      url: url(id),
      data: { fields },
      success: CustomerActions.fetchSuccess,
      error: CustomerActions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.resetAfterSuccess(data);
    // this.validateLegalEntity();
  }

  handleFetchError(...args) {
    this.isLoadingCustomer = false;
    this.notifyError('error while fetching customer', args);
  }

  handleAddBankAccount() {
    if (!this.newBankAccountUpdated) return null;

    this.validateBankAccount(this.newBankAccount);

    if (!this.newBankAccountIsValid) return null;

    this.isSaving = true;

    return uhApiClient.post({
      url: `${url(this.customer.id)}/bank_accounts`,
      data: this.payloadNewBankAccount(),
      success: CustomerActions.removeBankAccountSuccess,
      error: CustomerActions.removeBankAccountError,
    });
  }

  handleAddBankAccountSuccess(data) {
    this.resetAfterSuccess(data);
  }

  handleAddBankAccountError(...args) {
    this.isSaving = false;
    this.notifyError('error during bank account removal', args);
  }

  handleRemoveBankAccount(bankAccountId) {
    this.isSaving = true;
    return uhApiClient.delete({
      url: `${url(this.customer.id)}/bank_accounts/${bankAccountId}`,
      success: CustomerActions.removeBankAccountSuccess,
      error: CustomerActions.removeBankAccountError,
    });
  }

  handleRemoveBankAccountSuccess(data) {
    this.resetAfterSuccess(data);
  }

  handleRemoveBankAccountError(...args) {
    this.isSaving = false;
    this.notifyError('error during bank account removal', args);
  }

  handleChangeEmailPreference({ preference, checked }) {
    const emailPreferences = this.customer.preferences.get('client_emails');
    if (preference === 'all') {
      Object.keys(emailPreferences).forEach(key => {
        emailPreferences[key] = checked;
      });
    } else {
      emailPreferences[preference] = checked;
    }
    this.customer = this.customer.setIn(
      ['preferences', 'client_emails'],
      emailPreferences
    );
    this.handleValidate();
  }

  handleUpdateSportsOffered(values) {
    this.sportsOffered = values;
  }

  // eslint-disable-next-line consistent-return
  async handleUpdate({ id, staffMember, fields }) {
    this.handleValidate();

    if (this.isValid) {
      this.isSaving = true;
      return uhApiClient.put({
        url: url(id),
        data: await this.payload({ staffMember, fields }),
        success: CustomerActions.updateSuccess,
        error: CustomerActions.updateError,
      });
    }
    this.isSaving = false;
  }

  handleUpdateSuccess(data) {
    this.resetAfterSuccess(data);
  }

  handleUpdateError(...args) {
    this.isSaving = false;
    this.isValid = false;
    this.hasBeenValidated = false;
    this.isLoadingCustomer = false;
    this.notifyError('error while updating customer', args);
  }

  handleValidate() {
    this.fieldErrors = this.fieldErrors.clear();

    // if (this.legalEntityUpdated || !!data / && data.validateLegalEntity /) {
    // this.validateLegalEntity();
    // }

    this.validateStringField(this.customer.name, 'name');
    this.validateStringField(this.customer.time_zone, 'time_zone');

    // If the customer has a value in the random field this is a new customer
    // sign up, so they won't have any preferences to validate.
    if (!this.customer.random) {
      this.validatePaymentMethod();
    }

    this.isValid = this.fieldErrors.isEmpty();
    this.hasBeenValidated = true;
  }

  addError(field, error) {
    this.fieldErrors = this.fieldErrors.add(field, error);
  }

  async payload({ staffMember, fields }) {
    const payload = await this.customer.toServer();
    payload.sports_offered = [...this.sportsOffered];
    if (staffMember) {
      payload.admin = staffMember.toJSON();
      if (payload.admin.profile_image && !payload.admin.profile_image.file) {
        delete payload.admin.profile_image;
      } else {
        payload.admin.profile_image =
          await staffMember.profile_image.toServer();
      }
    }

    const finalPayload = { attributes: payload };
    if (fields) {
      finalPayload.fields = fields;
    }
    return JSON.stringify(finalPayload);
  }

  payloadNewBankAccount() {
    const payload = { attributes: {} };
    payload.attributes.bank_account = this.newBankAccount.toJS();
    return JSON.stringify(payload);
  }

  resetAfterSuccess(data) {
    this.isSaving = false;
    this.isValid = false;
    this.hasBeenValidated = false;
    this.isLoadingCustomer = false;
    this.legalEntityUpdated = false;
    this.newBankAccountUpdated = false;
    this.newBankAccount = new BankAccount();
    if (data) {
      this.customer = new Customer(data.customer ? data.customer : data);
      if (this.customer.sports_offered.length > 0) {
        this.sportsOffered = [...this.customer.sports_offered];
      }
    }
  }

  setBlackoutDates({ date, index }) {
    const dateError = 'Selected date already present in blackout dates';
    const blackoutDatesArray = this.customer.get('blackout_dates');
    const dateAlreadyExists = blackoutDatesArray.some(
      blackoutDate =>
        moment(blackoutDate).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD')
    );
    if (dateAlreadyExists) {
      MessageWindowActions.addMessage.defer(dateError);
      return;
    }
    if (index === null) {
      if (!dateAlreadyExists) {
        const updatedArray = this.customer.get('blackout_dates');
        updatedArray.push(moment(date).format('YYYY-MM-DD'));
        this.customer.set('blackout_dates', updatedArray);
        this.activeSaveButton = true;
      }
    }

    if (index !== null) {
      const updatedArray = this.customer.get('blackout_dates');
      updatedArray[index] = date;
      this.customer.set('blackout_dates', updatedArray);

      this.activeSaveButton = true;
    }
  }

  deleteBlackoutDates(index) {
    const updatedArray = this.customer.get('blackout_dates');
    updatedArray.splice(index, 1);
    this.customer.set('blackout_dates', updatedArray);

    this.activeSaveButton = true;
  }

  saveBlackoutDates() {
    this.validateDateTimes();

    if (!this.fieldErrors.isEmpty()) {
      return;
    }

    this.activeSaveButton = false;

    const blackoutDates = this.customer.get('blackout_dates');
    const newBlackoutDates = blackoutDates.map(item =>
      moment(item).format('YYYY-MM-DD')
    );

    this.blackoutDates = [...newBlackoutDates];

    const params = {
      attributes: {
        blackout_dates: newBlackoutDates,
        date_specific_daytimes: this.customer.get('date_specific_daytimes', []),
      },
    };

    uhApiClient.put({
      url: `customers/${currentCustomer().id}`,
      data: JSON.stringify(params),
      success: CustomerActions.blackoutDateSuccess,
      error: CustomerActions.saveBlackoutDatesError,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  saveBlackoutDatesError(data) {
    const { message } = data.response.data.response;
    MessageWindowActions.addMessage.defer(message);
    this.handleFetch({ id: currentCustomer().id });
  }

  blackoutDateSuccess(data) {
    const { blackout_dates } = data.customer;
    const nonBlackoutAbleDates = this.blackoutDates.filter(
      x => !blackout_dates.includes(x)
    );
    if (nonBlackoutAbleDates.length > 0) {
      const index = nonBlackoutAbleDates.map(item =>
        this.blackoutDates.findIndex(date => date === item)
      );

      index.map(item => this.deleteBlackoutDates(item));

      MessageWindowActions.addMessage.defer(
        `Can't be blacked out with sessions scheduled during following date and time ${nonBlackoutAbleDates.join(
          ','
        )}`
      );
    }

    if (nonBlackoutAbleDates.length === 0) {
      MessageWindowActions.addMessage.defer(
        'Business availability updated successfully'
      );
    }
  }

  handleAvailabilityChange({ keyPath, value }) {
    this.customer = this.customer.setIn(keyPath, value);
    this.activeSaveButton = true;
  }

  validateBankAccount(bankAccount) {
    if (bankAccount.get('id')) {
      return; // no need to validate bank accounts that are already saved
    }

    const routing_number = bankAccount.get('routing_number');
    const account_number = bankAccount.get('account_number');
    const account_holder_name = bankAccount.get('account_holder_name');
    const account_holder_type = bankAccount.get('account_holder_type');

    this.newBankAccountIsValid = true;

    if (this.newBankAccountUpdated) {
      this.newBankAccountIsValid =
        this.validateStringField(
          routing_number,
          'newBankAccount.routing_number'
        ) && this.newBankAccountIsValid;

      this.newBankAccountIsValid =
        this.validateStringField(
          account_number,
          'newBankAccount.account_number'
        ) && this.newBankAccountIsValid;

      this.newBankAccountIsValid =
        this.validateStringField(
          account_holder_name,
          'newBankAccount.account_holder_name'
        ) && this.newBankAccountIsValid;

      this.newBankAccountIsValid =
        this.validateStringField(
          account_holder_type,
          'newBankAccount.account_holder_type'
        ) && this.newBankAccountIsValid;
    }
  }

  // validateLegalEntity() {
  //   const legalEntity = this.customer.get('legal_entity');
  //   if (!legalEntity) {
  //     return;
  //   }

  //   if (legalEntity.isIndividual() && !legalEntity.get('ssn_last_4_provided')) {
  //     this.validateStringField(
  //       legalEntity.get('ssn_last_4'),
  //       'legal_entity.tax_id'
  //     );
  //   } else if (
  //     legalEntity.isCompany() &&
  //     !legalEntity.get('business_tax_id_provided')
  //   ) {
  //     this.validateStringField(
  //       legalEntity.get('business_tax_id'),
  //       'legal_entity.tax_id'
  //     );
  //   }

  //   this.validateStringField(
  //     legalEntity.get('first_name'),
  //     'legal_entity.first_name'
  //   );
  //   this.validateStringField(
  //     legalEntity.get('last_name'),
  //     'legal_entity.last_name'
  //   );

  //   if (!legalEntity.hasValidDOB()) {
  //     this.addError('legal_entity.dob', 'required');
  //   }
  // }

  validateStringField(value, field) {
    if (!value || !(value.length > 0)) {
      this.addError(field, 'required');
      return false;
    }
    return true;
  }

  validatePaymentMethod() {
    if (!this.customer.get('preferences')) {
      return true;
    }

    const events = this.customer.preferences.get('event_checkout');
    const memberships = this.customer.preferences.get('membership_checkout');
    const creditPasses = this.customer.preferences.get('credit_pass_checkout');
    const atLeastOnePaymentEvent = Object.keys(events).some(
      item => events[item]
    );
    const atLeastOnePaymentMembership = Object.keys(events).some(
      item => memberships[item]
    );
    const atLeastOnePaymentCreditPasses = Object.keys(events).some(
      item => creditPasses[item]
    );

    if (!atLeastOnePaymentEvent) {
      this.addError(
        ['preferences', 'client_payment_methods'],
        'validation.customer.at_least_one_payment_method_event'
      );
    }

    if (!atLeastOnePaymentMembership) {
      this.addError(
        ['preferences', 'client_payment_methods'],
        'validation.customer.at_least_one_payment_method_membership'
      );
    }

    if (!atLeastOnePaymentCreditPasses) {
      this.addError(
        ['preferences', 'client_payment_methods'],
        'validation.customer.at_least_one_payment_method_creditPasses'
      );
    }

    return false;
  }

  validateDateTimes() {
    this.fieldErrors = this.fieldErrors.clear();

    const dateSpecificTimes = this.customer.get('date_specific_daytimes');
    const isValidStartTime = dateSpecificTimes.every(timeRangeList =>
      timeRangeList.every(timeRange => timeRange.get('start_time'))
    );

    if (!isValidStartTime) {
      this.addError('start_time', 'Required.');
    }

    const isValidEndTime = dateSpecificTimes.every(timeRangeList =>
      timeRangeList.every(timeRange => timeRange.get('end_time'))
    );

    if (!isValidEndTime) {
      this.addError('end_time', 'Required.');
    }
  }
}

export default alt.createStore(CustomerStore, 'CustomerStore');
