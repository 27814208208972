import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Confirmation } from '@upperhand/playmaker';
import altContainer from 'shared/hocs/altContainer.jsx';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import TeamTypeForm from 'shared/components/TeamTypeForm.jsx';
import TeamTypeEditingStore from 'shared/stores/TeamTypeEditingStore.jsx';
import TeamTypeSelectorUIStore from 'shared/stores/TeamTypeSelectorUIStore.jsx';
import TeamTypeEditingActions from 'shared/actions/TeamTypeEditingActions.jsx';
import TeamTypeSelectorUIActions from 'shared/actions/TeamTypeSelectorUIActions.jsx';
import SelectWithNewItem from 'shared/components/_SelectWithNewItem.jsx';

import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const TeamTypeDialog = injectIntl(
  ({ teamType, open, onClose, onSubmit, intl }) => {
    const isNew = teamType ? teamType.id === null : false;
    const dialogTitle = isNew
      ? t('.dialog_title_create_mode', intl, __filenamespace)
      : t('.dialog_title_edit_mode', intl, __filenamespace);

    return (
      <Confirmation
        hasCheckbox={false}
        title={dialogTitle}
        open={open}
        cancelButtonLabel={t('.dialog_cancel_button', intl, __filenamespace)}
        confirmButtonLabel={t('.dialog_submit_button', intl, __filenamespace)}
        onCancel={onClose}
        onConfirm={onSubmit}
      >
        <TeamTypeForm teamType={teamType} />
      </Confirmation>
    );
  }
);

function EditableTeamTypeSelect({
  selectedTeamTypeId,
  componentId,
  errorText,
  onChange,
  menuItems,
  onCreateOrUpdateSuccess,
  teamTypeUIStore,
  intl,
  disableAddNewItem,
  teamType,
}) {
  return (
    <>
      <SelectWithNewItem
        value={selectedTeamTypeId}
        itemName={t('.item_name', intl, __filenamespace)}
        newItemCallback={
          !disableAddNewItem
            ? () =>
                TeamTypeSelectorUIActions.openDialog({
                  componentId,
                  newEventType: true,
                })
            : undefined
        }
        error={!!errorText}
        helperText={errorText}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: document.documentElement.clientHeight * 0.4,
            },
          },
        }}
        onChange={onChange}
        fullWidth
      >
        {menuItems}
      </SelectWithNewItem>

      <TeamTypeDialog
        teamType={teamType}
        open={teamTypeUIStore.shouldOpen(componentId)}
        onSubmit={() => {
          TeamTypeEditingActions.createOrUpdate({
            callback: onCreateOrUpdateSuccess,
          });
          TeamTypeSelectorUIActions.closeDialog();
        }}
        onClose={TeamTypeSelectorUIActions.closeDialog}
      />

      {selectedTeamTypeId && (
        <Button
          variant="contained"
          color="default"
          onClick={() => TeamTypeSelectorUIActions.openDialog({ componentId })}
        >
          {t('.edit_button', intl, __filenamespace)}
        </Button>
      )}
    </>
  );
}

const TeamTypeSelect = injectIntl(
  ({
    teamTypeUIStore,
    selectedTeamTypeId,
    teamTypes,
    onChange,
    errorText,
    onCreateOrUpdateSuccess,
    componentId,
    intl,
    teamTypeEditingStore: { record: teamType },
  }) => {
    const menuItems = [];

    if (teamTypes.size) {
      teamTypes.forEach(eventType => {
        const icon = <FiberManualRecordIcon sx={{ color: eventType.color }} />;
        menuItems.push(
          <MenuItem key={eventType.id} value={eventType.id}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{eventType.name}</ListItemText>
          </MenuItem>
        );
      });
    }

    return (
      <EditableTeamTypeSelect
        disableAddNewItem={
          currentCustomer().restrict_editing && !currentUser().isAdmin()
        }
        selectedTeamTypeId={selectedTeamTypeId}
        componentId={componentId}
        errorText={errorText}
        onChange={onChange}
        menuItems={menuItems}
        onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
        teamTypeUIStore={teamTypeUIStore}
        intl={intl}
        teamType={teamType}
      />
    );
  }
);

function TeamTypeInlineEditor(props) {
  const {
    selectedTeamTypeId,
    teamTypes,
    onTeamTypeSelect,
    errorText,
    onCreateOrUpdateSuccess,
    style,
    teamTypeEditingStore,
    teamTypeUIStore,
  } = props;

  React.useEffect(() => {
    TeamTypeEditingActions.initialLoad.defer(selectedTeamTypeId);
  }, [selectedTeamTypeId]);
  return (
    <Box sx={style}>
      <TeamTypeSelect
        selectedTeamTypeId={selectedTeamTypeId}
        teamTypes={teamTypes}
        onChange={onTeamTypeSelect}
        onCreateOrUpdateSuccess={onCreateOrUpdateSuccess}
        errorText={errorText}
        componentId={uuidv4()}
        teamTypeEditingStore={teamTypeEditingStore}
        teamTypeUIStore={teamTypeUIStore}
      />
    </Box>
  );
}

export default altContainer({
  stores: {
    teamTypeUIStore: TeamTypeSelectorUIStore,
    teamTypeEditingStore: TeamTypeEditingStore,
  },
})(TeamTypeInlineEditor);
