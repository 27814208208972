class PaymentMethodModalActions {
  constructor() {
    this.generateActions(
      'mounted',
      'paymentMethodResponse',
      'updatePaymentMethodRecord',
      'createPaymentMethod',
      'createPaymentMethodSuccess',
      'createPaymentMethodError',
      'changePaymentMethodType',
      'openModal',
      'closeModal'
    );
  }
}

export default alt.createActions(PaymentMethodModalActions);
