/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import ActionDoneIcon from '@mui/icons-material/Done';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import OrderTotals from 'shared/components/checkout/OrderTotals.jsx';
import ReceiptDialog from 'point_of_sale/components/ReceiptDialog.jsx';
import FormattedCurrency from 'shared/components/FormattedCurrency.jsx';
import ProRateToggle from 'shared/components/checkout/proRateToggle.jsx';
import ItemSummaryList from 'cart/index/components/_ItemSummaryList.jsx';
import CouponSummary from 'shared/components/checkout/CouponSummary.jsx';
import TaxExemptToggle from 'shared/components/checkout/TaxExemptToggle.jsx';
import { FlexBoxCenter, FlexBoxJustify } from 'shared/components/FlexBox.jsx';
import ServiceFeeExemptToggle from 'shared/components/checkout/ServiceFeeExemptToggle.jsx';
import {
  Payment,
  PaymentMethod,
  PaymentStore,
  PaymentType,
} from 'containers/payments';

import uhTheme from '_uh_theme.jsx';
import {
  currentCustomer,
  enabledCustomerFeatures,
} from 'shared/utils/CustomerUtils';
import { merge } from 'shared/utils/ObjectUtils.jsx';
import { uhColors } from 'shared/styles/uhStyles.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { messageId, t } from 'shared/utils/LocaleUtils.js';
import { OrderableTypes } from 'containers/reports/types/index';
import POSActions from 'point_of_sale/actions/POSActions.jsx';
import { hasMembershipAgreementAccepted } from 'shared/components/checkout/helper';

export const REVIEW = 'review';
export const PAYMENT_SELECT = 'paymentSelect';
export const PROCESSING = 'processing';
export const SUMMARY = 'summary';

const styles = {
  boldText: {
    fontWeight: 'bold',
    marginTop: '5px',
  },
  buttonLabel: {
    fontSize: 16,
  },
  checkoutButton: {
    marginBottom: '1rem',
    height: 50, // https://github.com/callemall/material-ui/issues/4473
  },
  completeFormWarning: {
    backgroundColor: uhColors.headerGrey,
    padding: '15px',
    textAlign: 'center',
    borderBottom: '1px solid',
    borderBottomColor: uhColors.lightGrey,
  },
  sectionHeader: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  summaryButton: {
    height: 20,
    lineHeight: '20px',
  },
  summaryLabel: {
    fontWeight: 400,
    fontSize: 15,
    textTransform: 'none',
    paddingLeft: 'none',
  },
  warningMessage: {
    fontSize: '13px',
    marginBottom: '1rem',
    paddingTop: '15px',
    color: uhColors.errorColor,
    textAlign: 'center',
  },
  confirmationEmail: {
    fontWeight: '600',
    wordBreak: 'break-all',
    padding: '1rem 0',
  },
  protectionsTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  divider: {
    margin: '20px -20px',
    height: '2px',
    backgroundColor: 'var(--icon-light-grey)',
  },
};

const allEventRegistrationsCompleted = (cart, registrationFormStore) =>
  cart.order_items.every(
    oi =>
      !oi.isEventItem() || registrationFormStore.isCompleted(oi.orderable.id)
  );

const hasRegistrationForms = (cart, registrationFormStore) =>
  cart.order_items
    .map(oi => oi.orderable.event_id)
    .some(eventId => registrationFormStore.hasRegistrationForm(eventId));

function Review({
  insurances,
  disableContinueButton,
  appliedAccountCredits,
  automations,
  cart,
  couponIsLoading,
  dividerStyle,
  formWarningStyle,
  onCouponApply,
  onItemEdit,
  onItemRemoved,
  onItemView,
  onReviewBack,
  onReviewContinue,
  onTaxExemptToggle,
  onTaxIdChange,
  profiles,
  registrationFormActions,
  registrationFormDrawerDirection,
  registrationFormStore,
  showReviewBackButton,
  showTaxExemptToggle,
  onFeeExemptToggle,
  showServiceFeeExemptToggle,
  onInsuranceCheck,
  onInsuranceValidate,
}) {
  React.useEffect(() => {
    document.addEventListener('setooAddToCart', onInsuranceCheck);

    return () =>
      document.removeEventListener('setooAddToCart', onInsuranceCheck);
  }, [onInsuranceCheck]);

  React.useEffect(() => {
    const insuranceContainer = document.querySelector('.insurances-list');

    insuranceContainer?.addEventListener('click', onInsuranceValidate);

    return () =>
      insuranceContainer?.removeEventListener('click', onInsuranceValidate);
  }, [onInsuranceValidate]);

  const membershipAgreementContent = currentCustomer().membership_agreement;
  const hasMembershipInCart = cart.order_items.some(
    item =>
      item.orderable_type === OrderableTypes.membership_subscription_package
  );
  const customerUserIds = cart.order_items
    .filter(
      order =>
        order.orderable_type === OrderableTypes.membership_subscription_package
    )
    .flatMap(item => item.getIn(['orderable', 'customer_user_ids']));
  const hasSignedAgreement = hasMembershipAgreementAccepted(
    customerUserIds,
    profiles,
    membershipAgreementContent
  );

  const showInsurances =
    currentUser().isClient() &&
    enabledCustomerFeatures(['insurance']) &&
    insurances.length > 0;

  return (
    <div>
      {(hasRegistrationForms(cart, registrationFormStore) ||
        (currentUser().isClient() &&
          hasMembershipInCart &&
          membershipAgreementContent &&
          !hasSignedAgreement)) && (
        <div style={merge(styles.completeFormWarning, formWarningStyle)}>
          <FormattedMessage
            id={messageId('.complete_form_warning', __filenamespace)}
          />
        </div>
      )}

      {showReviewBackButton && (
        <Button
          color="dark"
          disableTouchRipple
          startIcon={<ChevronLeft sx={{ color: uhColors.blue }} />}
          style={merge(styles.summaryButton, styles.summaryLabel, {
            marginLeft: -10,
            marginBottom: '2rem',
            backgroundColor: 'transparent',
          })}
          onClick={onReviewBack}
        >
          Back
        </Button>
      )}
      <div style={styles.sectionHeader}>
        <FormattedMessage id={messageId('.summary', __filenamespace)} />
      </div>

      <ItemSummaryList
        appliedAccountCredits={appliedAccountCredits}
        athletes={profiles}
        automations={automations}
        dividerStyle={dividerStyle}
        onEdit={onItemEdit}
        onRemove={onItemRemoved}
        onView={onItemView}
        items={cart.order_items}
        purchased={false}
        registrationFormActions={registrationFormActions}
        registrationFormDrawerDirection={registrationFormDrawerDirection}
        registrationForms={registrationFormStore}
      />

      {showInsurances && (
        <>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1" sx={styles.protectionsTitle}>
              <FormattedMessage
                id={messageId('.protections', __filenamespace)}
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id={messageId('.powered_by', __filenamespace)}
              />
            </Typography>
          </Stack>
          <Typography>
            <FormattedMessage
              id={messageId('.protections_info', __filenamespace)}
            />
          </Typography>
          <div className="insurances-list">
            {insurances.map((insurance, index) => (
              <div
                className="insurances-list__item"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: insurance.html }}
              />
            ))}
          </div>
          <Divider sx={styles.divider} />
        </>
      )}

      <CouponSummary
        order={cart}
        onCouponApply={onCouponApply}
        loading={couponIsLoading}
      />
      <OrderTotals order={cart} style={{ marginBottom: 20 }} />
      {showTaxExemptToggle && (
        <TaxExemptToggle
          order={cart}
          onTaxExemptToggle={onTaxExemptToggle}
          onTaxIdChange={onTaxIdChange}
        />
      )}
      {showServiceFeeExemptToggle && (
        <ServiceFeeExemptToggle
          order={cart}
          onFeeExemptToggle={onFeeExemptToggle}
        />
      )}
      {cart.get('prorate_date') === null && currentUser().isStaff() ? (
        <ProRateToggle
          order={cart}
          style={{ marginBottom: 20 }}
          proRateCheckHandler={value => POSActions.proRateCheck(value)}
        />
      ) : (
        ''
      )}

      {((!registrationFormStore.isLoading &&
        !allEventRegistrationsCompleted(cart, registrationFormStore)) ||
        (currentUser().isClient() &&
          hasMembershipInCart &&
          membershipAgreementContent &&
          !hasSignedAgreement)) && (
        <div style={styles.warningMessage}>
          <FormattedMessage
            id={messageId('.complete_event_form', __filenamespace)}
          />
        </div>
      )}

      <Button
        fullWidth
        variant="contained"
        disabled={
          !allEventRegistrationsCompleted(cart, registrationFormStore) ||
          (currentUser().isClient() &&
            hasMembershipInCart &&
            membershipAgreementContent &&
            !hasSignedAgreement) ||
          disableContinueButton
        }
        style={{ ...styles.checkoutButton, ...styles.buttonLabel }}
        onClick={onReviewContinue}
      >
        Continue to Payment
      </Button>
    </div>
  );
}

function Processing({ intl }) {
  return (
    <div>
      <div style={{ textAlign: 'center', marginBottom: '3rem' }}>
        <CircularProgress size={60} />
        <div style={{ marginTop: '1rem' }}>
          {t('.processing_payment', intl, __filenamespace)}
        </div>
      </div>
    </div>
  );
}

function Summary({
  automations,
  cart,
  onItemView,
  profiles,
  purchaserEmail,
  registrationFormStore,
  registrationFormActions,
  registrationFormDrawerDirection,
  showScheduleButtons,
  summaryActionButton,
}) {
  const paymentMethodDisplay = {
    [PaymentMethod.CARD]: 'Card',
    [PaymentMethod.CARD_NEW]: 'New Card',
    [PaymentMethod.CARD_NOT_PRESENT]: 'Card',
    [PaymentMethod.CARD_SAVED]: 'Saved Card',
    [PaymentMethod.CHECK]: 'Check',
    [PaymentMethod.CASH]: 'Cash',
    [PaymentMethod.HANDPOINT_CLOUD]: 'Card Present',
    [PaymentMethod.PAY_LATER]: 'Pay Later',
  };

  const { paymentMethod } = PaymentStore.getState();

  return (
    <div>
      <FlexBoxCenter style={{ fontSize: '14pt', marginBottom: '2rem' }}>
        <ActionDoneIcon
          style={{ marginRight: '0.5em', color: uhTheme.palette.accent1Color }}
        />
        <FormattedMessage
          id={messageId('.payment_complete', __filenamespace)}
        />
      </FlexBoxCenter>
      {cart.total > 0 && (
        <div>
          <div style={merge(styles.boldText, { color: '#969696' })}>
            <FormattedMessage
              id={messageId('.payment_method', __filenamespace)}
            />
          </div>
          <div style={{ fontSize: 16, margin: '0.5rem 0' }}>
            {paymentMethodDisplay[paymentMethod]}
          </div>
        </div>
      )}

      <Divider style={{ margin: '1.5rem 0' }} />

      <ItemSummaryList
        athletes={profiles}
        automations={automations}
        items={cart.order_items}
        onView={onItemView}
        purchased
        registrationFormActions={registrationFormActions}
        registrationFormDrawerDirection={registrationFormDrawerDirection}
        registrationForms={registrationFormStore}
        showScheduleButtons={showScheduleButtons}
      />

      {Boolean(cart.insurance_amount) && (
        <FlexBoxJustify style={{ marginBottom: '2rem', fontSize: '14pt' }}>
          <FormattedMessage
            id={messageId('.insurance_amount', __filenamespace)}
          />
          <FormattedCurrency value={cart.insurance_amount} fromCents />
        </FlexBoxJustify>
      )}

      <FlexBoxJustify style={{ marginBottom: '2rem', fontSize: '14pt' }}>
        <FormattedMessage id={messageId('.total_payment', __filenamespace)} />
        <FormattedCurrency value={cart.due_immediately} fromCents />
      </FlexBoxJustify>

      <div style={{ textAlign: 'center', marginBottom: 20 }}>
        <FormattedMessage
          id={messageId('.confirmation_email_sent_to', __filenamespace)}
        />
        <div style={styles.confirmationEmail}>{purchaserEmail}</div>
      </div>

      <ReceiptDialog orderId={cart.id} />

      {summaryActionButton}
    </div>
  );
}

function PaymentForm({
  cart,
  intl,
  hasAccess,
  onPurchaseSuccess,
  onReturnToReview,
  purchaserId,
  processingPayment,
  disabledPaymentMethods,
  purchasingUserId,
}) {
  const hasPreSaleItem = cart === null ? false : cart.hasPreSaleItem();
  let hasFutureBillableItem = false;

  if (cart !== null && cart.hasFutureBillableItem()) {
    hasFutureBillableItem =
      cart.order_items.findIndex(oi => oi.isMembershipItem() && oi.total > 0) >
      -1;
  }

  return (
    <div>
      <Button
        disableTouchRipple
        color="dark"
        disabled={processingPayment}
        startIcon={<ChevronLeft sx={{ color: uhColors.blue }} />}
        style={merge(styles.summaryButton, styles.summaryLabel, {
          marginLeft: -10,
          marginBottom: '2rem',
          backgroundColor: 'transparent',
        })}
        onClick={onReturnToReview}
      >
        {t('.summary', intl, __filenamespace)}
      </Button>
      <div style={styles.sectionHeader}>
        <FormattedMessage id={messageId('.payment', __filenamespace)} />
      </div>
      <FlexBoxJustify style={{ marginBottom: '1rem' }}>
        <div>
          {cart.due_immediately === cart.total ? (
            <FormattedMessage id={messageId('.total', __filenamespace)} />
          ) : (
            <FormattedMessage id={messageId('.due_today', __filenamespace)} />
          )}
        </div>
        <div style={styles.boldText}>
          <FormattedCurrency value={cart.due_immediately} fromCents />
        </div>
      </FlexBoxJustify>
      <Divider />
      <Payment
        hasAccess={hasAccess}
        hasFutureBillableItem={hasFutureBillableItem}
        hasPreSaleItem={hasPreSaleItem}
        itemId={cart.id}
        total={cart.total}
        order={cart}
        paymentType={PaymentType.ORDER}
        purchaserId={purchaserId}
        purchasingUserId={purchasingUserId}
        successAction={onPurchaseSuccess}
        disabledPaymentMethods={disabledPaymentMethods}
      />
    </div>
  );
}

function CheckoutStepper({ step, ...props }) {
  switch (step) {
    case REVIEW:
      return <Review {...props} />;
    case PAYMENT_SELECT:
      return <PaymentForm {...props} />;
    case PROCESSING:
      return <Processing {...props} />;
    case SUMMARY:
      return <Summary {...props} />;
    default:
      return <div />;
  }
}

export default injectIntl(CheckoutStepper);
