/* eslint-disable */
import { Set, List, Map, Record } from 'immutable';
import moment from 'moment-timezone';

import EventType from 'event_mgmt/shared/records/EventType.jsx';
import Registration from 'shared/records/Registration';
import Schedule from 'event_mgmt/shared/records/Schedule.jsx';
import Image from 'records/Image'; // Important to use direct import for resolving import/no-cycle issue
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { isCollectionWithContents } from 'shared/utils/ObjectUtils.jsx';
import {
  customerTZ,
  dateRange,
  MonthDayYearFormat,
} from 'event_mgmt/shared/utils/DateAndTimeUtils.jsx';
import { humanizeDuration } from 'event_mgmt/shared/utils/FormattingUtils.jsx';
import TeamDetail from 'team_mgmt/shared/records/TeamDetail.jsx';
import TeamType from 'records/TeamType.jsx';

const DEFAULT_DESCRIPTION =
  'More event information to follow. Let us know if you have any questions in the meantime.';

const constructionModifierForImmutableObject = object => {
  if (isCollectionWithContents(object.get('registrations'))) {
    object.set(
      'registrations',
      List(object.get('registrations')).map(pr => new Registration(pr))
    );
  } else {
    object.set('registrations', List());
  }

  if (isCollectionWithContents(object.get('purchased_registrations'))) {
    object.set(
      'purchased_registrations',
      List(object.get('purchased_registrations')).map(
        pr => new Registration(pr)
      )
    );
  } else {
    object.set('purchased_registrations', List());
  }
  if (isCollectionWithContents(object.get('schedules'))) {
    // For now, just convert it to a list. EventTranslator does the rest.
    // That code should be moved here eventually.
    object.set(
      'schedules',
      List(object.get('schedules').map(s => new Schedule(s)))
    );
  } else {
    object.set('schedules', List([new Schedule()]));
  }

  object.set('image', new Image(object.get('image')));
  object.set('team_detail', new TeamDetail(object.get('team_detail')));
  object.set('seasons', Set(object.get('seasons')));

  object.update('exclusive_membership_ids', value => Set(value));
  object.update('pricing_options', (ids = []) => Set(ids));
  object.update('session_ids', (ids = []) => Set(ids));
  object.update('participant_ids', (ids = []) => Set(ids));
  object.update('registered_client_ids', (ids = []) => Set(ids));
};

const constructionModifierForPlainObject = object =>
  Map(object).withMutations(constructionModifierForImmutableObject);

export const SCHEDULE_TYPE = {
  fixedSchedule: 'fixed_schedule',
  openBooking: 'open_booking',
  classSchedule: 'class_schedule',
  teamSchedule: 'team_schedule',
};

class CustomerEvent extends Record({
  id: null,
  uuid: null,
  admin_path: '',
  allow_single_session_purchase: false,
  allow_staff_selection: false,
  allow_waitlist: false,
  base_price: '',
  description: '',
  enable_dynamic_pricing: false,
  event_type: new EventType(),
  exclusive_membership_ids: Set(),
  exclusive_membership_tier_ids: Set(),
  freeEvent: false,
  image: new Image(),
  image_dimensions: {},
  participant_count: 0,
  path: '',
  price: '',
  pricing_tiers: List(),
  pricing_options: Set(),
  privacy: false,
  payment_methods: Map(),
  // Can we reliably get rid of registration fields?
  purchased_registrations: List(),
  registrations: List(),
  schedule_type: SCHEDULE_TYPE.fixedSchedule,
  schedules: List([new Schedule()]),
  session_ids: Set(),
  single_session_price: '',
  spots_remaining: '',
  status: 'draft',
  title: '',
  url: '',
  scheduled_price: null,
  scheduled_date: null,
  date_based_price: false,
  sport_type: '',
  team_type: new TeamType(),
  team_detail: new TeamDetail(),
  participant_ids: Set(),
  registered_client_ids: Set(),
  seasons: Set(),
  external_url: null,
}) {
  constructor(obj = {}, options = {}) {
    let objRef;

    if (obj && obj.asImmutable) {
      objRef = obj.withMutations(constructionModifierForImmutableObject);
    } else {
      objRef = constructionModifierForPlainObject(obj);
    }

    super(objRef, options);
  }

  editUrl() {
    return `${this.path}/edit`;
  }

  getHtmlDescription() {
    return this.description.toString('html');
  }

  getRichDescription() {
    return this.description;
  }

  isCancelled() {
    return this.status === 'cancelled';
  }

  isAlmostFull() {
    return (
      this.allow_waitlist &&
      this.spots_remaining > 0 &&
      this.spots_remaining <= 10
    );
  }

  isFixedSchedule() {
    return this.schedule_type === SCHEDULE_TYPE.fixedSchedule;
  }

  isOpenBooking() {
    return this.schedule_type === SCHEDULE_TYPE.openBooking;
  }

  isClassSchedule() {
    return this.schedule_type === SCHEDULE_TYPE.classSchedule;
  }

  isTeamSchedule() {
    return this.schedule_type === SCHEDULE_TYPE.teamSchedule;
  }

  isTeamEvent() {
    return Boolean(this.team_type?.get('id'));
  }

  isSSPTurnedOn() {
    return this.allow_single_session_purchase;
  }

  isMultiDayEvent() {
    return this.get('schedules')
      .first()
      .get('availability_schedule')
      .isMultiDay();
  }

  hasValidDaytime() {
    return this.get('schedules')
      .first()
      .get('availability_schedule')
      .isDaytimeValid();
  }

  isPurchased(byUserId = null) {
    if (byUserId) {
      return (
        !!this.purchased_registrations &&
        !!this.purchased_registrations.find(
          pr => pr.customer_user_id === byUserId
        )
      );
    }
    return (
      !!this.purchased_registrations && !!this.purchased_registrations.size
    );
  }

  isMembershipExclusive() {
    return !this.exclusive_membership_ids.isEmpty();
  }

  isMembershipTierExclusive() {
    return !this.exclusive_membership_tier_ids.isEmpty();
  }

  get locationName() {
    return (this && this.getIn(['schedules', 0, 'location', 'name'])) || 'TBD';
  }

  get locationAddress() {
    const address = this.getIn(['schedules', 0, 'location', 'address']);
    return address?.fullAddress;
  }

  get streetAddress() {
    const address = this.getIn(['schedules', 0, 'location', 'address']);
    return address?.streetAddress;
  }

  get additionalAddress() {
    const address = this.getIn(['schedules', 0, 'location', 'address']);
    return address?.additionalAddress;
  }

  typeColor() {
    if (this.isTeamEvent()) {
      return this?.team_type?.color ?? 'white';
    }
    return this?.event_type?.color ?? 'white';
  }

  typeName() {
    if (this.isTeamEvent()) {
      return this?.team_type?.name;
    }
    return this?.event_type?.name;
  }
  dateRangeText() {
    const startDate =
      this &&
      this.getIn(['schedules', 0, 'availability_schedule', 'start_date']);

    const endDate =
      this && this.getIn(['schedules', 0, 'availability_schedule', 'end_date']);

    if (startDate) {
      if (endDate) {
        return dateRange(startDate, endDate);
      }
      return moment(startDate).format(MonthDayYearFormat);
    }
    return 'TBD';
  }

  hasDisplayableSchedule() {
    const schedule = this.getSchedule();

    if (!schedule) {
      return false;
    }

    const { start_date: startDate, end_date: endDate } =
      schedule.availability_schedule;

    return (
      !!schedule.duration ||
      (moment(startDate).isValid() && moment(endDate).isValid())
    );
  }

  isAlreadyRegistered(userId) {
    return (
      this.participant_ids.has(userId) || this.registered_client_ids.has(userId)
    );
  }

  getScheduleTypeLabel() {
    if (this.isClassSchedule()) {
      return 'Class';
    }
    return 'Event';
  }

  scheduleText() {
    if (this.isClassSchedule()) {
      return 'Class';
    }

    if (this.isTeamEvent()) {
      return 'Team';
    }

    if (!this.hasDisplayableSchedule()) {
      return 'Schedule';
    }

    const schedule = this.getSchedule();

    if (this.isOpenBooking()) {
      return `${humanizeDuration(schedule.get('duration'))} duration`;
    }
    if (this.isFixedSchedule()) {
      const { start_date: startDate, end_date: endDate } =
        schedule.availability_schedule;

      return dateRange(startDate, endDate);
    }

    return null;
  }

  getSchedule() {
    return this.getIn(['schedules', 0]);
  }

  getAgeRange(min_age, max_age) {
    if (min_age && max_age) {
      return `${min_age}- ${max_age} years`;
    } else if (min_age && !max_age) {
      return `${min_age} years and above.`;
    } else if (max_age && !min_age) {
      return `${max_age}  years and below.`;
    }
  }

  getDobRestriction(dateOfBirth) {
    const startDate = dateOfBirth.start_date;
    const endDate = dateOfBirth.end_date;
    if (dateOfBirth.isDobTypeBetween()) {
      return `${startDate.format('MM/DD/YYYY')} to ${endDate.format(
        'MM/DD/YYYY'
      )}`;
    }
    if (dateOfBirth.isDobTypeBefore()) {
      return `Before ${startDate.format('MM/DD/YYYY')}`;
    }
    if (dateOfBirth.isDobTypeAfter()) {
      return `After ${startDate.format('MM/DD/YYYY')}`;
    }
  }

  getFutureAgeRestrictions() {
    let participants,
      dateOfBirth,
      hasAgeRestriction,
      hasDobRestriction,
      min_age,
      max_age;

    if (this.isTeamEvent()) {
      participants = this.team_detail;
      min_age = this.team_detail.min_age;
      max_age = this.team_detail.max_age;
    } else {
      participants = this.schedules.first();
      min_age = this.schedules.first().min_age;
      max_age = this.schedules.first().max_age;
    }

    dateOfBirth = participants.date_of_birth;
    hasAgeRestriction = participants.hasAgeRestriction();
    hasDobRestriction = dateOfBirth.hasDobRestriction();

    if (!hasAgeRestriction && !hasDobRestriction) {
      return 'No Range';
    }

    if (hasAgeRestriction) {
      return this.getAgeRange(min_age, max_age);
    } else {
      return this.getDobRestriction(dateOfBirth);
    }
  }
  hasScheduledPriceDate() {
    const scheduleDate = this.get('scheduled_date');
    const now = moment.tz(customerTZ());
    if (scheduleDate) {
      return moment.tz(scheduleDate, customerTZ()).isSameOrBefore(now, 'day');
    }
  }
  hasAccessToScheduledPrice() {
    return this.hasScheduledPriceDate() && !this.hasPricingScheduleDisabled();
  }

  async toServer() {
    const data = this.toJS();
    if (data.image && !data.image.file) {
      delete data.image;
    } else {
      data.image = await this.image.toServer();
    }
    // Skip default schedule on create
    if (!this.id && this.isClassSchedule()) delete data.schedules;
    delete data.registrations;
    delete data.purchased_registrations;
    delete data.team_detail;
    delete data.team_type;
    delete data.participant_ids;
    delete data.registered_client_ids;
    return data;
  }

  toJS() {
    if (this.description && !(typeof this.description === 'string')) {
      return this.set('description', this.description.toString('html')).toJS();
    }
    return super.toJS();
  }

  toJSON() {
    return this.toJS();
  }

  url() {
    if (currentUser().isStaff()) {
      return this.admin_path;
    }
    return this.path;
  }

  hasPricingScheduleDisabled() {
    const singleSessionPurchasable = this.allow_single_session_purchase;
    const dynamicPricingEnabled = this.enable_dynamic_pricing;
    const hasFixedSchedule = this.isFixedSchedule();

    return (
      !hasFixedSchedule || singleSessionPurchasable || dynamicPricingEnabled
    );
  }
  hasPriceScheduleChecked() {
    return this.date_based_price;
  }

  getTieredPrice(sessionCount, fallbackPrice = 0) {
    if (sessionCount === 1) return fallbackPrice;

    const tiers = this.pricing_tiers;
    const suitableTier = tiers.findLast(
      tier => sessionCount >= tier.get('session_count')
    );

    return suitableTier
      ? suitableTier.get('single_session_price')
      : fallbackPrice;
  }

  hasPricingTiers() {
    return this.pricing_tiers.size > 0;
  }
}

export default CustomerEvent;
