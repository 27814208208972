import * as React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Typography, Dropdown, Grid } from '@upperhand/playmaker';

import SpinWhileLoading from 'shared/components/_SpinWhileLoading.jsx';
import StateChangingButton from 'shared/components/_StateChangingButton.jsx';
import UserActions from 'shared/actions/UserActions.jsx';
import CurrentContextActions from 'shared/actions/CurrentContextActions.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils.js';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { t } from 'shared/utils/LocaleUtils.js';

const getSessionReminderTimeItems = intl => [
  {
    value: 60,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 1,
    }),
  },
  {
    value: 180,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 3,
    }),
  },
  {
    value: 1440,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 24,
    }),
  },
  {
    value: 2880,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 48,
    }),
  },
  {
    value: 4320,
    label: t('.session_reminder_time_value', intl, __filenamespace, {
      hours: 72,
    }),
  },
];

const styles = {
  root: {
    flexWrap: 'wrap',
    margin: 24,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    marginBottom: 32,
  },
};

function NotificationsTab({
  CurrentContextStore: {
    customerUser,
    isCustomerUserStoreLoading: customerUserIsLoading,
    isSaving: customerUserIsSaving,
  },
  intl,
  userStore: { user, isLoading: userIsLoading, isSaving: userIsSaving },
}) {
  const customerUserPreferences = customerUser.get('preferences');
  const sessionReminderMethods = customerUserPreferences.get(
    'sessionReminderMethods'
  );

  return (
    <div style={styles.root}>
      <SpinWhileLoading
        isLoading={userIsLoading && customerUserIsLoading}
        spinWhile="isLoading"
      >
        <Typography variant="h6">
          {t('.title_global', intl, __filenamespace)}
        </Typography>
        <Typography variant="body1">
          {t('.desc_global', intl, __filenamespace)}
        </Typography>
        <div style={styles.container}>
          <Checkbox
            label={t('.label_email', intl, __filenamespace)}
            checked={user.get_email_notifications}
            onChange={(_, value) =>
              UserActions.updateStore(['get_email_notifications'], value)
            }
          />
        </div>
        {!currentUser().isAdmin() &&
          currentCustomer().reminder_notifications_enabled && (
            <>
              <Typography variant="h6">
                {t('.title_reminder', intl, __filenamespace)}
              </Typography>
              <Typography variant="body1">
                {t('.desc_reminder', intl, __filenamespace)}
              </Typography>
              <div style={styles.container}>
                <Checkbox
                  label={t('.label_email', intl, __filenamespace)}
                  checked={sessionReminderMethods.has('email')}
                  onChange={(_, value) =>
                    CurrentContextActions.changePreferences(
                      'sessionReminderMethods',
                      value,
                      'email'
                    )
                  }
                />
                {currentCustomer().sms_enabled && (
                  <Checkbox
                    label={t('.label_text', intl, __filenamespace)}
                    checked={sessionReminderMethods.has('text')}
                    onChange={(_, value) =>
                      CurrentContextActions.changePreferences(
                        'sessionReminderMethods',
                        value,
                        'text'
                      )
                    }
                  />
                )}
              </div>

              {(sessionReminderMethods.has('email') ||
                sessionReminderMethods.has('text')) && (
                <>
                  <Typography variant="subtitle1">
                    {t('.title_send_reminder', intl, __filenamespace)}
                  </Typography>
                  <div style={styles.container}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <Dropdown
                          value={customerUserPreferences.get(
                            'sessionReminderTime'
                          )}
                          rounded={false}
                          onChange={event =>
                            CurrentContextActions.changePreferences(
                              'sessionReminderTime',
                              event.target.value
                            )
                          }
                          items={getSessionReminderTimeItems(intl)}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {t('.label_send_reminder', intl, __filenamespace)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </>
              )}
            </>
          )}
        <StateChangingButton
          inProgress={userIsSaving && customerUserIsSaving}
          onClick={() => {
            UserActions.update();
            CurrentContextActions.updatePreferences();
          }}
        />
      </SpinWhileLoading>
    </div>
  );
}

NotificationsTab.propTypes = {
  CurrentContextStore: PropTypes.object,
  intl: PropTypes.object.isRequired,
  userStore: PropTypes.object,
};

NotificationsTab.defaultProps = {
  CurrentContextStore: {},
  userStore: {},
};

export default NotificationsTab;
