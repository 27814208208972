import { Set } from 'immutable';
import moment from 'moment-timezone';

import {
  CreditCountSource,
  SessionSource,
  StaffSource,
  RegistrationSource,
  LocationSource,
} from 'sources';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import Actions from './Actions';
import { FILTER_STAFF_ALL } from './components/StaffFilter.jsx';

class SessionSchedulingStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.bindListeners({
      bookSessions: Actions.bookSessions,
      bookSessionError: Actions.bookSessionError,
      bookSessionSuccess: Actions.bookSessionSuccess,
      creditCountListSuccess: Actions.creditCountListSuccess,
      creditCountListError: Actions.creditCountListError,
      filterStaff: Actions.filterStaff,
      mounted: Actions.mounted,
      sessionsLoadMore: Actions.sessionsLoadMore,
      sessionListSuccess: Actions.sessionListSuccess,
      sessionListError: Actions.sessionListError,
      staffListSuccess: Actions.staffListSuccess,
      staffListError: Actions.staffListError,
      locationListSuccess: Actions.locationListSuccess,
      locationListError: Actions.locationListError,
      selectSession: Actions.selectSession,
      unmount: Actions.unmount,
      updateSelectedDayOfWeek: Actions.updateSelectedDayOfWeek,
      updateSelectedLocations: Actions.updateSelectedLocations,
    });
  }

  reset() {
    this.clientId = null;
    this.eventId = null;
    this.allowPast = false;
    this.onNext = () => {};

    this.creditCountsLoading = true;
    this.sessionsLoading = true;
    this.staffLoading = true;
    this.locationsLoading = true;
    this.bookingProcessing = 0;

    this.creditsAvailable = 0;
    this.page = 1;
    this.perPage = 25;
    this.selectedSessionIds = Set();
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.staffIds = Set();
    this.staffFilter = FILTER_STAFF_ALL;

    this.selectedDayOfWeek = Set([-1, 0, 1, 2, 3, 4, 5, 6]);

    this.locationIds = Set();
    this.selectedLocations = Set();
  }

  updateSelectedDayOfWeek({ selectedDay, isChecked }) {
    if (selectedDay === -1 && isChecked) {
      this.selectedDayOfWeek = new Set([-1, 0, 1, 2, 3, 4, 5, 6]);
    } else if (isChecked) {
      this.selectedDayOfWeek = this.selectedDayOfWeek.add(selectedDay);
    } else if (selectedDay === -1 && !isChecked) {
      this.selectedDayOfWeek = new Set();
    } else {
      this.selectedDayOfWeek = this.selectedDayOfWeek.delete(selectedDay);
      if (this.selectedDayOfWeek.includes(-1)) {
        this.selectedDayOfWeek = this.selectedDayOfWeek.delete(-1);
      }
    }
    if (this.selectedDayOfWeek.size === 7) {
      this.selectedDayOfWeek = this.selectedDayOfWeek.add(-1);
    }
    this.page = 1;
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.listSessions();
  }

  updateSelectedLocations([selectedLocations, _allSelected]) {
    this.selectedLocations = Set(selectedLocations);
    this.page = 1;
    this.sessionIds = Set();
    this.sessionsHasMore = true;
    this.listSessions();
  }

  mounted({
    clientId,
    eventId,
    allowPast = false,
    onNext = () => {},
    onBack = () => {},
    selectedSessionIds = Set(),
  }) {
    this.reset();

    this.clientId = clientId;
    this.eventId = eventId;
    this.allowPast = allowPast;
    this.onNext = onNext;
    this.onBack = onBack;
    this.selectedSessionIds = selectedSessionIds;

    this.listSessions();
    this.listStaff();
    this.listLocations();
    this.listCreditCounts();
  }

  unmount() {
    this.reset();
  }

  listSessions() {
    const startTime =
      this.allowPast && currentUser().isStaff()
        ? moment().subtract(1, 'week')
        : moment();
    const params = {
      page: this.page,
      per_page: this.perPage,
      event_ids: [this.eventId],
      location_ids: this.selectedLocations.toArray(),
      start_time: startTime.toISOString(),
    };

    if (this.staffFilter !== FILTER_STAFF_ALL) {
      params.staff_ids = [this.staffFilter];
    }

    if (this.selectedDayOfWeek && this.selectedDayOfWeek.size > 0) {
      params.days_of_week = this.selectedDayOfWeek.toArray();
    }

    this.sessionsLoading = true;

    SessionSource.list({
      params,
      success: Actions.sessionListSuccess,
      error: Actions.sessionListError,
    });
  }

  listStaff() {
    const params = {
      event_ids: this.eventId,
    };

    this.staffLoading = true;

    StaffSource.list({
      params,
      success: Actions.staffListSuccess,
      error: Actions.staffListError,
    });
  }

  listLocations() {
    const params = {
      event_ids: [this.eventId],
      per_page: 50,
    };

    this.locationsLoading = true;

    LocationSource.list({
      params,
      success: Actions.locationListSuccess,
      error: Actions.locationListError,
    });
  }

  locationListSuccess({ locations }) {
    this.locationsLoading = false;
    this.locationIds = Set(locations.map(l => l.id));
    this.selectedLocations = this.locationIds;
  }

  locationListError() {
    this.locationsLoading = false;
  }

  sessionListSuccess({ sessions, totalCount }) {
    this.sessionIds = this.sessionIds.concat(sessions.map(s => s.id));
    sessions.forEach(s => {
      if (
        (this.selectedSessionIds.includes(s.id) && s.spots_remaining === 0) ||
        s.client_ids.includes(this.clientId)
      ) {
        this.selectedSessionIds = this.selectedSessionIds.delete(s.id);
      }
    });

    this.sessionsLoading = false;
    this.sessionsHasMore = this.sessionIds.size < totalCount;
  }

  sessionListError() {
    this.sessionsLoading = false;
  }

  staffListSuccess({ staff }) {
    this.staffIds = staff.map(c => c.id);
    this.staffLoading = false;
  }

  staffListError() {
    this.staffLoading = false;
  }

  sessionsLoadMore() {
    this.page += 1;

    this.listSessions();
  }

  filterStaff(staffId) {
    this.staffFilter = staffId;
    this.page = 1;
    this.sessionIds = Set();
    this.sessionsHasMore = true;

    this.listSessions();
  }

  selectSession(id) {
    if (this.selectedSessionIds.includes(id)) {
      this.selectedSessionIds = this.selectedSessionIds.delete(id);
    } else if (this.selectedSessionIds.size < this.creditsAvailable) {
      this.selectedSessionIds = this.selectedSessionIds.add(id);
    }
  }

  listCreditCounts() {
    this.creditCountsLoading = true;

    CreditCountSource.list({
      params: {
        client_ids: [this.clientId],
        event_ids: [this.eventId],
        per_page: 100,
      },
      success: Actions.creditCountListSuccess,
      error: Actions.creditCountListError,
    });
  }

  creditCountListSuccess({ credit_counts: creditCounts }) {
    this.creditCountsLoading = false;
    const creditCount = creditCounts.first();
    this.creditsAvailable = creditCount
      ? creditCount.availableCreditCount()
      : 0;
  }

  creditCountListError() {
    this.creditCountsLoading = false;
  }

  bookSessions() {
    this.bookingProcessing = this.selectedSessionIds.size;

    this.selectedSessionIds.forEach(sId => {
      const request = new RegistrationSource.Schedule(this.clientId);
      request.bySession(sId);

      RegistrationSource.schedule({
        scheduleObj: request,
        success: Actions.bookSessionSuccess,
        error: Actions.bookSessionError,
      });
    });
  }

  bookSessionError(...args) {
    this.notifyError('error session booking', args);
  }

  bookSessionSuccess() {
    this.bookingProcessing -= 1;
    if (this.bookingProcessing <= 0) {
      setTimeout(this.onNext, 1);
    }
  }
}

export default alt.createStore(
  SessionSchedulingStore,
  'SessionSchedulingStore'
);
