import debounce from 'lodash.debounce';
import { Map, List } from 'immutable';

import Client from 'shared/records/Client.jsx';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';
import ContactGroupStore from 'shared/stores/ContactGroupStore.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { ClientSource, StaffSource } from 'sources';
import ContactsGroupsActions from './Actions';

class ContactsGroupsStore extends UpperHandStore {
  constructor() {
    super();

    this.addContactContactList = List();
    this.addContactParams = Map({
      page: 1,
      per_page: 25,
      total_count: 0,
      search_string: '',
    });

    this.contactList = List();
    this.contactListParams = Map({
      page: 1,
      per_page: 25,
      total_count: 0,
      search_string: '',
    });

    this.allClientsCount = 0;
    this.allStaffCount = 0;

    this.debouncedListContacts = debounce(this.listContacts, 600);

    this.bindListeners({
      mounted: ContactsGroupsActions.mounted,

      selectContactGroup: [
        ContactsGroupsActions.selectContactGroup,
        ContactGroupActions.deleteSuccess,
        ContactGroupActions.createSuccess,
      ],
      contactsChanged: [
        ContactGroupActions.removeContactsSuccess,
        ContactGroupActions.addContactSuccess,
      ],

      addContactSearchUpdated: ContactsGroupsActions.addContactSearchUpdated,
      listAddContactSuccess: ContactsGroupsActions.listAddContactSuccess,
      listAddContactError: ContactsGroupsActions.listAddContactError,

      contactListSearchUpdated: ContactsGroupsActions.contactListSearchUpdated,
      contactListPageSelected: ContactsGroupsActions.contactListPageSelected,
      listContactListSuccess: ContactsGroupsActions.listContactListSuccess,
      listContactListError: ContactsGroupsActions.listContactListError,

      fetchClientCountSuccess: ContactsGroupsActions.fetchClientCountSuccess,
      fetchClientCountError: ContactsGroupsActions.fetchClientCountError,
      fetchAllStaffCountSuccess:
        ContactsGroupsActions.fetchAllStaffCountSuccess,
      fetchAllStaffCountError: ContactsGroupsActions.fetchAllStaffCountError,
    });
  }

  mounted() {
    this.fetchAllClientsCount();
    this.fetchAllStaffCount();
  }

  selectContactGroup() {
    this.waitFor(ContactGroupStore);
    const { contactGroup } = ContactGroupStore.getState();

    this.contactListParams = Map({
      page: 1,
      per_page: 25,
      total_count: 0,
      search_string: '',
    });

    if (contactGroup.id === null) {
      this.contactList = List();
      return;
    }

    this.contactListParams = this.contactListParams.set(
      'contact_group_id',
      contactGroup.id || -1
    );

    this.listContacts('contactList');
  }

  contactsChanged() {
    this.waitFor(ContactGroupStore);
    const { contactGroup } = ContactGroupStore.getState();

    if (contactGroup.id === null) {
      this.contactList = List();
    } else if (contactGroup.id > 0) {
      this.contactListParams = this.contactListParams.set(
        'contact_group_id',
        contactGroup.id
      );
      this.addContactParams = this.addContactParams.set('search_string', '');

      this.listContacts('contactList');
    }
  }

  addContactSearchUpdated(newSearch) {
    this.addContactParams = this.addContactParams.set(
      'search_string',
      newSearch
    );

    this.debouncedListContacts('addContact');
  }

  contactListSearchUpdated(newSearch) {
    this.contactListParams = this.contactListParams.set(
      'search_string',
      newSearch
    );

    // The search bar already debounces the action call, so we don't want to debounce again here.
    this.listContacts('contactList');
  }

  contactListPageSelected([page, perPage]) {
    this.contactListParams = this.contactListParams.merge({
      page,
      per_page: perPage,
    });

    this.listContacts('contactList');
  }

  listContacts(requester) {
    let params = {};
    let success;
    let error;

    if (requester === 'contactList') {
      params = this.contactListParams.toJS();
      success = ContactsGroupsActions.listContactListSuccess;
      error = ContactsGroupsActions.listContactListError;
    } else {
      params = this.addContactParams.toJS();
      success = ContactsGroupsActions.listAddContactSuccess;
      error = ContactsGroupsActions.listAddContactError;
    }

    params.types = ['staff_admin', 'staff_member', 'client'];

    uhApiClient.get({
      url: 'customer_users',
      data: params,
      success,
      error,
    });
  }

  listAddContactSuccess({
    customer_users: customerUsers,
    total_count: totalCount,
  }) {
    this.addContactParams = this.addContactParams.set(
      'total_count',
      totalCount
    );

    this.addContactContactList = List(customerUsers.map(c => new Client(c)));
  }

  listAddContactError(...args) {
    this.notifyError('error fetching customer users', args);
  }

  listContactListSuccess({
    customer_users: customerUsers,
    total_count: totalCount,
  }) {
    this.contactListParams = this.contactListParams.set(
      'total_count',
      totalCount
    );

    this.contactList = List(customerUsers.map(c => new Client(c)));
  }

  listContactListError(...args) {
    this.notifyError('error fetching customer users', args);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchAllClientsCount() {
    ClientSource.list({
      params: { per_page: 1 },
      success: ContactsGroupsActions.fetchClientCountSuccess,
      error: ContactsGroupsActions.fetchClientCountError,
    });
  }

  fetchClientCountSuccess({ totalCount }) {
    this.allClientsCount = totalCount;
  }

  fetchClientCountError(...args) {
    this.notifyError('error fetching client count', args);
  }

  // eslint-disable-next-line class-methods-use-this
  fetchAllStaffCount() {
    StaffSource.list({
      params: { per_page: 1 },
      success: ContactsGroupsActions.fetchAllStaffCountSuccess,
      error: ContactsGroupsActions.fetchAllStaffCountError,
    });
  }

  fetchAllStaffCountSuccess({ totalCount }) {
    this.allStaffCount = totalCount;
  }

  fetchAllStaffCountError(...args) {
    this.notifyError('error fetching staff count', args);
  }
}

export default alt.createStore(ContactsGroupsStore, 'ContactsGroupsStore');
