import { List, Map } from 'immutable';
import debounce from 'lodash.debounce';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentCustomer } from 'shared/utils/CustomerUtils';

import { ContactFilterSource } from 'sources';

import ContactFilter from 'containers/contacts/contactsFilters/records/ContactFilter';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';

import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import ContactsFiltersDrawerActions from 'containers/contacts/contactsFilters/Actions';
import ContactGroupActions from 'shared/actions/ContactGroupActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

export const STEPS = {
  INITIAL: 'initial',
  CREATE: 'create',
};

export const DATE_OPERATORS = {
  IS: 'is',
  BEFORE: 'before',
  AFTER: 'after',
  BETWEEN: 'between',
};

export const ACCOUNT_STATUSES = {
  ALL: 'all',
  ACTIVE: 'active',
  INVITED: 'invited',
  UNCLAIMED: 'unclaimed',
};

export const ACCOUNT_TYPES = {
  ALL: 'all',
  MANAGED: 'managed',
  PRIMARY: 'primary',
  LEAD: 'lead',
};

export const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
  CANCELLED: 'cancelled',
  PAST_DUE: 'past_due',
  NON_MEMBER: 'non_member',
};

export const GENDERS = {
  ALL: 'all',
  MALE: 'male',
  FEMALE: 'female',
};

export const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

class ContactsFiltersDrawerStore extends UpperHandStore {
  constructor() {
    super();
    this.reset();
    this.debouncedListProducts = debounce(() => {
      this.listProducts();
    }, 600);
    this.bindListeners({
      openDrawer: ContactsFiltersDrawerActions.openDrawer,
      closeDrawer: ContactsFiltersDrawerActions.closeDrawer,

      changeStep: ContactsFiltersDrawerActions.changeStep,
      filterValueChange: ContactsFiltersDrawerActions.filterValueChange,

      listFilters: ContactsFiltersDrawerActions.listFilters,
      listFiltersSuccess: ContactsFiltersDrawerActions.listFiltersSuccess,
      listFiltersError: ContactsFiltersDrawerActions.listFiltersError,

      saveFilter: ContactsFiltersDrawerActions.saveFilter,
      saveFilterSuccess: ContactsFiltersDrawerActions.saveFilterSuccess,
      saveFilterError: ContactsFiltersDrawerActions.saveFilterError,

      searchProducts: ContactsFiltersDrawerActions.searchProducts,
      listProducts: ContactsFiltersDrawerActions.listProducts,
      listProductsSuccess: ContactsFiltersDrawerActions.listProductsSuccess,
      listProductsError: ContactsFiltersDrawerActions.listProductsError,

      selectSavedFilter: ContactsFiltersDrawerActions.selectSavedFilter,

      clearFilters: ContactsFiltersDrawerActions.clearFilters,

      deleteFilter: ContactsFiltersDrawerActions.deleteFilter,
      deleteFilterSuccess: ContactsFiltersDrawerActions.deleteFilterSuccess,
      deleteFilterError: ContactsFiltersDrawerActions.deleteFilterError,

      createGroupSuccess: ContactGroupActions.createSuccess,
    });
  }

  reset = () => {
    this.open = false;
    this.filtersLoading = false;
    this.filterChanged = false;
    this.products = [];
    this.contactFilters = List();
    this.filter = new ContactFilter();
    this.step = STEPS.INITIAL;
    this.pagination = Map({
      products: Map({
        search: '',
        loading: false,
        page: 1,
        perPage: 15,
        totalCount: 0,
      }),
    });
  };

  openDrawer = () => {
    this.listFilters();
    this.setState({ open: true });
  };

  closeDrawer = () => {
    this.reset();
  };

  changeStep = ({ step = STEPS.CREATE, filter = new ContactFilter() }) => {
    this.setState({ step, filter, filterChanged: false });

    if (step === STEPS.CREATE && filter.get('id')) {
      ContactsFiltersDrawerActions.selectSavedFilter.defer(filter.get('id'));
    }
  };

  filterValueChange = ([e]) => {
    const path = e.target.name.split('.');

    this.setState({ filter: this.filter.setIn(path, e.target.value) });

    if (!e.target.value) {
      this.clearDateOperator(path);
    }

    if (this.filter.get('errors').getErrors(path).length > 0) {
      this.setState({ filter: this.filter.validate() });
    }

    this.setState({ filterChanged: true });
  };

  clearDateOperator = path => {
    const filterPath = path.slice(0, -1);
    const operator = this.filter.getIn(filterPath.concat('operator'));
    const startDate = this.filter.getIn(filterPath.concat('start_date'));
    const endDate = this.filter.getIn(filterPath.concat('end_date'));

    if (operator && operator !== 'between' && !startDate) {
      this.setState({ filter: this.filter.setIn(filterPath, Map()) });
    }

    if (operator && operator === 'between' && !startDate && !endDate) {
      this.setState({ filter: this.filter.setIn(filterPath, Map()) });
    }
  };

  clearFilters = () => {
    this.setState({ filter: new ContactFilter(), filterChanged: false });
  };

  listFilters = () => {
    this.setState({ filtersLoading: true });

    ContactFilterSource.fetchContactFilters({
      success: ContactsFiltersDrawerActions.listFiltersSuccess,
      error: ContactsFiltersDrawerActions.listFiltersError,
    });
  };

  listFiltersSuccess = ({ contact_filters: contactFilters }) => {
    this.setState({
      filtersLoading: false,
      contactFilters: List(contactFilters.map(f => new ContactFilter(f))),
    });
  };

  listFiltersError = (...args) => {
    this.setState({ filtersLoading: false });
    this.notifyError('Error while listing contact filters', args);
  };

  saveFilter = () => {
    this.setState({ filter: this.filter.validate() });

    if (this.filter.isValid()) {
      ContactFilterSource.createOrUpdateFilter({
        id: this.filter.get('id'),
        params: { attributes: this.filter },
        success: ContactsFiltersDrawerActions.saveFilterSuccess,
        error: ContactsFiltersDrawerActions.saveFilterError,
      });
    }
  };

  saveFilterSuccess = filter => {
    if (!this.filter.get('id')) {
      const message = new TranslatableMessage({
        id: 'containers.contacts.contactsFilters.Store.save_success',
        filename: __filenamespace,
      });

      MessageWindowActions.addMessage.defer(message);
    } else {
      const message = new TranslatableMessage({
        id: 'containers.contacts.contactsFilters.Store.update_success',
        filename: __filenamespace,
      });

      MessageWindowActions.addMessage.defer(message);
    }
    this.setState({ filter: new ContactFilter(filter) });
    this.listFilters();
  };

  saveFilterError = (...args) => {
    if (this.filter.get('id')) {
      this.notifyError('Error while updating contact filter.', args);
    } else {
      this.notifyError('Error while saving contact filter.', args);
    }
  };

  searchProducts = ([_event, searchText]) => {
    this.setState({
      pagination: this.pagination
        .setIn(['products', 'search'], searchText)
        .setIn(['products', 'loading'], true),
    });
    this.debouncedListProducts();
  };

  listProducts = () => {
    const searchString = this.pagination.getIn(['products', 'search']);

    this.setState({
      pagination: this.pagination.setIn(['products', 'loading'], true),
    });

    uhApiClient.get({
      url: `customers/${
        currentCustomer().id
      }/products?search_string=${searchString}`,
      data: {
        product_types: ['event', 'membership', 'credit_pass', 'team_event'],
      },
      success: ContactsFiltersDrawerActions.listProductsSuccess,
      error: ContactsFiltersDrawerActions.listProductsError,
    });
  };

  listProductsSuccess = ({
    products,
    page,
    per_page: perPage,
    total_count: totalCount,
  }) => {
    this.setState({
      products,
      pagination: this.pagination
        .setIn(['products', 'loading'], false)
        .setIn(['products', 'page'], page)
        .setIn(['products', 'perPage'], perPage)
        .setIn(['products', 'totalCount'], totalCount),
    });
  };

  listProductsError = (...args) => {
    this.notifyError('Error while listing products', args);
    this.setState({
      pagination: this.pagination.setIn(['products', 'loading'], false),
    });
  };

  selectSavedFilter = id => {
    const filter = this.contactFilters.find(f => f.id === id);

    this.setState({ filter });
  };

  // eslint-disable-next-line class-methods-use-this
  deleteFilter = id => {
    ContactFilterSource.deleteContactFilter({
      id,
      success: {
        action: ContactsFiltersDrawerActions.deleteFilterSuccess,
        args: [id],
      },
      error: ContactsFiltersDrawerActions.deleteFilterError,
    });
  };

  deleteFilterSuccess = ([filter, id]) => {
    const message = new TranslatableMessage({
      id: 'containers.contacts.contactsFilters.Store.delete_success',
      filename: __filenamespace,
      values: { name: filter.name },
    });

    this.setState({
      contactFilters: this.contactFilters.filter(f => f.id !== id),
      filter: this.contactFilters.first() || new ContactFilter(),
    });

    MessageWindowActions.addMessage.defer(message);
  };

  deleteFilterError = (...args) => {
    this.notifyError('Error while deleting contact filter', args);
  };

  createGroupSuccess = group => {
    const filterIndex = this.contactFilters.findIndex(
      f => f.id === group.contact_filter_id
    );
    const contactFilters = this.contactFilters.update(filterIndex, f =>
      f.set('contact_group_id', group.contact_filter_id)
    );

    this.setState({ contactFilters });
  };
}

export default alt.createStore(
  ContactsFiltersDrawerStore,
  'ContactsFiltersDrawerStore'
);
