import { List, Map, Range, Set } from 'immutable';

import FieldErrors from 'shared/records/FieldErrors.jsx';
import Client from 'shared/records/Client.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';
import TranslatableMessage from 'shared/records/TranslatableMessage.jsx';
import UpperHandStore from 'shared/stores/UpperHandStore.jsx';
import UserValidator from 'shared/utils/UserValidator.jsx';
import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { currentUser } from 'shared/utils/UserUtils.jsx';
import { merge, setAddressDataIn } from 'shared/utils/ObjectUtils.jsx';

const url = id => (id ? `customer_users/${id}` : 'customer_users');

class ClientStoreInterface extends UpperHandStore {
  constructor() {
    super();

    this.actions = null;

    this.handleReset();

    this.findById = id => this.allClients.find(client => client.id === id);

    this.groupedByManager = () => {
      if (!this.managedByMap || this.shouldUpdateManagedByMap) {
        this.managedByMap = this.allClients.groupBy(c => c.managed_by_id);
        this.shouldUpdateManagedByMap = false;
      }
      return this.managedByMap;
    };

    this.managedUsers = client =>
      this.groupedByManager().get(client.user_id, List());
  }

  handleReset() {
    this.client = new Client();
    this.allClients = List();
    this.allClientsMap = Map();
    this.managedByMap = null;
    this.currentListOptions = {};
    this.handledPages = Set();
    this.loadedPages = Set();
    this.totalPages = 0;

    this.isLoading = false;
    this.isSaving = false;
    this.clearOnSuccess = false;
    this.shouldUpdateManagedByMap = false;
    this.isValid = true;
    this.managedByCurrentClient = false;

    this.isCreatingManagedProfile = false;

    this.requiredFields = List(['email']);
    this.fieldErrors = new FieldErrors();
  }

  handleAcceptTerms(id) {
    return uhApiClient.put({
      url: `customer_users/${id}/accept_terms`,
      success: this.actions.updateSuccess,
      error: this.actions.updateError,
    });
  }

  handleSendInvite() {
    this.handleList();
  }

  handleDeleteInvite([_, customerUserId]) {
    this.allClientsMap = this.allClientsMap.delete(customerUserId);
    this.allClients = this.sorted(this.allClientsMap.toList());
  }

  validateUser({ requiredFields = [], isNewUser = false } = {}) {
    if (requiredFields) {
      this.requiredFields = List(requiredFields);
    }
    const userValidator = new UserValidator(this.client);
    userValidator.validate(this.requiredFields, isNewUser);
    this.fieldErrors = userValidator.errors;
    this.isValid = !this.fieldErrors.hasErrors();
  }

  handleUpdateStore(data) {
    let newData = data;
    /*
     * TODO: https://www.pivotaltracker.com/story/show/136466685
     * Refactor to make this more readable
     */
    const clientJson = this.client.toJSON ? this.client.toJSON() : this.client;

    if ('login_enabled' in data && !data.login_enabled) {
      newData.email = '';
    }

    newData = setAddressDataIn(newData, clientJson);

    const mergedData = Map(clientJson)
      .mergeDeep(Map({ ...newData, id: this.client.id }))
      .toObject();

    this.client = new Client(mergedData);

    if (
      (this.isCreatingManagedProfile || this.client.managed_by_id) &&
      'date_of_birth' in newData &&
      this.client.underThirteen()
    ) {
      this.client = this.client.set('login_enabled', false);
      this.client = this.client.set('email', '');
    }

    if (!this.isValid) {
      this.validateUser();
    }
  }

  handleClear() {
    this.client = new Client();
    this.fieldErrors = this.fieldErrors.clear();
  }

  handleStageClient(client) {
    this.client = client;
  }

  handleList(opts) {
    const options = opts || {};

    if (!options.fields) {
      options.fields = [];
    }

    if (!options.fields.includes('active_membership_color')) {
      options.fields.push('active_membership_color');
    }
    options.fields.push('managing_customer_user_id');
    options.fields.push('note');

    this.isLoading = true;
    this.currentListOptions = merge({ per_page: 100, type: 'client' }, options);
    this.handledPages = this.handledPages.add(this.currentListOptions.page);

    return uhApiClient.get({
      url: url(),
      data: this.currentListOptions,
      success: this.actions.listSuccess,
      error: this.actions.listError,
    });
  }

  getNextPage() {
    return Range(1, this.totalPages + 1).find(
      pageNo => !this.handledPages.has(pageNo)
    );
  }

  setCreatingManagedProfile() {
    if (this.isValid) {
      this.isCreatingManagedProfile = true;
    }
  }

  unsetCreatingManagedProfile() {
    if (this.isValid) {
      this.isCreatingManagedProfile = false;
    }
  }

  handleListSuccess(data) {
    if (data.page === 1) {
      this.allClientMap = Map();
      this.handledPages = Set([1]);
      this.loadedPages = Set();
      this.totalPages = Math.trunc((data.total_count - 1) / data.per_page) + 1;
    }

    this.managedByMap = null;

    const usersById = Map().withMutations(u => {
      this.allClientsMap = this.allClientsMap.withMutations(m =>
        data.customer_users.forEach(c => {
          m.set(c.id, new Client(c));
          u.set(c.user_id, m.get(c.id));
        })
      );
    });
    this.allClientsMap = this.allClientsMap.withMutations(m =>
      data.customer_users.forEach(c => {
        const client = m.get(c.id);
        let manager;
        if (client.managing_customer_user_id) {
          manager = m.get(client.managing_customer_user_id);
        } else if (client.managed_by_id) {
          manager = usersById.get(client.managed_by_id);
        }
        if (manager) {
          m.set(c.id, client.set('managing_customer_user', manager));
          m.set(manager.id, manager.addManagedProfiles([client]));
        }
      })
    );
    this.allClients = this.sorted(this.allClientsMap.toList());

    this.loadedPages = this.loadedPages.add(data.page);

    if (data.total_count > this.allClients.size) {
      while (
        this.getNextPage() &&
        this.handledPages.subtract(this.loadedPages).size < 3
      ) {
        this.handleList(
          merge(this.currentListOptions, { page: this.getNextPage() })
        );
      }
    } else {
      this.isLoading = false;
      this.currentListOptions = {};
    }
  }

  handleListError(...args) {
    this.isLoading = false;
    this.notifyError('error while listing client', args);
  }

  handleFetch(id) {
    if (currentUser().customer_user_id === id) {
      this.client = new Client(currentUser());
      return false;
    }
    return uhApiClient.get({
      url: url(id),
      success: this.actions.fetchSuccess,
      error: this.actions.fetchError,
    });
  }

  handleFetchSuccess(data) {
    this.isLoading = false;
    this.client = new Client(data);
    this.validateUser();
  }

  handleFetchError(...args) {
    this.isLoading = false;
    this.notifyError('error while fetching client member', args);
  }

  handleCreateOrUpdate(options) {
    const opts = options || {};

    this.clearOnSuccess = !!opts.clearOnSuccess;
    this.showSuccessMessage = !!opts.showSuccessMessage;
    this.managedByCurrentClient = !!opts.managedByCurrentClient;

    const resourceName = opts.resourceName || 'Client';

    if (this.client.id) {
      this.successMessage = `${resourceName} updated successfully.`;
      this.handleUpdate(this.client.id);
    } else {
      this.successMessage = `${resourceName} created successfully.`;
      this.handleCreate({});
    }
  }

  handleUpdateNote() {
    this.showSuccessMessage = true;
    this.successMessage = new TranslatableMessage({
      id: '.client_update_success',
      filename: __filenamespace,
    });

    if (!this.isSaving) {
      this.isSaving = true;

      uhApiClient.patch({
        url: url(this.client.id),
        data: JSON.stringify({
          attributes: { id: this.client.id, note: this.client.getHtmlNote() },
          fields: ['note'],
        }),
        success: this.actions.updateSuccess,
        error: this.actions.updateError,
      });
    }
  }

  async handleCreate({ afterCreate, showSuccessMessage }) {
    this.validateUser();
    this.afterCreate = afterCreate;
    this.showSuccessMessage = !!showSuccessMessage;
    this.successMessage = 'Invite Sent.';

    if (this.managedByCurrentClient) {
      this.client = this.client.set('managed_by_id', currentUser().id);
    }
    if (this.isValid && !this.isSaving) {
      this.isSaving = true;
      const payload = await this.payload(true);

      uhApiClient.post({
        url: url(),
        data: payload,
        success: this.actions.createSuccess,
        error: this.actions.createError,
      });
    }
  }

  handleCreateSuccess(data) {
    this.isSaving = false;
    this.client = this.clearOnSuccess ? new Client() : new Client(data);
    if (this.client.isLead()) {
      this.successMessage = 'Lead Contact Added.';
    }
    this.allClients = this.allClients.push(this.client);
    this.allClients = this.sorted(this.allClients);
    this.managedByMap = null;

    if (this.afterCreate) {
      this.afterCreate(data);
      this.afterCreate = null;
    }

    if (this.showSuccessMessage) {
      MessageWindowActions.addMessage.defer(this.successMessage);
    }

    this.successMessage = '';
  }

  handleCreateError(...args) {
    this.isSaving = false;
    this.notifyError('error while creating client member', args);
  }

  async handleUpdate(id) {
    this.validateUser();

    if (this.isValid && !this.isSaving) {
      this.isSaving = true;
      const payload = await this.payload();

      uhApiClient.put({
        url: url(id),
        data: payload,
        success: this.actions.updateSuccess,
        error: this.actions.updateError,
      });
    }
  }

  handleUpdateSuccess(data) {
    const index = this.allClients.findIndex(
      theClientMember => theClientMember.id === data.id
    );
    const newClientMember = new Client(data);

    this.allClients = this.allClients.set(index, newClientMember);
    this.managedByMap = null;
    this.client = this.clearOnSuccess ? new Client() : new Client(data);
    this.isSaving = false;

    if (this.showSuccessMessage) {
      MessageWindowActions.addMessage.defer(this.successMessage);
    }

    this.successMessage = '';
  }

  handleUpdateError(...args) {
    this.isSaving = false;
    this.notifyError('error while updating client member', args);
  }

  handleArchive(id) {
    uhApiClient.patch({
      url: `customer_users/${id}`,
      data: JSON.stringify({
        attributes: { archived: true },
        fields: ['managing_customer_user_id', 'active_membership_color'],
      }),
      success: this.actions.archiveSuccess,
      error: this.actions.archiveOrUnarchiveError,
    });
  }

  handleUnarchive(id) {
    uhApiClient.patch({
      url: `customer_users/${id}`,
      data: JSON.stringify({
        attributes: { archived: false },
        fields: ['managing_customer_user_id', 'active_membership_color'],
      }),
      success: this.actions.unarchiveSuccess,
      error: this.actions.archiveOrUnarchiveError,
    });
  }

  handleArchiveOrUnarchiveSuccess(client) {
    this.allClientsMap = this.allClientsMap.set(client.id, new Client(client));

    if (client.managing_customer_user_id) {
      this.allClientsMap = this.allClientsMap.set(
        client.managing_customer_user_id,
        this.allClientsMap
          .get(client.managing_customer_user_id)
          .addManagedProfiles([client])
      );
      this.shouldUpdateManagedByMap = true;
    }
    this.allClients = this.sorted(this.allClientsMap.toList());
  }

  handleArchiveOrUnarchiveError(...args) {
    this.notifyError('error while updating client archived status', args);
  }

  async payload(create = false) {
    const payload = await this.client.toServer(create);

    return JSON.stringify({
      attributes: merge(payload, { type: 'client', role: 'standard' }),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sorted(list) {
    return list.sortBy(client => client.name());
  }
}

export default ClientStoreInterface;
