import { Map } from 'immutable';

import CartActions from 'event_mgmt/shared/actions/CartActions.jsx';
import MessageWindowActions from 'shared/actions/MessageWindowActions.jsx';

import UpperHandStore from 'shared/stores/UpperHandStore.jsx';

import uhApiClient from 'shared/helpers/uhApiClient.jsx';
import { customerScopedRoute } from 'shared/utils/RouteUtils.js';

import {
  StaffSource,
  SessionSource,
  ClientSource,
  EventSource,
  OrderSource,
} from 'sources';

import Order from 'event_mgmt/shared/records/Order.jsx';
import OrderItem from 'shared/records/OrderItem';

import { enabledCustomerFeatures } from 'shared/utils/CustomerUtils';
import { currentUser, isLoggedIn } from 'shared/utils/UserUtils.jsx';

import { SessionDataStore } from 'dataStores';
import EventStore from 'event_mgmt/shared/stores/EventStore.jsx';
import { PaymentMethod, PaymentActions } from 'containers/payments';

const CHECKOUT_STEPS = {
  REVIEW: 'review',
  PAYMENT_SELECT: 'paymentSelect',
  PROCESSING: 'processing',
  SUMMARY: 'summary',
};

const url = id => {
  if (id) {
    return `/order_items/${id}`;
  }
  return '/order_items';
};

class CartStore extends UpperHandStore {
  constructor() {
    super();

    this.reset();
    this.isLoadingCart = !!isLoggedIn();

    this.bindListeners({
      handleFetchCart: CartActions.fetch,
      handleFetchCartSuccess: CartActions.fetchSuccess,
      handleFetchCartError: CartActions.fetchError,

      handleUpdate: CartActions.update,
      handleUpdateSuccess: CartActions.updateSuccess,

      addCreditPassPackageToCart: CartActions.creditPassPackageAdded,
      addRegistrationPackageToCart: CartActions.registrationPackageAdded,
      addSubscriptionPackageToCart: CartActions.subscriptionPackageAdded,
      handleAddSuccess: CartActions.addSuccess,

      handleRemove: CartActions.remove,
      handleRemoveSuccess: CartActions.removeSuccess,

      handleError: [CartActions.updateError, CartActions.removeError],
      handleAddError: CartActions.addError,

      handleUpdateCheckoutStep: CartActions.updateCheckoutStep,

      applyCoupon: CartActions.COUPON_APPLIED,
      applyCouponSuccess: CartActions.APPLY_COUPON_SUCCESS,
      applyCouponError: CartActions.APPLY_COUPON_ERROR,

      updateAccountCredits: CartActions.UPDATE_ACCOUNT_CREDITS,
      applyAccountCredits: CartActions.APPLY_ACCOUNT_CREDITS,
      applyAccountCreditsSuccess: CartActions.APPLY_ACCOUNT_CREDITS_SUCCESS,
      applyAccountCreditsError: CartActions.APPLY_ACCOUNT_CREDITS_ERROR,

      reset: CartActions.resetAfterSuccess,

      handleFetchEventsSuccess: CartActions.fetchEventsSuccess,
      handleFetchEventsError: CartActions.fetchEventsError,

      handleFetchSessionsSuccess: CartActions.fetchSessionsSuccess,
      handleFetchSessionsError: CartActions.fetchSessionsError,

      handleFetchStaffSuccess: CartActions.fetchStaffSuccess,
      handleFetchStaffError: CartActions.fetchStaffError,

      setInsurance: CartActions.setInsurance,
      applyInsurance: CartActions.applyInsurance,
      applyInsuranceSuccess: CartActions.applyInsuranceSuccess,
      applyInsuranceError: CartActions.applyInsuranceError,
      validateInsurancesSelection: CartActions.validateInsurancesSelection,

      fetchInsurancesSuccess: CartActions.fetchInsurancesSuccess,
      fetchInsurancesError: CartActions.fetchInsurancesError,

      toggleCheckoutStep: CartActions.toggleCheckoutStep,
      resetOnUnmount: CartActions.resetOnUnmount,
    });
  }

  reset() {
    this.cart = new Order();
    this.cartSize = -1;
    this.previousCart = null;
    this.insurances = [];
    this.disableContinueButton = false;

    this.redirectOnSuccess = true;
    this.isNavigatingToCart = false;
    this.isLoadingCart = false;
    this.isUpdatingCart = false;
    this.isOrderItem = false;
    this.showContinueToCheckout = true;

    this.checkoutStep = CHECKOUT_STEPS.REVIEW;

    this.isLoadingCoupon = false;
  }

  resetOnUnmount() {
    this.redirectOnSuccess = true;
    this.checkoutStep = CHECKOUT_STEPS.REVIEW;
    this.showContinueToCheckout = true;
  }

  toggleCheckoutStep() {
    this.showContinueToCheckout = !this.showContinueToCheckout;

    this.fetchInsurances();
  }

  handleFetchCart({ shouldSpin = true, shouldFetchAdditionalInfo = true }) {
    this.isLoadingCart = shouldSpin;
    return uhApiClient.get({
      url: '/cart',
      data: { fields: ['coupon', 'order_items'] },
      success: {
        action: CartActions.fetchSuccess,
        args: [shouldFetchAdditionalInfo],
      },
      error: CartActions.fetchError,
    });
  }

  handleFetchCartSuccess([data, shouldFetchAdditionalInfo]) {
    this.cart = new Order(data);
    this.cartSize = this.cart.order_items.size;

    if (this.cartSize) {
      ClientSource.fetch({
        id: this.cart.get('order_items').first().get('buyer_id'),
        params: {
          fields: ['total_account_credit'],
        },
        success: () => null,
      });
    }

    // hack: keep insurance in case when coupon code is applied and new cart is fetched
    if (this.previousCart && this.previousCart.get('insurance_amount')) {
      this.cart = this.cart
        .set('insurance_metadata', this.previousCart.get('insurance_metadata'))
        .set('insurance_amount', this.previousCart.get('insurance_amount'))
        .set(
          'insurance_proposal_ids',
          this.previousCart.get('insurance_proposal_ids')
        );
    }

    const eventIds = this.cart.order_items
      .filter(item => item.isEventItem())
      .map(item => item.orderable?.event_id);
    const fsEventIds = this.cart.order_items
      .filter(item => item.isEventItem() && item.isFixedScheduleItem())
      .map(item => item.orderable?.event_id);
    const obEventIds = this.cart.order_items
      .filter(item => item.isEventItem() && item.isOpenBookingItem())
      .map(item => item.orderable?.event_id);

    if (obEventIds.size > 0) {
      this.handleFetchEvents({ eventIds: obEventIds.toJS() });
    }

    if (eventIds.size > 0 && shouldFetchAdditionalInfo) {
      this.handleFetchStaff({ eventIds });
      this.handleFetchSessions({ eventIds: fsEventIds.toJS() });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchCartError(...args) {
    this.isLoadingCart = false;
    this.notifyError('error while fetching cart', args);
  }

  validateInsurancesSelection() {
    const list = document.querySelector('.insurances-list');
    const items = Array.from(list.querySelectorAll('.insurances-list__item'));

    const isValid = items.map(item => {
      const isNoChecked = Array.from(
        item.querySelectorAll('input[type="radio"]')
      ).some(input => input.checked && input?.id?.includes('no'));

      const isYesChecked = Array.from(
        item.querySelectorAll('input[type="radio"]')
      ).some(input => input.checked && input?.id?.includes('yes'));

      if (isNoChecked) {
        return true;
      }

      if (isYesChecked) {
        const inputs = Array.from(
          item.querySelectorAll('input[type="checkbox"]')
        );

        // Note: if we want to make sure that all checkboxes are checked change some to every
        return inputs.some(input => input.checked);
      }

      return false;
    });

    this.disableContinueButton = !isValid.every(item => item);
  }

  setInsurance(data) {
    const { detail } = data;
    const { price, action, proposalId } = detail;
    const selectedInsurancePrice = Number(price) * 100;

    if (action === 'add') {
      this.cart = this.cart
        .set(
          'insurance_amount',
          this.cart.get('insurance_amount', 0) + selectedInsurancePrice
        )
        .addInsurance({
          proposal_id: proposalId,
          price: Number(price),
        });

      // if insurance applied allow only card method for payments
      PaymentActions.setPaymentMethods.defer({
        enabled: [PaymentMethod.CARD],
        disabled: [
          PaymentMethod.CASH,
          PaymentMethod.CHECK,
          PaymentMethod.PAY_LATER,
          PaymentMethod.ACH,
        ],
      });
    }

    if (action === 'remove') {
      this.cart = this.cart
        .set(
          'insurance_amount',
          this.cart.get('insurance_amount', 0) - selectedInsurancePrice
        )
        .removeInsurance(proposalId);
    }

    this.previousCart = this.cart;

    // if all insurances deselected or no option clicked reset payment methods to default
    if (!this.cart.get('insurance_amount', 0)) {
      PaymentActions.setPaymentMethods.defer({
        disabled: [PaymentMethod.CASH, PaymentMethod.CHECK],
      });
    }
  }

  fetchInsurances() {
    const hasInsuranceEvents = this.cart.order_items.some(
      oi => oi.isEventItem() && (oi.isFixedScheduleItem() || oi.isTeamItem())
    );
    const shouldFetchInsurance =
      hasInsuranceEvents &&
      !this.showContinueToCheckout &&
      currentUser().isClient() &&
      enabledCustomerFeatures(['insurance']);

    if (shouldFetchInsurance) {
      this.isLoadingCart = true;

      OrderSource.fetchInsurance({
        id: this.cart.id,
        success: CartActions.fetchInsurancesSuccess,
        error: CartActions.fetchInsurancesError,
      });
    }
  }

  fetchInsurancesSuccess(insurances) {
    this.isLoadingCart = false;
    this.insurances = insurances;

    if (insurances.length > 0) {
      this.disableContinueButton = true;
    }

    const insuranceAmount = this.cart.get('insurance_amount', 0);

    if (insuranceAmount > 0) {
      this.cart = this.cart.set('insurance_metadata', Map());
      this.applyInsurance('review');
    }
  }

  fetchInsurancesError(...args) {
    this.isLoadingCart = false;
    this.notifyError('Error fetching insurances list', args);
  }

  handleFetchEvents({ eventIds = [], page = 1 }) {
    if (eventIds.length === 0) return;

    this.isLoadingCart = true;
    EventSource.list({
      params: {
        page,
        per_page: 50,
        ids: eventIds,
      },
      success: {
        action: CartActions.fetchEventsSuccess,
        args: [eventIds],
      },
      error: CartActions.fetchEventsError,
    });
  }

  handleFetchEventsSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchEvents({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchEventsError() {
    this.isLoadingCart = false;
  }

  handleFetchSessions({ eventIds = [], page = 1 }) {
    if (eventIds.length === 0) return;

    this.isLoadingCart = true;
    SessionSource.list({
      params: {
        page,
        per_page: 50,
        event_ids: eventIds,
      },
      success: {
        action: CartActions.fetchSessionsSuccess,
        args: [eventIds],
      },
      error: CartActions.fetchSessionsError,
    });
  }

  handleFetchSessionsSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchSessions({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchSessionsError() {
    this.isLoadingCart = false;
  }

  handleFetchStaff({ eventIds, page = 1 }) {
    this.isLoadingCart = true;
    StaffSource.list({
      params: { page, per_page: 50, event_ids: eventIds.toJS() },
      success: {
        action: CartActions.fetchStaffSuccess,
        args: [eventIds],
      },
      error: CartActions.fetchStaffError,
    });
  }

  handleFetchStaffSuccess([{ page, perPage, totalCount }, eventIds]) {
    if (page * perPage < totalCount) {
      this.handleFetchStaff({ eventIds, page: page + 1 });
    } else {
      this.isLoadingCart = false;
    }
  }

  handleFetchStaffError() {
    this.isLoadingCart = false;
  }

  handleUpdateCheckoutStep(step) {
    const shouldApplyInsurance =
      step === CHECKOUT_STEPS.PAYMENT_SELECT &&
      this.cart.get('insurance_amount', 0) > 0;

    // Apply insurance when Continue to payment button clicked
    if (shouldApplyInsurance) {
      this.applyInsurance(step);
      return;
    }

    // Clear insurance when back to summary button clicked
    if (step === CHECKOUT_STEPS.REVIEW) {
      const insuranceAmount = this.cart.get('insurance_amount', 0);

      if (insuranceAmount > 0) {
        this.cart = this.cart
          .set('insurance_metadata', Map())
          .set('insurance_amount', null);
        this.previousCart = this.cart;

        this.applyInsurance(step);
      }
    }

    this.checkoutStep = step;
  }

  applyInsurance(step) {
    uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: {
          insurance_metadata: this.cart.get('insurance_metadata').toObject(),
          coupon_code: this.cart.getIn(['coupon', 'code'], null),
        },
        fields: ['coupon', 'order_items', 'insurance_metadata'],
      }),
      success: {
        action: CartActions.applyInsuranceSuccess,
        args: [step],
      },
      error: CartActions.applyCouponError,
    });
  }

  applyInsuranceSuccess([cart, step]) {
    if (step) {
      this.checkoutStep = step;
      this.disableContinueButton = true;
    }

    // Reset payment methods to default when insurance was cleared
    if (!cart.insurance_amount) {
      PaymentActions.setPaymentMethods.defer({
        disabled: [PaymentMethod.CASH, PaymentMethod.CHECK],
      });
    }

    this.cart = new Order(cart);
  }

  applyInsuranceError(...args) {
    this.notifyError('error while applying insurance', args);
  }

  addRegistrationPackageToCart([
    registrationPackage,
    opts = { redirect: false, customSuccessAction: null },
  ]) {
    this.redirectOnSuccess = !!opts.redirect;
    this.addOrderableToCart(
      registrationPackage,
      'registration_package',
      opts.customSuccessAction
    );
  }

  addSubscriptionPackageToCart(subscriptionPackage) {
    this.addOrderableToCart(
      subscriptionPackage,
      'membership_subscription_package'
    );
  }

  addCreditPassPackageToCart(creditPassPackage) {
    this.addOrderableToCart(creditPassPackage, 'credit_pass_package');
  }

  getOrderParams(item) {
    this.waitFor(EventStore);
    this.waitFor(SessionDataStore);
    const { customerEvent } = EventStore.getState();
    const { pagination } = SessionDataStore.getState();
    const orderParams = item.toServer();
    const hasTeamEvent = customerEvent.isTeamEvent();
    if (hasTeamEvent) {
      const sessionsCount = pagination.get('totalCount');
      orderParams.registration_package_attributes.quantity =
        sessionsCount > 0 ? sessionsCount : 1;
    }
    return orderParams;
  }

  addOrderableToCart(orderable, orderableType, customSuccessAction) {
    this.handleFetchCart({});
    const newItem = new OrderItem({
      order_id: this.cart.id,
      orderable,
      orderable_type: orderableType,
      quantity: 1,
    });

    if (!customSuccessAction) {
      this.isUpdatingCart = true;
      this.isOrderItem = true;
      this.isLoadingCart = true;
    }

    return uhApiClient.post({
      url: url(),
      data: JSON.stringify({
        attributes: this.getOrderParams(newItem),
      }),
      success: customSuccessAction || CartActions.addSuccess,
      error: CartActions.addError,
    });
  }

  handleAddSuccess(_orderItem) {
    this.isLoadingCart = false;
    this.isOrderItem = false;
    this.previousCart = this.cart;
    this.handleFetchCart({ shouldSpin: false });

    this.isUpdatingCart = false;

    if (this.redirectOnSuccess) {
      this.navigateToCart();
    } else {
      MessageWindowActions.addMessage.defer('Added to cart.');
    }
  }

  handleUpdate([id, newOrderable, opts = { redirect: false }]) {
    this.isUpdatingCart = true;
    this.redirectOnSuccess = !!opts.redirect;

    const index = this.cart.order_items.findIndex(oi => oi.id === id);
    const updatedItem = this.cart
      .getIn(['order_items', index])
      .set('orderable', newOrderable);

    this.previousCart = this.cart;
    this.cart = this.cart.setIn(['order_items', index], updatedItem);
    this.cartSize = this.cart.order_items.size;

    return uhApiClient.put({
      url: url(updatedItem.id),
      data: JSON.stringify({
        attributes: this.getOrderParams(updatedItem),
      }),
      success: CartActions.updateSuccess,
      error: CartActions.updateError,
    });
  }

  handleUpdateSuccess(item) {
    const updatedItem = new OrderItem(item);
    const index = this.cart.order_items.findIndex(
      oi => oi.id === updatedItem.id
    );

    this.cart = this.cart.setIn(['order_items', index], updatedItem);
    this.cartSize = this.cart.order_items.size;

    this.isUpdatingCart = false;

    if (this.redirectOnSuccess) {
      this.navigateToCart();
    } else {
      MessageWindowActions.addMessage.defer('Cart updated.');
    }
  }

  handleRemove(id) {
    this.isUpdatingCart = true;
    this.previousCart = this.cart;
    this.cart = this.cart.removeItem(id);
    this.cartSize = this.cart.order_items.size;

    return uhApiClient.delete({
      url: url(id),
      success: CartActions.removeSuccess,
      error: CartActions.removeError,
    });
  }

  handleRemoveSuccess(_data) {
    this.handleFetchCart({
      shouldSpin: false,
      shouldFetchAdditionalInfo: false,
    });
    this.isUpdatingCart = false;

    if (!enabledCustomerFeatures(['insurance'])) {
      return;
    }

    const hasFSEvents = this.previousCart.order_items.some(
      oi => oi.isEventItem() && oi.isFixedScheduleItem()
    );
    const shouldRefetchInsurance =
      !this.showContinueToCheckout &&
      hasFSEvents &&
      [CHECKOUT_STEPS.REVIEW, CHECKOUT_STEPS.PAYMENT_SELECT].includes(
        this.checkoutStep
      );

    if (shouldRefetchInsurance) {
      this.cart = this.cart.set('insurance_metadata', Map());
      this.fetchInsurances();
      this.applyInsurance();
    }
  }

  handleError(error) {
    this.isLoadingCart = false;
    this.isOrderItem = false;
    this.cart = this.previousCart;
    this.cartSize = this.cart && this.cart.order_items.size;
    this.isUpdatingCart = false;
    MessageWindowActions.addMessage.defer(error?.httpMessage);
  }

  handleAddError(error) {
    this.cart = this.previousCart;
    this.cartSize = this.cart && this.cart.order_items.size;
    this.isUpdatingCart = false;
    MessageWindowActions.addMessage.defer(error?.httpMessage);
  }

  navigateToCart() {
    this.isNavigatingToCart = true;
    window.location.href = customerScopedRoute('cart');
  }

  applyCoupon({ code }) {
    this.isLoadingCoupon = true;

    return uhApiClient.patch({
      url: `orders/${this.cart.id}`,
      data: JSON.stringify({
        attributes: { coupon_code: code },
        fields: ['coupon', 'order_items'],
      }),
      success: CartActions.applyCouponSuccess,
      error: CartActions.applyCouponError,
    });
  }

  applyCouponSuccess(data) {
    this.isLoadingCoupon = false;
    this.handleFetchCartSuccess([data]);
  }

  applyCouponError(...args) {
    this.isLoadingCoupon = false;
    this.notifyError('error while applying coupon', args);
  }

  updateAccountCredits({ item, creditsAmount }) {
    const index = this.cart.order_items.findIndex(oi => oi.id === item.id);
    const updatedItem = item
      .merge({ account_credit_amount: creditsAmount })
      .validate();

    this.cart = this.cart.setIn(['order_items', index], updatedItem);
  }

  // eslint-disable-next-line class-methods-use-this
  applyAccountCredits({ item }) {
    const attributes = {
      ...item.toServer(),
      orderable_id: item.isRetailItem()
        ? item.getIn(['orderable', 'id'])
        : null,
    };

    uhApiClient.put({
      url: `order_items/${item.id}`,
      data: JSON.stringify({
        attributes,
        fields: ['account_credit_amount'],
      }),
      success: CartActions.applyAccountCreditsSuccess,
      error: CartActions.applyAccountCreditsError,
    });
  }

  applyAccountCreditsSuccess() {
    this.handleFetchCart({
      shouldSpin: false,
      shouldFetchAdditionalInfo: false,
    });
  }

  applyAccountCreditsError(...args) {
    this.notifyError('Error while applying account credits', args);
  }
}

export default alt.createStore(CartStore, 'CartStore');
